import React from 'react'
import { useTranslation } from 'react-i18next'
import { type UserType } from '../../typings'
import Bell from '../Bell'
import UserDropdown from '../UserDropdown'
import './style.sass'

interface WelcomeProps {
  name?: string
  userType: UserType
}

const Welcome = ({ name, userType }: WelcomeProps) => {
  const { t } = useTranslation()
  return (
    <div className="welcome">
      <div className="welcome__text">
        <div className="welcome__name">
          {t('welcome')}, {name}
        </div>
        <div className="welcome__space">
          {t('userTypeSpace', { userType: t(`userTypes.${userType}`) })}
        </div>
      </div>
      <div className="welcome__actions">
        <UserDropdown condensed />
        <Bell />
      </div>
    </div>
  )
}

export default Welcome
