import React from 'react'
import { useMutation } from '@tanstack/react-query'
import Form, { Input, Select } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { editDescription } from '../api/formatted/vetecard'
import SubmitButton from '../components/SubmitButton'
import { type EditDescriptionPayload, type VetecardFull } from '../typings'
import formatDate from '../utils/formatDate'
import { useTranslatedOptions } from '../utils/helpers'
import queryClient from '../utils/queryClient'

interface FormEditDescriptionProps {
  ident: VetecardFull
  onSuccess?: () => void
}

const FormEditDescription = ({ ident, onSuccess }: FormEditDescriptionProps) => {
  const { t } = useTranslation()

  const { isLoading, mutate } = useMutation(
    (payload: EditDescriptionPayload) => editDescription(ident.id, payload),
    {
      onSuccess: (response: Partial<VetecardFull>) => {
        queryClient.setQueryData(['vetecard', ident.id], (oldData: VetecardFull | undefined) =>
          oldData
            ? {
                ...oldData,
                ...response,
              }
            : undefined
        )
        toast.success(t('descriptionUpdated'))
        onSuccess && onSuccess()
      },
    }
  )

  const speciesOptions = useTranslatedOptions('species')
  const breedOptions = useTranslatedOptions(ident.species, 'breeds').sort((a, b) => {
    if (a.label < b.label) return -1
    if (a.label > b.label) return 1
    return 0
  })
  const coatingColourOptions = useTranslatedOptions(ident.species, 'coatingColors')
  const sexAllOptions = useTranslatedOptions('sex')
  const sexOptions = React.useMemo(
    () =>
      ident.species === 'horse'
        ? sexAllOptions
        : sexAllOptions.filter(item => item.value !== 'gelding'),
    [ident]
  )

  const handleSubmit = (values: EditDescriptionPayload) => {
    mutate(values)
  }

  const showBreedOptions = ident.species === 'dog' || ident.species == 'cat'

  return (
    <Form fields={['species', 'breedCode', 'sex', 'birthDate', 'coatingColour']} allMandatory>
      <Select
        name="species"
        label={t('selectAnimalSpecies')}
        options={speciesOptions}
        initialValue={ident.species}
        disabled
      />
      {showBreedOptions ? (
        <Select
          name="breedCode"
          label={t('breedCode')}
          options={breedOptions}
          placeholder={t('notSpecified')}
          initialValue={ident.breed}
        />
      ) : (
        <Input name="breedCode" label={t('breedCode')} initialValue={ident.breed} />
      )}
      <Select
        name="sex"
        label={t('sexLabel')}
        options={sexOptions}
        placeholder={t('notSpecified')}
        initialValue={ident.sex}
      />
      <Input
        name="birthDate"
        type="date"
        label={t('birthDate')}
        initialValue={ident.birthDate && formatDate(ident.birthDate, 'input')}
      />
      {showBreedOptions ? (
        <Select
          name="coatingColour"
          label={t('colour')}
          options={coatingColourOptions}
          placeholder={t('notSpecified')}
          initialValue={ident.coatingColour}
        />
      ) : (
        <Input name="coatingColour" label={t('colour')} initialValue={ident.coatingColour} />
      )}
      <SubmitButton onClick={handleSubmit} loading={isLoading}>
        {t('save')}
      </SubmitButton>
    </Form>
  )
}

export default FormEditDescription
