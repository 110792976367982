import React from 'react'
import { useSetAtom } from 'jotai'
import { wideLayoutAtom } from '../globalState'

interface ContentProps {
  wide?: boolean
  children: React.ReactNode
}

const Content = ({ wide = false, children }: ContentProps) => {
  const setWideLayout = useSetAtom(wideLayoutAtom)

  React.useEffect(() => {
    setWideLayout(wide)
  }, [wide])

  return <>{children}</>
}

export default Content
