export default function formatDate(date?: Date, variant?: 'year' | 'daysLeft' | 'input'): string {
  if (!date) return 'n/d'
  switch (variant) {
    case 'year':
      return date.getFullYear().toString()
    case 'daysLeft': {
      const today = new Date()
      const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
      return Math.round(Math.abs((date.getTime() - today.getTime()) / oneDay)).toString()
    }
    case 'input': {
      const year = date.toLocaleString('default', { year: 'numeric' })
      const month = date.toLocaleString('default', {
        month: '2-digit',
      })
      const day = date.toLocaleString('default', { day: '2-digit' })
      return [year, month, day].join('-')
    }
    default:
      return date.toLocaleDateString('en-GB')
  }
}
