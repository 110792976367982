import axios, { InternalAxiosRequestConfig } from 'axios'
import { keycloak } from '../utils/useAuth'

const api = axios.create({
  baseURL: process.env.DASHBOARD_API_URL as string,
  timeout: 60000,
  headers: {
    Accept: 'application/json',
  },
})

api.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  // Check whether user session is still valid before request is sent.
  // If not, redirect to login screen.
  if (keycloak.isTokenExpired()) {
    keycloak.logout()
  }
  return config
})

export interface ApiResponse<T = any> {
  data: T;
  status: number;
  statusText: string;
}

export type ApiError = Error

export type ApiGet = <T = any, R = ApiResponse<T>, D = any>(url: string) => Promise<R>;
export type ApiDelete = <T = any, R = ApiResponse<T>, D = any>(url: string) => Promise<R>;
export type ApiHead = <T = any, R = ApiResponse<T>, D = any>(url: string) => Promise<R>;
export type ApiOptions = <T = any, R = ApiResponse<T>, D = any>(url: string) => Promise<R>;
export type ApiPost = <T = any, R = ApiResponse<T>, D = any>(url: string, data?: D) => Promise<R>;
export type ApiPut = <T = any, R = ApiResponse<T>, D = any>(url: string, data?: D) => Promise<R>;
export type ApiPatch = <T = any, R = ApiResponse<T>, D = any>(url: string, data?: D) => Promise<R>;

export type Api = {
  get: ApiGet,
  delete: ApiDelete,
  head: ApiHead,
  options: ApiOptions,
  post: ApiPost,
  put: ApiPut,
  patch: ApiPatch
}

export default api
