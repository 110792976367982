import React from 'react'
import {
  faCalendarDays,
  faEnvelopeOpenText,
  faFileLines,
  faHome,
  faListCheck,
  faNewspaper,
  faPaw,
  faUserDoctor,
} from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'
import { wideLayoutAtom } from '../../globalState'
import useAuth from '../../utils/useAuth'
import Bell from '../Bell'
import BellNotifications from '../BellNotifications'
import MobileTabs from '../MobileTabs'
import Sidebar from '../Sidebar'
import Spinner from '../Spinner'
import UserDropdown from '../UserDropdown'
import './style.sass'

interface LayoutProps {
  children?: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const { t } = useTranslation()
  const { hasPermission } = useAuth()
  const wideLayout = useAtomValue(wideLayoutAtom)

  const links = [
    {
      url: '/',
      label: t('dashboard'),
      icon: faHome,
      desktop: true,
      mobile: false,
    },
    {
      url: '/vetecards',
      label: t('vetEcards'),
      icon: faPaw,
      permission: 'vetecards',
      desktop: true,
      mobile: true,
    },
    {
      url: '/veterinaries',
      label: t('veterinaries'),
      icon: faUserDoctor,
      permission: 'my-veterinaries',
      desktop: true,
      mobile: false,
    },
    {
      url: '/veterinaries',
      label: t('vets'),
      icon: faUserDoctor,
      permission: 'my-veterinaries',
      desktop: false,
      mobile: true,
    },
    {
      url: '/formalities',
      label: t('formalities'),
      icon: faFileLines,
      permission: 'formalities',
      desktop: true,
      mobile: true,
    },
    {
      url: '/vetecard-requests',
      label: t('requests'),
      icon: faListCheck,
      permission: 'vetecards-advanced',
      desktop: true,
      mobile: true,
    },
    {
      url: '/activate-vetecards',
      label: t('activateVetecards'),
      icon: faPaw,
      permission: 'activate-vetecards',
      desktop: true,
      mobile: true,
    },
    {
      url: '/manage-news',
      label: t('manageNews'),
      icon: faNewspaper,
      permission: 'activate-vetecards', // Should be 'manage-news'
      desktop: true,
      mobile: true,
    },
    {
      url: '/agenda',
      label: t('myAppointments'),
      icon: faCalendarDays,
      permission: 'agenda',
      desktop: true,
      mobile: false,
    },
    {
      url: '/agenda',
      label: t('agenda'),
      icon: faCalendarDays,
      permission: 'agenda',
      desktop: false,
      mobile: true,
    },
    {
      url: '/document-inbox',
      label: t('documentInbox'),
      icon: faEnvelopeOpenText,
      permission: 'document-inbox',
      desktop: true,
      mobile: false,
    },
    {
      url: '/document-inbox',
      label: t('inbox'),
      icon: faEnvelopeOpenText,
      permission: 'document-inbox',
      desktop: false,
      mobile: true,
    },
  ].filter(({ permission }) => (permission ? hasPermission(permission) : true))

  return (
    <React.Suspense fallback={<Spinner variant="block" />}>
      <div className={classNames('layout', { 'layout--wide': wideLayout })}>
        <Sidebar className="layout__sidebar" links={links.filter(item => item.desktop)} />
        <div className="layout__main">
          <div className="layout__content">
            <div className="layout__top">
              <UserDropdown />
              <Bell />
            </div>
            {children}
          </div>
        </div>
        <BellNotifications overlap={wideLayout} />
        <MobileTabs items={links.filter(item => item.mobile)} className="layout__mobile-tabs" />
      </div>
    </React.Suspense>
  )
}

export default Layout
