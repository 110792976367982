import api from '../.'
import {
  type RequestVetecardPayload,
  type Species,
  type UserBasic,
  type VetecardAdvanced,
  type VetecardBasic,
  type VetecardFull,
} from '../../typings'
import toTitleCase from '../../utils/toTitleCase'
import { type FetchVetecardRequestDetailsResponseT } from '../pure/vetecard_request/details/fetch'
import { type GetAdministratorVetecardRequestsResponseT } from '../pure/vetecard_request/get_administrator'
import { type PostOwnerVetecardRequestResponseT } from '../pure/vetecard_request/post_owner'
import { type InsertVeterinarianVetecardRequestResponseT } from '../pure/veterinarian/vetecard_request/insert'
import { type ListVeterinarianVetecardRequestsResponseListBodyT } from '../pure/veterinarian/vetecard_request/list'
import { paths } from '../schema'

export const requestVetecard = async (
  currentUser: UserBasic,
  {
    keycloakId = '',
    veterinarianId,
    name,
    species,
    sex = null,
    breed = null,
    coatingColour = null,
    birthDate,
    identifierType,
    identifierNumber,
    identifierDate,
    identifierLocation,
    documentDate,
  }: RequestVetecardPayload
): Promise<VetecardBasic> => {
  const vetecardRequest = {
    name: toTitleCase(name),
    identifier:
      identifierType === 'none'
        ? { type: identifierType }
        : {
            type: identifierType,
            number: typeof identifierNumber === 'undefined' ? null : identifierNumber,
            date: typeof identifierDate === 'undefined' ? null : identifierDate,
            location: identifierLocation,
          },
    sex,
    species: {
      id: species,
      breed,
    },
    coatingColour,
    birthDate,
  }

  let vetecardId = 0

  switch (currentUser.userType) {
    case 'individual':
    case 'breeder':
    case 'association': {
      const response = await api.post(
        `0/owner/${currentUser.userType}/${currentUser.keycloakId}/vetecard/request`,
        {
          ...vetecardRequest,
          veterinarian: veterinarianId ? { id: veterinarianId } : null,
        }
      )
      const { id } = response.data as PostOwnerVetecardRequestResponseT
      vetecardId = id
      break
    }

    case 'veterinarian': {
      const ownerType = 'individual' //TODO: support for various user types
      const response = await api.post(
        `/0/veterinarian/${currentUser.veterinarianId}/vetecard/request/${ownerType}/${keycloakId}`,
        {
          ...vetecardRequest,
          ...(documentDate ? { consent: { documentDate: new Date(documentDate) } } : {}),
        }
      )
      const { id } = response.data as InsertVeterinarianVetecardRequestResponseT
      vetecardId = id
      break
    }

    default:
      throw new Error('Wrong userType')
  }
  return {
    id: vetecardId,
    name,
    species,
    birthDate,
    isRequest: true,
    requestStatus: currentUser.userType === 'individual' ? 'payment' : undefined,
  }
}

export const getVetecardRequestIdent = async (
  vetecardRequestId: VetecardBasic['id']
): Promise<VetecardFull> => {
  const response = await api.get(`/0/vetecard_request/${vetecardRequestId}`)
  const {
    id,
    animal: { name, species, birth, speciesBreed, sex, coatingColour },
    identifier,
    createdAt,
  } = response.data as FetchVetecardRequestDetailsResponseT

  return {
    id,
    name,
    species,
    birthDate: new Date(birth.date),
    breed: speciesBreed ?? undefined,
    sex: sex ?? undefined,
    coatingColour: coatingColour ?? undefined,
    identifier: {
      type: identifier.type,
      number:
        identifier.type === 'none' || identifier.number === null ? undefined : identifier.number,
      date:
        identifier.type === 'none' || identifier.date === null
          ? undefined
          : new Date(identifier.date),
      location:
        identifier.type === 'none' || identifier.location === null
          ? undefined
          : identifier.location,
    },
    createdAt: new Date(createdAt),
    // consentUrl: '/Engagement chien.docx',
    // requestStatus: actions.includes('payment') TODO: Enable afer actions array is provided to endpoint
    //       ? 'payment'
    //       : actions.includes('consent_agreement')
    //         ? 'consentAgreement'
    //     : 'processingPayment' as VetecardAdvanced['requestStatus'],
  }
}

export const getVetecardRequests = async (user: UserBasic): Promise<VetecardAdvanced[]> => {
  const { userType, keycloakId, veterinarianId } = user

  switch (userType) {
    case 'veterinarian': {
      if (typeof veterinarianId === 'undefined') {
        throw new Error(
          `Cannot "getVetecardRequests" as "veterinarianId" is not a valid veterinarian identifier: Provided user ${JSON.stringify(
            user
          )}`
        )
      }
      const response = await api.get(`/0/veterinarian/${veterinarianId}/vetecard/requests`)
      const data = response.data as ListVeterinarianVetecardRequestsResponseListBodyT

      return data.map(({ identifier, species, owner, birthDate, created, confirmed, ...rest }) => ({
        ...rest,
        identifier:
          identifier.type === 'none'
            ? {
                type: identifier.type,
                number: undefined,
                date: undefined,
                location: undefined,
              }
            : {
                ...identifier,
                number: identifier.number === null ? undefined : identifier.number,
                date: identifier.date === null ? undefined : identifier.date,
              },
        species: species as Species,
        owner: {
          userType: owner.type,
          keycloakId: owner.id,
          ...(owner.type === 'individual'
            ? {
                firstName: owner.firstName === null ? undefined : owner.firstName,
                lastName: owner.lastName === null ? undefined : owner.lastName,
              }
            : { institution: owner.name }),
        },
        birthDate: new Date(birthDate),
        createdAt: new Date(created),
        isConfirmed: confirmed,
        isRequest: true,
      }))
    }

    case 'administrator': {
      const response = await api.get(`/0/administrator/vetecard/requests`)
      const data = response.data as GetAdministratorVetecardRequestsResponseT

      return data.map(({ identifier, species, owner, birthDate, confirmed, created, ...rest }) => ({
        ...rest,
        identifier:
          identifier.type === 'none'
            ? {
                type: identifier.type,
                number: undefined,
                date: undefined,
                location: undefined,
              }
            : {
                ...identifier,
                number: identifier.number === null ? undefined : identifier.number,
                date: identifier.date === null ? undefined : identifier.date,
              },
        species: species as Species,
        owner: {
          userType: owner.type,
          keycloakId: owner.id,
          ...(owner.type === 'individual'
            ? {
                firstName: owner.firstName === null ? undefined : owner.firstName,
                lastName: owner.lastName === null ? undefined : owner.lastName,
              }
            : {
                institutions: owner.name ? [{ name: owner.name }] : [],
                firstName: owner.firstName === null ? undefined : owner.firstName,
                lastName: owner.lastName === null ? undefined : owner.lastName,
              }),
        },
        birthDate: new Date(birthDate),
        isConfirmed: confirmed,
        createdAt: new Date(created),
      }))
    }

    case 'individual':
    case 'breeder':
    case 'association': {
      const response = await api.get(`/0/owner/${userType}/${keycloakId}/vetecard/requests`)
      const data =
        response.data as paths['/0/owner/individual/{individual}/vetecard/requests']['get']['responses'][200]['content']['application/json']

      return data.map(
        ({ identifier, species, birthDate, confirmed, created, actions, ...rest }) => ({
          ...rest,
          identifier:
            identifier.type === 'none'
              ? {
                  type: identifier.type,
                  number: undefined,
                  date: undefined,
                  location: undefined,
                }
              : {
                  ...identifier,
                  number: identifier.number || undefined,
                  date: identifier.date ? new Date(identifier.date) : undefined,
                },
          species: species as Species,
          birthDate: new Date(birthDate),
          isConfirmed: confirmed,
          createdAt: new Date(created),
          owner: {
            keycloakId,
            userType,
          },
          isRequest: true,
          // requestStatus: 'consentAgreement' as VetecardAdvanced['requestStatus'],
          requestStatus: actions.includes('payment')
            ? 'payment'
            : actions.includes('consent')
            ? 'consentAgreement'
            : ('processingPayment' as VetecardAdvanced['requestStatus']),
        })
      )
    }

    case 'petsitter':
      return []

    default:
      throw new Error('Wrong userType')
  }
}

export const confirmVetecardRequest = async (
  user: UserBasic,
  vetecardRequestId: VetecardAdvanced['id']
): Promise<VetecardAdvanced['id']> => {
  await api.put(
    `/0/veterinarian/${user.veterinarianId}/vetecard/request/${vetecardRequestId}/confirmation`
  )
  return vetecardRequestId
}

export const validateDiscountCode = async (code: string) => {
  if (code.toUpperCase() === 'TESTPHASE') return 20 // no special characters support (i.e. '!')
  throw new Error('Invalid code')
}

export const activateVetecardRequest = async (
  vetecardRequestId: VetecardAdvanced['id']
): Promise<VetecardAdvanced['id']> => {
  await api.post(`/0/vetecard/request/${vetecardRequestId}/activate`)
  return vetecardRequestId
}
