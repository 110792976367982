import React from 'react'
import { darken, lighten } from 'polished'
import { useDropzone } from 'react-dropzone'
import Cropper from 'react-easy-crop'
import { type Area, type Point } from 'react-easy-crop/types'
import { maxRows, withFormControl } from 'react-form-component'
import { type fullTheme, setValue, type value } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'

const ImageUpload = ({
  name,
  value,
  mandatory,
  setValue,
  showName,
  aspect = 3 / 4,
}: ImageUploadProps) => {
  const classes = useStyles()
  // JSS instead of Sass is used here to pocencially easly migrate this input to
  // react-form-component library.
  const { t } = useTranslation()
  const [crop, setCrop] = React.useState<Point>({ x: 0, y: 0 })
  const [zoom, setZoom] = React.useState(1)

  const onDrop = React.useCallback((files: File[]) => {
    const fileReader = new FileReader()
    const { name: fileName, type: fileType } = files[0]
    const dataFile = files[0]
    fileReader.readAsDataURL(files[0])
    fileReader.onload = () => {
      const data = fileReader.result
      setValue(
        name,
        {
          name: fileName,
          type: fileType.split('/')[0],
          data,
          dataFile,
        },
        mandatory,
        { touched: true }
      )
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    onDrop,
  })

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setValue(
      name,
      {
        ...value,
        croppedAreaPixels,
      },
      mandatory,
      { touched: true }
    )
  }

  return !value ? (
    <div {...getRootProps({ className: classes.root })}>
      <input className={classes.input} name={name} id={name} {...getInputProps()} />
      <div className={classes.upload}>
        {isDragActive
          ? t('dragNDrop.dropHere', { count: 1 })
          : t('dragNDrop.instruction', { count: 1 })}
      </div>
    </div>
  ) : (
    <div className={classes.root}>
      <div className={classes.cropper}>
        <Cropper
          image={typeof value === 'string' && value.includes('http') ? value : value.data}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <input
        className={classes.slider}
        type="range"
        value={zoom}
        min="1"
        max="3"
        step="0.1"
        aria-labelledby="Zoom"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const zoom = e.target.value
          setZoom(Number(zoom))
        }}
      />
      {showName && (
        <div className={classes.filename}>
          {typeof value === 'string' ? value : value.name || value.data.split('/').pop()}
        </div>
      )}
      <button
        className={classes.delete}
        onClick={() => setValue(name, '', mandatory, { touched: true })}
      >
        {t('dragNDrop.clear')}
      </button>
    </div>
  )
}

export interface ImageUploadProps {
  name: string
  value: value
  mandatory?: boolean
  setValue: setValue
  showName?: boolean
  aspect?: number
}

const useStyles = createUseStyles((theme: fullTheme) => ({
  root: {
    textAlign: 'center',
    backgroundColor: theme.colors.fill,
    // maxWidth: 300,
    borderRadius: theme.sizes.borderRadius,
    overflow: 'hidden',
  },
  input: {
    display: 'none',
  },
  upload: {
    border: `2px dashed ${darken(0.2, theme.colors.inputBorder)}`,
    color: darken(0.3, theme.colors.inputBorder),
    padding: '32px 24px',
    margin: 16,
    cursor: 'pointer',
    fontSize: theme.typography.labelFontSize,
    lineHeight: 'normal',
    '&:hover': {
      backgroundColor: lighten(0.02, theme.colors.fill),
    },
  },
  cropper: {
    height: 256,
    position: 'relative',
  },
  slider: {
    display: 'block',
    margin: '16px auto',
    width: 'calc(100% - 128px)',
    minWidth: '50%',
  },
  delete: {
    backgroundColor: theme.colors.inputText,
    color: theme.colors.inputBg !== 'transparent' ? theme.colors.inputBg : 'white',
    fontSize: theme.typography.labelFontSize,
    borderRadius: theme.sizes.borderRadius,
    lineHeight: 'normal',
    padding: '4px 16px',
    marginBottom: 16,
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: lighten(0.02, theme.colors.accent),
    },
  },
  image: {
    display: 'block',
    margin: '0 auto 8px',
    maxWidth: '100%',
    boxSizing: 'border-box',
  },
  filename: {
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    margin: 8,
    fontSize: 11,
    color: theme.colors.inputText,
    lineHeight: 'normal',
    textAlign: 'center',
    ...maxRows(),
  },
}))

export default withFormControl(ImageUpload)
