import React from 'react'
import classNames from 'classnames'
import './style.sass'

export interface ButtonProps {
  children?: React.ReactNode
  component?: React.ElementType | string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  variant?: 'dark' | 'secondary' | 'ghost' | 'danger' | 'link'
  size?: 'xs' | 'sm' | 'lg'
  block?: boolean
  disabled?: boolean
  className?: string
  [key: string]: any
}

const Button = ({
  children,
  onClick,
  component = 'button',
  variant,
  size,
  block,
  disabled,
  className,
  ...otherProps
}: ButtonProps) => {
  const ComponentProp = component
  return (
    <ComponentProp
      className={classNames(
        'btn',
        {
          [`btn--${variant}`]: variant,
          [`btn--${size}`]: size,
          'btn--block': block,
          'btn--disabled': disabled,
        },
        className
      )}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        if (component === 'button') e.preventDefault()
        onClick && onClick(e)
      }}
      {...otherProps}
    >
      {children}
    </ComponentProp>
  )
}

export const ButtonGroup = ({ combined, children }: IconButtonGroupProps) => (
  <div
    className={classNames('btn-group', {
      'btn-group--combined': combined,
    })}
  >
    {children}
  </div>
)

interface IconButtonGroupProps {
  combined?: boolean
  children?: React.ReactNode
}

export default Button
