import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { getVetecardFull } from '../api/formatted/vetecard'
import Accordion, { AccordionItem } from '../components/Accordion'
import Alert from '../components/Alert'
import Box from '../components/Box'
import Content from '../components/Content'
import Grid from '../components/Grid'
import Identification from '../components/Identification'
import Loader from '../components/Loader'
import Modal, { type ModalRef } from '../components/Modal'
import Tabs from '../components/Tabs'
import Text from '../components/Text'
import Title from '../components/Title'
import VetecardHead from '../components/VetecardHead'
import ConsentContainer from '../containers/ConsentContainer'
import FilesContainer from '../containers/FilesContainer'
import MainVeterinarianContainer from '../containers/MainVeterinarianContainer'
import SharedWithOtherContainer from '../containers/SharedWithOtherContainer'
import SharedWithVetContainer from '../containers/SharedWithVetContainer'
import VaccinationsContainer from '../containers/VaccinationsContainer'
// import FormAssignOwnership from '../forms/FormAssignOwnership'
import FormTransferOwnership from '../forms/FormTransferOwnership'
import useAuth from '../utils/useAuth'

const Vetecard = () => {
  const { id } = useParams()
  const vetecardId = id ? parseInt(id) : 0
  const { hash } = useLocation()
  const { t } = useTranslation()
  const {
    // currentUser: { userType },
    hasPermission,
  } = useAuth()
  // const isVeterinarian = userType === 'veterinarian'
  const [expandedColumns, setExpandedColumns] = React.useState<string[]>([])

  const shareTransferModalRef = React.useRef<ModalRef>(null)

  const {
    data: ident,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ['vetecard', vetecardId],
    queryFn: () => getVetecardFull(vetecardId),
  })

  return (
    <Loader loading={isFetching} error={isError}>
      <Content wide>
        <Title hideDesktop>{t('vetecard')}</Title>
        {ident && (
          <>
            <VetecardHead
              vetecardId={ident.id}
              name={ident.name}
              email={ident.email}
              species={ident.species}
              photo={ident.photo}
            />
            <Accordion multiple onToggle={openedItems => setExpandedColumns(openedItems)}>
              <Grid onlyDesktop noStretch={expandedColumns.length < 2}>
                <AccordionItem title={t('identification')} defaultOpen>
                  <Identification
                    ident={ident}
                    canEdit={hasPermission('vetecards-edit_vetecard_details')}
                  />
                </AccordionItem>
                <AccordionItem title={t('mainVeterinarian')} defaultOpen noPadding>
                  <MainVeterinarianContainer vetecardId={vetecardId} />
                </AccordionItem>
              </Grid>
            </Accordion>

            <Accordion>
              <AccordionItem title={t('vaccinations')} scrollIntoView={hash === '#vaccinations'}>
                <Alert
                  type="info"
                  primary={t('demoData.title')}
                  secondary={t('demoData.descriptionVaccinations')}
                />
                <Alert
                  type="info"
                  primary={t('reminder.title')}
                  secondary={t('reminder.description')}
                />
                <Tabs
                  labels={[
                    <>
                      <span className="visible-xs-inline">{t('mandatory')}</span>
                      <span className="hidden-xs"> {t('mandatoryVaccinations')}</span>
                    </>,
                    t('treatment'),
                    <>
                      <span className="visible-xs-inline">{t('other')}</span>
                      <span className="hidden-xs"> {t('otherVaccinations')}</span>
                    </>,
                  ]}
                >
                  <VaccinationsContainer vetecardId={ident.id} category="mandatory" />
                  <FilesContainer vetecardId={ident.id} category="treatment" />
                  <VaccinationsContainer vetecardId={ident.id} category="other" />
                </Tabs>
              </AccordionItem>
              <AccordionItem title={t('medicalFollowup')}>
                <Alert
                  type="info"
                  primary={t('demoData.title')}
                  secondary={t('demoData.descriptionMedicalFiles')}
                />
                <Tabs
                  labels={[
                    t('medicalFiles'),
                    <>
                      <span className="visible-xs-inline">{t('prescriptions')}</span>
                      <span className="hidden-xs"> {t('billsPrescriptions')}</span>
                    </>,
                    t('engagement'),
                  ]}
                >
                  <FilesContainer vetecardId={ident.id} category="medical" />
                  <FilesContainer vetecardId={ident.id} category="invoice" />
                  <ConsentContainer vetecardId={ident.id} />
                </Tabs>
              </AccordionItem>
              <AccordionItem title={t('accessManagement.title')}>
                <Box
                  title={t('shareWithVet.title')}
                  description={t('shareWithVet.description')}
                  footer={<SharedWithVetContainer vetecardId={ident.id} />}
                />
                <Box
                  title={t('shareWithOther.title')}
                  description={t('shareWithOther.description')}
                  footer={<SharedWithOtherContainer vetecardId={ident.id} />}
                />
                <Box
                  title={t('transferOwnership.title')}
                  description={t('transferOwnership.description')}
                  btnLabel={t('Transfer')}
                  onClick={() => shareTransferModalRef.current?.open()}
                />
                <Modal size="lg" title={t('transferOwnership.title')} ref={shareTransferModalRef}>
                  <Text>{t('transferOwnership.description')}</Text>
                  <FormTransferOwnership vetecardId={ident.id} />
                </Modal>
              </AccordionItem>
              {/* {ident && isVeterinarian && (
                <AccordionItem title={t('ownershipAssignment')}>
                  <FormAssignOwnership vetecardId={ident.id} />
                </AccordionItem>
              )} */}
            </Accordion>
          </>
        )}
      </Content>
    </Loader>
  )
}

export default Vetecard
