import React from 'react'
import { useAtom } from 'jotai'
import BellPendingSvg from '../../assets/icons/bell-pending.svg'
import BellSvg from '../../assets/icons/bell.svg'
import { showBellNotificationsAtom } from '../../globalState'
import useAuth from '../../utils/useAuth'
import './style.sass'

const Bell = () => {
  const [showBellNotifications, setShowBellNotifications] = useAtom(showBellNotificationsAtom)
  const { notifications } = useAuth()
  const isPending = notifications.length > 0

  return (
    <div className="bell" onClick={() => setShowBellNotifications(!showBellNotifications)}>
      {isPending ? <BellPendingSvg /> : <BellSvg />}
    </div>
  )
}

export default Bell
