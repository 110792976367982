import React from 'react'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@tanstack/react-query'
import Form, { FormActions, Input } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createCheckoutSession } from '../api/formatted/payment'
import { getVetecardRequests } from '../api/formatted/vetecardRequests'
import Alert from '../components/Alert'
import Block from '../components/Block'
import Content from '../components/Content'
import Loader from '../components/Loader'
import Panel from '../components/Panel'
import SubmitButton from '../components/SubmitButton'
import Table from '../components/Table'
import Text from '../components/Text'
import Title from '../components/Title'
// import IndividualSearchInput from '../containers/IndividualSearchInput'
import formatPrice from '../utils/formatPrice'
import useAuth from '../utils/useAuth'
import useLocalStorage from '../utils/useLocalStorage'

interface FormValues {
  code?: string
  ambassador?: string
}

const Payment = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const [paymentActive, setPaymentActive] = useLocalStorage<boolean>('paymentActive', false)
  const singlePrice = 60

  const {
    data = [],
    isFetching,
    isError,
  } = useQuery(['vetecard-requests'], () => getVetecardRequests(currentUser))

  const unpaidData = React.useMemo(
    () => data.filter(item => item.requestStatus === 'payment'),
    [data]
  )

  const handleSubmit = ({ code, ambassador }: FormValues) => {
    try {
      setPaymentActive(true)
      const stripedCode = code && code.replace(/\s/g, '')
      createCheckoutSession(unpaidData, stripedCode, ambassador)
    } catch (error) {
      toast.error(t('paymentGatewayError'))
      setPaymentActive(false)
    }
  }

  React.useEffect(() => {
    // If user navigate back from payment gate, redirect to cancel page.
    if (paymentActive) {
      setPaymentActive(false)
      navigate('/payment/cancel')
    }
  }, [])

  return (
    <Content>
      <Title>{t('payment.title')}</Title>
      <Loader loading={isFetching} error={isError}>
        {unpaidData.length ? (
          <div>
            <Text>{t('payment.description')}</Text>
            <Panel>
              <Block title={t('cart')} condensed>
                <Table
                  toRight
                  labels={[
                    { name: t('Item'), field: 'item' },
                    { name: t('Price'), field: 'price' },
                  ]}
                  data={[
                    ...unpaidData.map(item => ({
                      item: (
                        <>
                          {item.name} {t('vetecardActivation')}
                        </>
                      ),
                      price: formatPrice(singlePrice),
                    })),
                    {
                      item: <strong>Total:</strong>,
                      price: <strong>{formatPrice(data.length * singlePrice)}</strong>,
                    },
                  ]}
                />
              </Block>
              <Form fields={['code', 'ambassador']}>
                <Block title={t('discountCode')} condensed>
                  <Input name="code" help={t('discountCodeDescr')} />
                  {/* <IndividualSearchInput
                  name="ambassador"
                  label={t('ambassador')}
                  help={t('ambassadorSearch')}
                /> */}
                </Block>
                <SubmitButton onClick={handleSubmit}>
                  <FontAwesomeIcon icon={faCreditCard} /> {t('payment.payNow')}
                </SubmitButton>
              </Form>
            </Panel>
          </div>
        ) : (
          <Alert
            type="info"
            primary={t('noResults.title')}
            secondary={t('noResults.requestsCart')}
          />
        )}
      </Loader>
    </Content>
  )
}

export default Payment
