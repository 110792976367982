import React from 'react'
import classNames from 'classnames'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import CloseSvg from '../../assets/icons/close.svg'
import { showBellNotificationsAtom } from '../../globalState'
import useAuth from '../../utils/useAuth'
import Count from '../Count'
import Notification from '../Notification'
import './style.sass'

interface BellNotificationsProps {
  overlap?: boolean
}

const BellNotifications = ({ overlap }: BellNotificationsProps) => {
  const [showBellNotifications, setShowBellNotifications] = useAtom(showBellNotificationsAtom)
  const { notifications } = useAuth()

  const { t } = useTranslation()

  return (
    <div
      className={classNames('bell-notifications', {
        'bell-notifications--open': showBellNotifications,
        'bell-notifications--overlap': overlap,
      })}
    >
      <div className="bell-notifications__inner">
        <div className="bell-notifications__head">
          <div className="bell-notifications__title">
            {t('notifications.title')} <Count number={notifications.length} />
          </div>
          <button
            className="bell-notifications__close"
            onClick={() => setShowBellNotifications(!showBellNotifications)}
          >
            <CloseSvg />
          </button>
        </div>
        {notifications.length ? (
          notifications.map(({ id, ...rest }) => <Notification key={id} {...rest} />)
        ) : (
          <div className="bell-notifications__empty">{t('notifications.empty')}</div>
        )}
      </div>
    </div>
  )
}

export default BellNotifications
