import React from 'react'
import classNames from 'classnames'
import './style.sass'

interface TooltipProps {
  direction?: 'tl' | 'tr' | 'bl' | 'br'
  message: string
  children: React.ReactNode
}

const Tooltip = ({ direction = 'tl', message, children }: TooltipProps) => (
  <div className={classNames('tooltip', `tooltip--${direction}`)} data-tooltip={message}>
    {children}
  </div>
)

export default Tooltip
