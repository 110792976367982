import React from 'react'
import classNames from 'classnames'
import './style.sass'

interface GridProps {
  columns?: 2 | 4
  noGap?: boolean
  onlyDesktop?: boolean
  noStretch?: boolean
  children: React.ReactNode
}

const Grid = ({ columns = 2, noGap, onlyDesktop, noStretch, children }: GridProps) => (
  <div
    className={classNames('grid', `grid--${columns}`, {
      'grid--no-gap': noGap,
      'grid--only-desktop': onlyDesktop,
      'grid--no-stretch': noStretch,
    })}
  >
    {children}
  </div>
)

export default Grid
