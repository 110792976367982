import { pipe } from 'fp-ts/function'
import api from '../.'
import {
  type ShareWithOtherPayload,
  type ShareWithVetPayload,
  type SharedFileIds,
  type UpdateShareOtherPayload,
  type UserBasic,
  type UserShared,
  type UserType,
  type VetecardBasic,
  type VeterinarianBasic,
} from '../../typings'
import { isOwnerType } from '../../utils/helpers'
import { type EmployedVeterinarian } from '../pure/employed_veterinarian'
import { type ListOwnerVetecardPetsitterInvoicesResponseT } from '../pure/owner/vetecard/invoices/petsitter/list'
import { type SetOwnerVetecardPetsitterInvoicesResponseT } from '../pure/owner/vetecard/invoices/petsitter/set'
import { type ListOwnerVetecardVeterinarianInvoicesResponseT } from '../pure/owner/vetecard/invoices/veterinarian/list'
import { type SetOwnerVetecardVeterinarianInvoicesResponseT } from '../pure/owner/vetecard/invoices/veterinarian/set'
import { type ListOwnerVetecardPetsitterMedicalFilesResponseT } from '../pure/owner/vetecard/medical_files/petsitter/list'
import { type SetOwnerVetecardPetsitterMedicalFilesResponseT } from '../pure/owner/vetecard/medical_files/petsitter/set'
import { type ListOwnerVetecardVeterinarianMedicalFilesResponseT } from '../pure/owner/vetecard/medical_files/veterinarian/list'
import { type SetOwnerVetecardVeterinarianMedicalFilesResponseT } from '../pure/owner/vetecard/medical_files/veterinarian/set'
import {
  type InsertOwnerVetecardPetsitterRequestT,
  type InsertOwnerVetecardPetsitterResponseInsertionBodyT,
} from '../pure/owner/vetecard/petsitter/insert'
import { type ListOwnerVetecardPetsitterTreatmentsResponseT } from '../pure/owner/vetecard/treatments/petsitter/list'
import { type SetOwnerVetecardPetsitterTreatmentsResponseT } from '../pure/owner/vetecard/treatments/petsitter/set'
import { type ListOwnerVetecardVeterinarianTreatmentsResponseT } from '../pure/owner/vetecard/treatments/veterinarian/list'
import { type SetOwnerVetecardVeterinarianTreatmentsResponseT } from '../pure/owner/vetecard/treatments/veterinarian/set'
import { type SearchPetsitterProfileResponseT } from '../pure/petsitter/profile/search'
import { type ListPetsitterVetecardPetsitterInvoicesResponseT } from '../pure/petsitter/vetecard/invoices/petsitter/list'
import { type SetPetsitterVetecardPetsitterInvoicesResponseT } from '../pure/petsitter/vetecard/invoices/petsitter/set'
import { type ListPetsitterVetecardVeterinarianInvoicesResponseT } from '../pure/petsitter/vetecard/invoices/veterinarian/list'
import { type SetPetsitterVetecardVeterinarianInvoicesResponseT } from '../pure/petsitter/vetecard/invoices/veterinarian/set'
import { type ListPetsitterVetecardPetsitterMedicalFilesResponseT } from '../pure/petsitter/vetecard/medical_files/petsitter/list'
import { type SetPetsitterVetecardPetsitterMedicalFilesResponseT } from '../pure/petsitter/vetecard/medical_files/petsitter/set'
import { type ListPetsitterVetecardVeterinarianMedicalFilesResponseT } from '../pure/petsitter/vetecard/medical_files/veterinarian/list'
import { type SetPetsitterVetecardVeterinarianMedicalFilesResponseT } from '../pure/petsitter/vetecard/medical_files/veterinarian/set'
import { type InsertPetsitterVetecardPetsitterResponseInsertionBodyT } from '../pure/petsitter/vetecard/petsitter/insert'
import { type ListPetsitterVetecardPetsitterTreatmentsResponseT } from '../pure/petsitter/vetecard/treatments/petsitter/list'
import { type SetPetsitterVetecardPetsitterTreatmentsResponseT } from '../pure/petsitter/vetecard/treatments/petsitter/set'
import { type ListPetsitterVetecardVeterinarianTreatmentsResponseT } from '../pure/petsitter/vetecard/treatments/veterinarian/list'
import { type SetPetsitterVetecardVeterinarianTreatmentsResponseT } from '../pure/petsitter/vetecard/treatments/veterinarian/set'
import { type RemoveVetecardPetsitterResponsibilityResponseT } from '../pure/vetecard/petsitter/remove'
import { type ListVeterinarianVetecardVeterinarianInvoicesResponseInvoicesT } from '../pure/veterinarian/vetecard/invoices/veterinarian/list'
import { type SetVeterinarianVetecardVeterinarianInvoicesResponseT } from '../pure/veterinarian/vetecard/invoices/veterinarian/set'
import { type ListVeterinarianVetecardVeterinarianMedicalFilesResponseMedicalFilesT } from '../pure/veterinarian/vetecard/medical_files/veterinarian/list'
import { type SetVeterinarianVetecardVeterinarianMedicalFilesResponseT } from '../pure/veterinarian/vetecard/medical_files/veterinarian/set'
import { type ListVeterinarianVetecardVeterinarianTreatmentsResponseTreatmentsT } from '../pure/veterinarian/vetecard/treatments/veterinarian/list'
import { type SetVeterinarianVetecardVeterinarianTreatmentsResponseT } from '../pure/veterinarian/vetecard/treatments/veterinarian/set'

export const shareWithVet = async (
  { userType }: UserBasic,
  {
    vetecardId,
    veterinarianId,
    timeRangeType,
    dateFrom: dateFromString,
    dateTo: dateToString,
  }: ShareWithVetPayload
): Promise<UserShared> => {
  const dateFrom =
    typeof dateFromString !== 'undefined' && dateFromString !== '' ? new Date(dateFromString) : null
  const dateTo =
    typeof dateToString !== 'undefined' && dateToString !== '' ? new Date(dateToString) : null
  const duration =
    timeRangeType === 'date_range'
      ? {
          type: timeRangeType,
          from: dateFrom,
          to: dateTo,
        }
      : {
          type: timeRangeType,
        }

  await api.post(`/0/vetecard/${vetecardId}/veterinarian/${veterinarianId}`, {
    type: 'temporary',
    duration,
    confirmedByOwner: isOwnerType(userType),
    confirmedByVeterinarian: userType === 'veterinarian',
  })
  const {
    data: { firstName, lastName },
  } = (await api.get(`/1/veterinarian/preview/${veterinarianId}`)) as {
    data: EmployedVeterinarian
  }

  return {
    keycloakId: veterinarianId.toString(),
    veterinarianId,
    userType: 'veterinarian',
    firstName,
    lastName,
    timeRangeType,
    dateFrom: timeRangeType === 'oneyear' ? new Date() : dateFrom || undefined,
    dateTo:
      timeRangeType === 'oneyear'
        ? pipe(
            new Date(),
            from => [from, new Date(from.valueOf())],
            ([from, to]) => {
              to.setFullYear(from.getFullYear() + 1)
              return to
            }
          )
        : dateTo || undefined,
  }
}

export const shareWithOther = async (
  { userType, keycloakId }: UserBasic,
  payload: ShareWithOtherPayload
): Promise<UserShared> => {
  const { vetecardId, otherUserType, email, timeRangeType, dateFrom, dateTo } = payload

  if (otherUserType !== 'petsitter') {
    throw new Error(
      `Tried to "share with other" with not supported "otherUserType" ${JSON.stringify(
        otherUserType
      )}`
    )
  }

  const { data: possiblySharedUsers } = (await api.get(`/0/petsitter/search/${email}`)) as {
    data: SearchPetsitterProfileResponseT
  }
  const sharedUser = possiblySharedUsers.find(user => user.email === email)
  let sharedUserId
  if (sharedUser) {
    sharedUserId = sharedUser.id
  }
  let duration: InsertOwnerVetecardPetsitterRequestT['duration']
  switch (timeRangeType) {
    case 'date_range':
      {
        if (typeof dateFrom === 'undefined')
          throw new Error(
            `Cannot share with "other" without specifying "dateFrom". Provided: ${JSON.stringify(
              dateFrom
            )}`
          )
        const from = new Date(dateFrom)
        if (isNaN(from.valueOf())) {
          throw new Error(
            `Cannot share with "other" without specifying valid "dateFrom". Provided: ${JSON.stringify(
              dateFrom
            )}`
          )
        }

        if (typeof dateTo === 'undefined') {
          throw new Error(
            `Cannot share with "other" without specifying "dateTo". Provided: ${JSON.stringify(
              dateTo
            )}`
          )
        }
        const to = new Date(dateTo)
        if (isNaN(to.valueOf())) {
          throw new Error(
            `Cannot share with "other" without specifying valid "dateTo". Provided: ${JSON.stringify(
              dateTo
            )}`
          )
        }

        duration = {
          type: 'date_range',
          from,
          to,
        }
      }
      break
    case 'oneyear':
      duration = {
        type: 'oneyear',
      }
      break
    case 'forever':
      duration = {
        type: 'forever',
      }
      break
    default:
      throw new Error(
        `Cannot share with "other" without providing valid "timeRangeType". Provided: ${JSON.stringify(
          timeRangeType
        )}`
      )
  }

  switch (userType) {
    case 'individual':
    case 'association':
    case 'breeder': {
      const { data } = (await api.post(
        `/0/owner/${userType}/${keycloakId}/vetecard/${vetecardId}/petsitter`,
        { petsitter: { id: sharedUserId }, duration }
      )) as { data: InsertOwnerVetecardPetsitterResponseInsertionBodyT }
      return {
        keycloakId: data.petsitter.id,
        firstName: data.petsitter.firstName,
        lastName: data.petsitter.lastName,
        userType: 'petsitter' as UserType,
        timeRangeType: data.duration.type,
        dateFrom:
          data.duration.type === 'date_range' || data.duration.type === 'oneyear'
            ? data.duration.from
            : undefined,
        dateTo:
          data.duration.type === 'date_range'
            ? data.duration.to
            : data.duration.type === 'oneyear'
            ? pipe(
                data.duration.from,
                from => [from, new Date(from.valueOf())],
                ([from, to]) => {
                  to.setFullYear(from.getFullYear() + 1)
                  return to
                }
              )
            : undefined,
      }
    }
    case 'petsitter': {
      const { data } = (await api.post(
        `/0/petsitter/${keycloakId}/vetecard/${vetecardId}/petsitter`,
        { petsitter: { id: sharedUserId }, duration }
      )) as { data: InsertPetsitterVetecardPetsitterResponseInsertionBodyT }
      return {
        keycloakId: data.petsitter.id,
        firstName: data.petsitter.firstName,
        lastName: data.petsitter.lastName,
        userType: 'petsitter',
        timeRangeType: data.duration.type,
        dateFrom:
          data.duration.type === 'date_range' || data.duration.type === 'oneyear'
            ? data.duration.from
            : undefined,
        dateTo:
          data.duration.type === 'date_range'
            ? data.duration.to
            : data.duration.type === 'oneyear'
            ? pipe(
                data.duration.from,
                from => [from, new Date(from.valueOf())],
                ([from, to]) => {
                  to.setFullYear(from.getFullYear() + 1)
                  return to
                }
              )
            : undefined,
      }
    }
    default:
      throw new Error(
        `Cannot share with "other". Undefined behaviour for "userType": ${JSON.stringify(userType)}`
      )
  }
}

export const updateShareOther = async (
  _currentUser: UserBasic,
  vetecardId: number,
  targetUser: UserShared | undefined,
  { dateFrom, dateTo, timeRangeType }: UpdateShareOtherPayload
) => {
  if (typeof targetUser === 'undefined') {
    throw new Error(`Updating share of "other" requires a valid Target User Identifier`)
  }
  if (typeof targetUser === 'undefined' || targetUser.userType !== 'petsitter') {
    throw new Error(
      `Updating share of "other" is implemented for Target User Identifier being a Petsitter only. Provided: ${JSON.stringify(
        targetUser.userType
      )}`
    )
  }
  const duration = { type: timeRangeType, from: dateFrom, to: dateTo }
  await api.patch(`/0/vetecard/${vetecardId}/petsitter/${targetUser.keycloakId}`, duration)
}

export const revokeVetAccess = async (
  vetecardId: VetecardBasic['id'],
  veterinarianId?: VeterinarianBasic['veterinarianId']
): Promise<VeterinarianBasic['veterinarianId']> => {
  if (!veterinarianId) {
    throw new Error('Missing veterinarianId.')
  }
  await api.delete(`/0/vetecard/${vetecardId}/veterinarian/${veterinarianId}`)
  return veterinarianId
}

// FIXME: missing information about user type we're removing
export const revokeOtherAccess = async (
  vetecardId: VetecardBasic['id'],
  keycloakId: UserShared['keycloakId']
) => {
  const {
    data: {
      petsitter: { id },
    },
  } = (await api.delete(`/0/vetecard/${vetecardId}/petsitter/${keycloakId}`)) as {
    data: RemoveVetecardPetsitterResponsibilityResponseT
  }
  return id
}

export const shareFilesWithVet = async (
  user: UserBasic,
  payload: SharedFileIds,
  veterinarianId: VeterinarianBasic['veterinarianId'],
  vetecardId: number
): Promise<SharedFileIds> => {
  const { userType, keycloakId, veterinarianId: sharingVeterinarianId = 0 } = user

  switch (userType) {
    case 'individual':
    case 'breeder':
    case 'association': {
      const { data: treatmentFiles } = (await api.put(
        `/0/owner/${userType}/${keycloakId}/vetecard/${vetecardId}/treatments/veterinarian/${veterinarianId}`,
        {
          treatmentResponsibilities: payload.treatmentFiles.map(id => ({
            treatment: { id },
            responsibility: { confirmedByOwner: true, confirmedByVeterinarian: false },
          })),
        }
      )) as { data: SetOwnerVetecardVeterinarianTreatmentsResponseT }

      const { data: medicalFiles } = (await api.put(
        `/0/owner/${userType}/${keycloakId}/vetecard/${veterinarianId}/medical_files/veterinarian/${veterinarianId}`,
        {
          medicalFileResponsibilities: payload.medicalFiles.map(id => ({
            medicalFile: { id },
            responsibility: { confirmedByOwner: true, confirmedByVeterinarian: false },
          })),
        }
      )) as { data: SetOwnerVetecardVeterinarianMedicalFilesResponseT }

      const { data: invoices } = (await api.put(
        `/0/owner/${userType}/${keycloakId}/vetecard/${vetecardId}/invoices/veterinarian/${veterinarianId}`,
        {
          invoiceResponsibilities: payload.invoices.map(id => ({
            invoice: { id },
            responsibility: { confirmedByOwner: true, confirmedByVeterinarian: false },
          })),
        }
      )) as { data: SetOwnerVetecardVeterinarianInvoicesResponseT }

      return {
        treatmentFiles: treatmentFiles.map(({ treatment: { id } }) => id),
        medicalFiles: medicalFiles.map(({ medicalFile: { id } }) => id),
        invoices: invoices.map(({ invoice: { id } }) => id),
      }
    }

    case 'petsitter': {
      const { data } = (await api.put(
        `/0/petsitter/${keycloakId}/vetecard/${vetecardId}/treatments/veterinarian/${veterinarianId}`,
        {
          treatmentResponsibilities: payload.treatmentFiles.map(id => ({
            treatment: { id },
            responsibility: { confirmedByOwner: true, confirmedByVeterinarian: false },
          })),
        }
      )) as { data: SetPetsitterVetecardVeterinarianTreatmentsResponseT }
      const treatmentFiles = data

      const { data: medicalFiles } = (await api.put(
        `/0/petsitter/${keycloakId}/vetecard/${vetecardId}/medical_files/veterinarian/${veterinarianId}`,
        {
          medicalFileResponsibilities: payload.medicalFiles.map(id => ({
            medicalFile: { id },
            responsibility: { confirmedByOwner: true, confirmedByVeterinarian: false },
          })),
        }
      )) as { data: SetPetsitterVetecardVeterinarianMedicalFilesResponseT }

      const { data: invoices } = (await api.put(
        `/0/petsitter/${keycloakId}/vetecard/${vetecardId}/invoices/veterinarian/${veterinarianId}`,
        {
          invoiceResponsibilities: payload.invoices.map(id => ({
            invoice: { id },
            responsibility: { confirmedByOwner: true, confirmedByVeterinarian: false },
          })),
        }
      )) as { data: SetPetsitterVetecardVeterinarianInvoicesResponseT }

      return {
        treatmentFiles: treatmentFiles.map(({ treatment: { id } }) => id),
        medicalFiles: medicalFiles.map(({ medicalFile: { id } }) => id),
        invoices: invoices.map(({ invoice: { id } }) => id),
      }
    }

    case 'veterinarian': {
      const { data: treatmentFiles } = (await api.put(
        `/0/veterinarian/${sharingVeterinarianId}/vetecard/${vetecardId}/treatments/veterinarian/${veterinarianId}`,
        {
          treatmentResponsibilities: payload.treatmentFiles.map(id => ({
            treatment: { id },
            responsibility: { confirmedByOwner: false, confirmedByVeterinarian: true },
          })),
        }
      )) as { data: SetVeterinarianVetecardVeterinarianTreatmentsResponseT }

      const { data: medicalFiles } = (await api.put(
        `/0/veterinarian/${sharingVeterinarianId}/vetecard/${vetecardId}/medical_files/veterinarian/${veterinarianId}`,
        {
          medicalFileResponsibilities: payload.medicalFiles.map(id => ({
            medicalFile: { id },
            responsibility: { confirmedByOwner: false, confirmedByVeterinarian: true },
          })),
        }
      )) as { data: SetVeterinarianVetecardVeterinarianMedicalFilesResponseT }

      const { data: invoices } = (await api.put(
        `/0/veterinarian/${sharingVeterinarianId}/vetecard/${vetecardId}/invoices/veterinarian/${veterinarianId}`,
        {
          invoiceResponsibilities: payload.invoices.map(id => ({
            invoice: { id },
            responsibility: { confirmedByOwner: false, confirmedByVeterinarian: true },
          })),
        }
      )) as { data: SetVeterinarianVetecardVeterinarianInvoicesResponseT }

      return {
        treatmentFiles: treatmentFiles.map(({ treatment: { id } }) => id),
        medicalFiles: medicalFiles.map(({ medicalFile: { id } }) => id),
        invoices: invoices.map(({ invoice: { id } }) => id),
      }
    }

    default:
      throw new Error(`Invalid requesting userType ${userType}`)
  }
}

// FIXME: provide distinction for "other" in case it's not always a petsitter
export const shareFilesWithOther = async (
  user: UserBasic,
  payload: SharedFileIds,
  keycloakId: UserBasic['keycloakId'],
  vetecardId: number
): Promise<SharedFileIds> => {
  const owner = user

  switch (owner.userType) {
    case 'individual':
    case 'breeder':
    case 'association': {
      const { data: treatmentResponsibilities } = (await api.put(
        `/0/owner/${owner.userType}/${owner.keycloakId}/vetecard/${vetecardId}/treatments/petsitter/${keycloakId}`,
        payload.treatmentFiles.map(id => ({ treatment: { id } }))
      )) as { data: SetOwnerVetecardPetsitterTreatmentsResponseT }

      const { data: invoiceResponsibilities } = (await api.put(
        `/0/owner/${owner.userType}/${owner.keycloakId}/vetecard/${vetecardId}/invoices/petsitter/${keycloakId}`,
        payload.invoices.map(id => ({ invoice: { id } }))
      )) as { data: SetOwnerVetecardPetsitterInvoicesResponseT }

      const { data: medicalFileResponsibilities } = (await api.put(
        `/${0}/owner/${owner.userType}/${
          owner.keycloakId
        }/vetecard/${vetecardId}/medical_files/petsitter/${keycloakId}`,
        payload.medicalFiles.map(id => ({ medicalFile: { id } }))
      )) as { data: SetOwnerVetecardPetsitterMedicalFilesResponseT }

      return {
        treatmentFiles: treatmentResponsibilities.map(({ treatment: { id } }) => id),
        medicalFiles: medicalFileResponsibilities.map(({ medicalFile: { id } }) => id),
        invoices: invoiceResponsibilities.map(({ invoice: { id } }) => id),
      }
    }

    case 'petsitter': {
      const { data: treatmentResponsibilities } = (await api.put(
        `/0/petsitter/${user.keycloakId}/vetecard/${vetecardId}/treatments/petsitter/${keycloakId}`,
        payload.treatmentFiles.map(id => ({ treatment: { id } }))
      )) as { data: SetPetsitterVetecardPetsitterTreatmentsResponseT }

      const { data: medicalFileResponsibilities } = (await api.put(
        `/0/petsitter/${user.keycloakId}/vetecard/${vetecardId}/medical_files/petsitter/${keycloakId}`,
        payload.medicalFiles.map(id => ({ medicalFile: { id } }))
      )) as { data: SetPetsitterVetecardPetsitterMedicalFilesResponseT }

      const { data: invoiceResponsibilities } = (await api.put(
        `/0}/petsitter/${user.keycloakId}/vetecard/${vetecardId}/invoices/petsitter/${keycloakId}`,
        payload.invoices.map(id => ({ invoice: { id } }))
      )) as { data: SetPetsitterVetecardPetsitterInvoicesResponseT }

      return {
        treatmentFiles: treatmentResponsibilities.map(({ treatment: { id } }) => id),
        medicalFiles: medicalFileResponsibilities.map(({ medicalFile: { id } }) => id),
        invoices: invoiceResponsibilities.map(({ invoice: { id } }) => id),
      }
    }

    default:
      throw new Error(
        `Failed to "share files with other", because of invalid requesting user type ${JSON.stringify(
          user
        )}`
      )
  }
}

export const getSharedVetFiles = async (
  user: UserBasic,
  vetecardId: VetecardBasic['id'],
  veterinarianId: VeterinarianBasic['veterinarianId']
): Promise<SharedFileIds> => {
  const { userType, keycloakId, veterinarianId: sharingVeterinarianId = 0 } = user

  switch (userType) {
    case 'individual':
    case 'breeder':
    case 'association': {
      const { data: treatmentFiles } = (await api.get(
        `/0/owner/${userType}/${keycloakId}/vetecard/${vetecardId}/treatments/veterinarian/${veterinarianId}`
      )) as { data: ListOwnerVetecardVeterinarianTreatmentsResponseT }

      const { data: medicalFiles } = (await api.get(
        `/0/owner/${userType}/${keycloakId}/vetecard/${vetecardId}/medical_files/veterinarian/${veterinarianId}`
      )) as { data: ListOwnerVetecardVeterinarianMedicalFilesResponseT }

      const { data: invoices } = (await api.get(
        `/0/owner/${userType}/${keycloakId}/vetecard/${vetecardId}/invoices/veterinarian/${veterinarianId}`
      )) as { data: ListOwnerVetecardVeterinarianInvoicesResponseT }

      return {
        treatmentFiles: treatmentFiles.map(({ treatment: { id } }) => id),
        medicalFiles: medicalFiles.map(({ medicalFile: { id } }) => id),
        invoices: invoices.map(({ invoice: { id } }) => id),
      }
    }

    case 'petsitter': {
      const { data: treatmentFiles } = (await api.get(
        `/0/petsitter/${keycloakId}/vetecard/${vetecardId}/treatments/veterinarian/${veterinarianId}`
      )) as { data: ListPetsitterVetecardVeterinarianTreatmentsResponseT }

      const { data: medicalFiles } = (await api.get(
        `0/petsitter/${keycloakId}/vetecard/${vetecardId}/medical_files/veterinarian/${veterinarianId}`
      )) as { data: ListPetsitterVetecardVeterinarianMedicalFilesResponseT }

      const { data: invoices } = (await api.get(
        `/0/petsitter/${keycloakId}/vetecard/${vetecardId}/invoices/veterinarian/${veterinarianId}`
      )) as { data: ListPetsitterVetecardVeterinarianInvoicesResponseT }

      return {
        treatmentFiles: treatmentFiles.map(({ treatment: { id } }) => id),
        medicalFiles: medicalFiles.map(({ medicalFile: { id } }) => id),
        invoices: invoices.map(({ invoice: { id } }) => id),
      }
    }

    case 'veterinarian': {
      const { data: treatmentFiles } = (await api.get(
        `/0/veterinarian/${sharingVeterinarianId}/vetecard/${vetecardId}/treatments/veterinarian/${veterinarianId}`
      )) as { data: ListVeterinarianVetecardVeterinarianTreatmentsResponseTreatmentsT }

      const { data: medicalFiles } = (await api.get(
        `/0/veterinarian/${sharingVeterinarianId}/vetecard/${vetecardId}/medical_files/veterinarian/${veterinarianId}`
      )) as { data: ListVeterinarianVetecardVeterinarianMedicalFilesResponseMedicalFilesT }

      const { data: invoiceFiles } = (await api.get(
        `/0/veterinarian/${sharingVeterinarianId}/vetecard/${vetecardId}/invoices/veterinarian/${veterinarianId}`
      )) as { data: ListVeterinarianVetecardVeterinarianInvoicesResponseInvoicesT }

      return {
        treatmentFiles: treatmentFiles.map(({ treatment: { id } }) => id),
        medicalFiles: medicalFiles.map(({ medicalFile: { id } }) => id),
        invoices: invoiceFiles.map(({ invoice: { id } }) => id),
      }
    }

    default:
      throw new Error(`Invalid requesting userType ${userType}`)
  }
}

export const getSharedOtherFiles = async (
  user: UserBasic,
  vetecardId: VetecardBasic['id'],
  keycloakId: UserBasic['keycloakId']
): Promise<SharedFileIds> => {
  const owner = user

  switch (owner.userType) {
    case 'individual':
    case 'breeder':
    case 'association': {
      const { data: treatmentResponsibilities } = (await api.get(
        `/0/owner/${owner.userType}/${owner.keycloakId}/vetecard/${vetecardId}/treatments/petsitter/${keycloakId}`
      )) as { data: ListOwnerVetecardPetsitterTreatmentsResponseT }

      const { data: invoiceResponsibilities } = (await api.get(
        `/0/owner/${owner.userType}/${owner.keycloakId}/vetecard/${vetecardId}/invoices/petsitter/${keycloakId}`
      )) as { data: ListOwnerVetecardPetsitterInvoicesResponseT }

      const { data: medicalFileResponsibilities } = (await api.get(
        `/0/owner/${owner.userType}/${owner.keycloakId}/vetecard/${vetecardId}/medical_files/petsitter/${keycloakId}`
      )) as { data: ListOwnerVetecardPetsitterMedicalFilesResponseT }

      return {
        treatmentFiles: treatmentResponsibilities.map(({ treatment: { id } }) => id),
        medicalFiles: medicalFileResponsibilities.map(({ medicalFile: { id } }) => id),
        invoices: invoiceResponsibilities.map(({ invoice: { id } }) => id),
      }
    }

    case 'petsitter': {
      const { data: treatmentResponsibilities } = (await api.get(
        `/0/petsitter/${owner.keycloakId}/vetecard/${vetecardId}/treatments/petsitter/${keycloakId}`
      )) as { data: ListPetsitterVetecardPetsitterTreatmentsResponseT }

      const { data: medicalFileResponsibilities } = (await api.get(
        `/0/petsitter/${owner.keycloakId}/vetecard/${vetecardId}/medical_files/petsitter/${keycloakId}`
      )) as { data: ListPetsitterVetecardPetsitterMedicalFilesResponseT }

      const { data: invoiceResponsibilities } = (await api.get(
        `/0/petsitter/${owner.keycloakId}/vetecard/${vetecardId}/invoices/petsitter/${keycloakId}`
      )) as { data: ListPetsitterVetecardPetsitterInvoicesResponseT }

      return {
        treatmentFiles: treatmentResponsibilities.map(({ treatment: { id } }) => id),
        medicalFiles: medicalFileResponsibilities.map(({ medicalFile: { id } }) => id),
        invoices: invoiceResponsibilities.map(({ invoice: { id } }) => id),
      }
    }

    default:
      throw new Error(
        `Failed to "share files with other", because of invalid requesting user type ${JSON.stringify(
          user
        )}`
      )
  }
}
