import React from 'react'
import { Outlet, Route, BrowserRouter as Router, Routes as RoutesComponent } from 'react-router-dom'
import Layout from './components/Layout'
import ActivateVetecards from './pages/ActivateVetecards'
import Agenda from './pages/Agenda'
import Billing from './pages/Billing'
import Dashboard from './pages/Dashboard'
import DocumentInbox from './pages/DocumentInbox'
import EditPersonalInfo from './pages/EditPersonalInfo'
import Formalities from './pages/Formalities'
import ManageNews from './pages/ManageNews'
import MyVetecards from './pages/MyVetecards'
import MyVeterinaries from './pages/MyVeterinaries'
import PageNotFound from './pages/PageNotFound'
import Payment from './pages/Payment'
import PaymentCancel from './pages/PaymentCancel'
import PaymentSuccess from './pages/PaymentSuccess'
import PersonalInfo from './pages/PersonalInfo'
import RequestVetecard from './pages/RequestVetecard'
import Sandbox from './pages/Sandbox'
import Settings from './pages/Settings'
import Support from './pages/Support'
import UserProfile from './pages/UserProfile'
import VerifyRegistration from './pages/VerifyRegistration'
import Vetecard from './pages/Vetecard'
import VetecardRequest from './pages/VetecardRequest'
import VetecardRequests from './pages/VetecardRequests'
import Veterinarian from './pages/Veterinarian'
import PermissionRoute from './utils/PermissionRoute'
import RequireAuth from './utils/RequireAuth'

const Routes = () => (
  <Router>
    <RoutesComponent>
      {/* Protected routes with dashboard layout */}
      <Route
        element={
          <RequireAuth>
            <Layout>
              <Outlet />
            </Layout>
          </RequireAuth>
        }
      >
        <Route path="/" element={<Dashboard />} />
        <Route
          path="/vetecards/request"
          element={
            <PermissionRoute perm="request-vetecard">
              <RequestVetecard />
            </PermissionRoute>
          }
        />
        <Route
          path="/vetecards/:id"
          element={
            <PermissionRoute perm="vetecards">
              <Vetecard />
            </PermissionRoute>
          }
        />
        <Route
          path="/vetecards"
          element={
            <PermissionRoute perm="vetecards">
              <MyVetecards />
            </PermissionRoute>
          }
        />
        <Route
          path="/vetecard-requests/:id"
          element={
            <PermissionRoute perm="request-vetecard">
              <VetecardRequest />
            </PermissionRoute>
          }
        />
        <Route
          path="/vetecard-requests"
          element={
            <PermissionRoute perm="vetecards-advanced">
              <VetecardRequests />
            </PermissionRoute>
          }
        />
        <Route
          path="/payment"
          element={
            <PermissionRoute perm="pay-vetecards">
              <Payment />
            </PermissionRoute>
          }
        />
        <Route
          path="/payment/success"
          element={
            <PermissionRoute perm="pay-vetecards">
              <PaymentSuccess />
            </PermissionRoute>
          }
        />
        <Route
          path="/payment/cancel"
          element={
            <PermissionRoute perm="pay-vetecards">
              <PaymentCancel />
            </PermissionRoute>
          }
        />
        <Route
          path="/agenda"
          element={
            <PermissionRoute perm="agenda">
              <Agenda />
            </PermissionRoute>
          }
        />
        <Route
          path="/activate-vetecards"
          element={
            <PermissionRoute perm="activate-vetecards">
              <ActivateVetecards />
            </PermissionRoute>
          }
        />
        <Route
          path="/veterinaries"
          element={
            <PermissionRoute perm="my-veterinaries">
              <MyVeterinaries />
            </PermissionRoute>
          }
        />
        <Route
          path="/veterinaries/:id"
          element={
            <PermissionRoute perm="my-veterinaries">
              <Veterinarian />
            </PermissionRoute>
          }
        />
        <Route
          path="/personal-info"
          element={
            <PermissionRoute perm="personal-info">
              <PersonalInfo />
            </PermissionRoute>
          }
        />
        <Route
          path="/personal-info/edit"
          element={
            <PermissionRoute perm="personal-info">
              <EditPersonalInfo />
            </PermissionRoute>
          }
        />
        <Route
          path="/document-inbox"
          element={
            <PermissionRoute perm="document-inbox">
              <DocumentInbox />
            </PermissionRoute>
          }
        />
        <Route path="/settings" element={<Settings />} />
        <Route
          path="/billing"
          element={
            <PermissionRoute perm="billing">
              <Billing />
            </PermissionRoute>
          }
        />
        <Route
          path="/formalities"
          element={
            <PermissionRoute perm="formalities">
              <Formalities />
            </PermissionRoute>
          }
        />
        <Route
          path="/support"
          element={
            <PermissionRoute perm="messages">
              <Support />
            </PermissionRoute>
          }
        />
        <Route
          path="/sandbox"
          element={
            <PermissionRoute perm="dev-sandbox">
              <Sandbox />
            </PermissionRoute>
          }
        />
        <Route
          path="/user-profile/:keycloakId"
          element={
            <PermissionRoute perm="view-profiles">
              <UserProfile />
            </PermissionRoute>
          }
        />
        <Route
          path="/manage-news"
          element={
            <PermissionRoute perm="activate-vetecards">
              {/* Should be 'manage-news' */}
              <ManageNews />
            </PermissionRoute>
          }
        />
      </Route>
      {/* Unprotected routes */}
      <Route path="/verify-registration/:token" element={<VerifyRegistration />} />
      <Route path="/404" element={<PageNotFound />} />
      <Route path="*" element={<PageNotFound />} />
    </RoutesComponent>
  </Router>
)

export default Routes
