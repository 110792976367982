import React from 'react'
import { useMutation } from '@tanstack/react-query'
import Form, { FormActions, Input, Radio, Row } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { shareWithVet } from '../api/formatted/share'
import SubmitButton from '../components/SubmitButton'
import VeterinarianSearchInput from '../containers/VeterinarianSearchInput'
import {
  type ShareWithVetPayload,
  type TimeRangeType,
  type UserShared,
  type VetecardBasic,
} from '../typings'
import { useTranslatedOptions } from '../utils/helpers'
import queryClient from '../utils/queryClient'
import useAuth from '../utils/useAuth'

interface FormShareWithVeterinarianProps {
  vetecardId: VetecardBasic['id']
  onSuccess: () => void
}

const FormShareWithVeterinarian = ({ vetecardId, onSuccess }: FormShareWithVeterinarianProps) => {
  const { t } = useTranslation()
  const { currentUser } = useAuth()
  const [timeRangeType, setTimeRangeType] = React.useState<TimeRangeType | undefined>(undefined)
  const timeRangeOptions = useTranslatedOptions('timeRange')

  // const { data: referringVets = [] } = useQuery({
  //   queryKey: ['vetecard-veterinarian'],
  //   queryFn: () => getReferringVeterinarians(vetecardId),
  // })
  const { isLoading, mutate } = useMutation(
    (payload: ShareWithVetPayload) => shareWithVet(currentUser, payload),
    {
      onSuccess: (user: UserShared) => {
        queryClient.setQueryData(
          ['vetecard', vetecardId, 'shared-vets'],
          (oldData: UserShared[] = []) => [user, ...oldData]
        )
        toast.success(t('success'))
        onSuccess()
      },
    }
  )

  const handleSubmit = (payload: ShareWithVetPayload) => {
    mutate({ ...payload, vetecardId })
  }

  // TODO: Should veterinary sharing also has time range, similar to 3rd party?
  return (
    <Form
      fields={['veterinarianId', 'timeRangeType', 'dateFrom', 'dateTo']}
      mandatory={[
        'veterinarianId',
        'timeRangeType',
        ...(timeRangeType === 'date_range' || timeRangeType === 'oneyear' ? ['dateFrom'] : []),
        ...(timeRangeType === 'date_range' ? ['dateTo'] : []),
      ]}
    >
      <VeterinarianSearchInput
        name="veterinarianId"
        placeholder={t('enterVeterinarian')}
        // featuredOptions={referringVets}
      />
      <Radio
        name="timeRangeType"
        label={t('timeRangeLabel')}
        options={timeRangeOptions}
        onChange={(type: TimeRangeType) => setTimeRangeType(type)}
      />
      {(timeRangeType === 'date_range' || timeRangeType === 'oneyear') && (
        <Row>
          <Input name="dateFrom" label={t('validFrom')} type="date" />
          {timeRangeType === 'date_range' && (
            <Input name="dateTo" label={t('validUntil')} type="date" />
          )}
        </Row>
      )}
      <FormActions>
        <SubmitButton onClick={handleSubmit} loading={isLoading}>
          {t('share')}
        </SubmitButton>
      </FormActions>
    </Form>
  )
}

export default FormShareWithVeterinarian
