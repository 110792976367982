import React from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createRoot } from 'react-dom/client'
import App from './App'
import './i18n'
import registerServiceWorker from './registerServiceWorker'
import './style/index.sass'
import FormThemeProvider from './utils/FormThemeProvider'
import queryClient from './utils/queryClient'
import { AuthProvider } from './utils/useAuth'

createRoot(document.getElementById('app')!).render(
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <FormThemeProvider>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </FormThemeProvider>
    </AuthProvider>
  </QueryClientProvider>
)

registerServiceWorker()
