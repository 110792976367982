import React from 'react'
import { useMutation } from '@tanstack/react-query'
import Form, { FormActions, Input, MultiSelect, Row, Select } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { saveUserProfile } from '../api/formatted/user'
import Alert from '../components/Alert'
import Block from '../components/Block'
import Button from '../components/Button'
import PhoneInput from '../components/PhoneInput'
import SubmitButton from '../components/SubmitButton'
import { type EditPersonalInfoPayload, type UserFull } from '../typings'
import { useTranslatedOptions } from '../utils/helpers'
import queryClient from '../utils/queryClient'
import useAuth from '../utils/useAuth'

const FormEditPersonalInfo = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    currentUser: {
      keycloakId,
      userType,
      firstName,
      lastName,
      politeness,
      contact,
      email,
      profession,
      institutions,
      rpps,
    },
  } = useAuth()

  const { isLoading, mutate } = useMutation(
    (payload: EditPersonalInfoPayload) => saveUserProfile(userType, keycloakId, rpps, payload),
    {
      onSuccess: (data: UserFull) => {
        const completeData = { ...data, email } // savePersonalInfo does not process email.
        queryClient.setQueryData(['current-user'], completeData)
        navigate('/personal-info')
        toast.success('Personal informations updated')
      },
    }
  )

  const institution = institutions?.[0]

  const countryOptions = useTranslatedOptions('countries', 'countries')
  const politenessOptions = useTranslatedOptions('politeness')
  const categoryOptions = useTranslatedOptions('categories', 'vetProfessions')
  const fieldOptions = useTranslatedOptions('fields', 'vetProfessions')
  const specializationOptions = useTranslatedOptions('specializations', 'vetProfessions')
  const speciesOptions = useTranslatedOptions('species')

  const handleSubmit = (fields: EditPersonalInfoPayload) => {
    mutate({ ...fields, institutionId: institution?.id })
  }

  const formActions = () => (
    <FormActions>
      <Button component={Link} variant="link" to="/personal-info">
        {t('cancel')}
      </Button>
      <SubmitButton onClick={handleSubmit} loading={isLoading}>
        {t('save')}
      </SubmitButton>
    </FormActions>
  )

  const typeSpecificForm = () => {
    switch (userType) {
      case 'individual':
        return (
          <Form
            fields={[
              'firstName',
              'lastName',
              'phoneNumber1',
              'phoneNumber2',
              'address',
              'city',
              'postalCode',
              'country',
            ]}
            mandatory={[
              'firstName',
              'lastName',
              'phoneNumber1',
              'address',
              'city',
              'postalCode',
              'country',
            ]}
          >
            <Block condensed>
              <Row>
                <Input name="firstName" label={t('firstName')} initialValue={firstName} />
                <Input name="lastName" label={t('lastName')} initialValue={lastName} />
              </Row>
            </Block>
            <Block condensed title={t('contactDetails')}>
              <Row>
                <PhoneInput name="phoneNumber1" label={t('phone')} initialValue={contact?.phone} />
                <PhoneInput
                  name="phoneNumber2"
                  label={t('phoneSecondary')}
                  initialValue={contact?.fax}
                />
              </Row>
              <Input name="address" label={t('streetAddress')} initialValue={contact?.address} />
              <Row>
                <Input name="city" label={t('city')} initialValue={contact?.city} />
                <Input name="postalCode" label={t('postCode')} initialValue={contact?.postalCode} />
              </Row>
              <Select
                name="country"
                label={t('country')}
                options={countryOptions}
                initialValue={contact?.country}
              />
            </Block>
            {formActions()}
          </Form>
        )

      case 'veterinarian':
        return (
          <Form
            fields={[
              'firstName',
              'lastName',
              'politeness',
              'field',
              'category',
              'specialization',
              'institutionName',
              'siren',
              'siret',
              'finess',
              'dpe',
              'phoneNumber1',
              'phoneNumber2',
              'address',
              'city',
              'postalCode',
              'country',
            ]}
            mandatory={['politeness', 'field', 'category', 'specialization', 'dpe']}
          >
            <Block condensed>
              <Row>
                <Input
                  name="firstName"
                  label={t('firstName')}
                  initialValue={firstName}
                  help={t('vetDatabase.name')}
                  disabled
                />
                <Input name="lastName" label={t('lastName')} initialValue={lastName} disabled />
              </Row>
            </Block>
            <Block condensed title={t('veterinarianProfile')}>
              <Select
                name="politeness"
                label={t('politenessLabel')}
                options={politenessOptions}
                initialValue={profession?.politeness}
              />
              <Select
                name="category"
                label={t('category')}
                options={categoryOptions}
                initialValue={profession?.category}
              />
              <Select
                name="field"
                label={t('field')}
                options={fieldOptions}
                initialValue={profession?.field}
              />
              <MultiSelect
                name="specialization"
                label={t('specialization')}
                options={specializationOptions}
                initialValue={profession?.specialization}
              />
            </Block>
            <Block condensed title={t('workingPlace')}>
              <Input
                name="institutionName"
                label={t('institution.name')}
                help={t('vetDatabase.institution')}
                initialValue={institution?.name}
                disabled
              />
              <Input
                name="siren"
                label={t('institution.siren')}
                initialValue={institution?.siren}
              />
              <Input
                name="siret"
                label={t('institution.siret')}
                initialValue={institution?.siret}
              />
              <Input
                name="finess"
                label={t('institution.finess')}
                initialValue={institution?.finess}
              />
              <Input name="dpe" label={t('dpe')} initialValue={institution?.code} disabled />
            </Block>
            <Block condensed title={t('institution.contactDetails')}>
              <Row>
                <PhoneInput
                  name="phoneNumber1"
                  label={t('phone')}
                  initialValue={contact?.phone}
                  disabled
                />
                <PhoneInput
                  name="phoneNumber2"
                  label={t('phoneSecondary')}
                  initialValue={institution?.contact?.fax}
                  disabled
                />
              </Row>
              <Input
                name="address"
                label={t('streetAddress')}
                initialValue={institution?.contact?.address}
                disabled
              />
              <Row>
                <Input
                  name="city"
                  label={t('city')}
                  initialValue={institution?.contact?.city}
                  disabled
                />
                <Input
                  name="postalCode"
                  label={t('postCode')}
                  initialValue={institution?.contact?.postalCode}
                  disabled
                />
              </Row>
              <Select
                name="country"
                label={t('country')}
                options={countryOptions}
                initialValue={institution?.contact?.country}
                disabled
              />
            </Block>
            {formActions()}
          </Form>
        )

      case 'breeder':
        return (
          <Form
            fields={[
              'firstName',
              'lastName',
              'politeness',
              'institutionName',
              'species',
              'siren',
              'siret',
              'finess',
              'finessGeo',
              'phoneNumber1',
              'phoneNumber2',
              'address',
              'city',
              'postalCode',
              'country',
            ]}
            mandatory={['firstName', 'lastName', 'politeness', 'institutionName']}
          >
            <Block condensed>
              <Row>
                <Input name="firstName" label={t('firstName')} initialValue={firstName} />
                <Input name="lastName" label={t('lastName')} initialValue={lastName} />
              </Row>
              <Select
                name="politeness"
                label={t('politenessLabel')}
                options={politenessOptions}
                initialValue={politeness}
              />
            </Block>
            <Block condensed title={t('institution.data')}>
              <Input
                name="institutionName"
                label={t('institution.name')}
                initialValue={institution?.name}
              />
              <MultiSelect
                name="species"
                label={t('institution.species')}
                options={speciesOptions}
                initialValue={institution?.species}
              />
              <Input
                name="siren"
                label={t('institution.siren')}
                initialValue={institution?.siren}
              />
              <Input
                name="siret"
                label={t('institution.siret')}
                initialValue={institution?.siret}
              />
              <Input
                name="finess"
                label={t('institution.finess')}
                initialValue={institution?.finess}
              />
              <Input
                name="finessGeo"
                label={t('institution.finessGeo')}
                initialValue={institution?.finessGeo}
              />
            </Block>
            <Block condensed title={t('institution.contactDetails')}>
              <Row>
                <PhoneInput name="phoneNumber1" label={t('phone')} initialValue={contact?.phone} />
                <PhoneInput
                  name="phoneNumber2"
                  label={t('phoneSecondary')}
                  initialValue={contact?.fax}
                />
              </Row>
              <Input name="address" label={t('streetAddress')} initialValue={contact?.address} />
              <Row>
                <Input name="city" label={t('city')} initialValue={contact?.city} />
                <Input name="postalCode" label={t('postCode')} initialValue={contact?.postalCode} />
              </Row>
              <Select
                name="country"
                label={t('country')}
                options={countryOptions}
                initialValue={contact?.country}
              />
            </Block>
            {formActions()}
          </Form>
        )

      case 'association':
        return (
          <Form
            fields={[
              'firstName',
              'lastName',
              'politeness',
              'institutionName',
              'species',
              'siren',
              'siret',
              'finess',
              'finessGeo',
              'rna',
              'phoneNumber1',
              'phoneNumber2',
              'address',
              'city',
              'postalCode',
              'country',
            ]}
            mandatory={['firstName', 'lastName', 'politeness', 'institutionName']}
          >
            <Block condensed>
              <Row>
                <Input name="firstName" label={t('firstName')} initialValue={firstName} />
                <Input name="lastName" label={t('lastName')} initialValue={lastName} />
              </Row>
              <Select
                name="politeness"
                label={t('politenessLabel')}
                options={politenessOptions}
                initialValue={politeness}
              />
            </Block>
            <Block condensed title={t('institution.data')}>
              <Input
                name="institutionName"
                label={t('institution.name')}
                initialValue={institution?.name}
              />
              <MultiSelect
                name="species"
                label={t('institution.species')}
                options={speciesOptions}
                initialValue={institution?.species}
              />
              <Input
                name="siren"
                label={t('institution.siren')}
                initialValue={institution?.siren}
              />
              <Input
                name="siret"
                label={t('institution.siret')}
                initialValue={institution?.siret}
              />
              <Input
                name="finess"
                label={t('institution.finess')}
                initialValue={institution?.finess}
              />
              <Input
                name="finessGeo"
                label={t('institution.finessGeo')}
                initialValue={institution?.finessGeo}
              />
              <Input name="rna" label={t('institution.rna')} initialValue={institution?.rna} />
            </Block>
            <Block condensed title={t('institution.contactDetails')}>
              <Row>
                <PhoneInput name="phoneNumber1" label={t('phone')} initialValue={contact?.phone} />
                <PhoneInput
                  name="phoneNumber2"
                  label={t('phoneSecondary')}
                  initialValue={contact?.fax}
                />
              </Row>
              <Input name="address" label={t('streetAddress')} initialValue={contact?.address} />
              <Row>
                <Input name="city" label={t('city')} initialValue={contact?.city} />
                <Input name="postalCode" label={t('postCode')} initialValue={contact?.postalCode} />
              </Row>
              <Select
                name="country"
                label={t('country')}
                options={countryOptions}
                initialValue={contact?.country}
              />
            </Block>
            {formActions()}
          </Form>
        )

      default:
        return <Alert type="error" primary="Unsupported user type" />
    }
  }

  return typeSpecificForm()
}

export default FormEditPersonalInfo
