import React from 'react'
import { useMutation } from '@tanstack/react-query'
import Form, { Input, Select } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { editMarking } from '../api/formatted/vetecard'
import SubmitButton from '../components/SubmitButton'
import { type EditMarkingPayload, type VetecardFull } from '../typings'
import formatDate from '../utils/formatDate'
import { useTranslatedOptions } from '../utils/helpers'
import queryClient from '../utils/queryClient'

interface FormEditMarkingProps {
  ident: VetecardFull
  onSuccess?: () => void
}

const FormEditMarking = ({ ident, onSuccess }: FormEditMarkingProps) => {
  const { t } = useTranslation()
  const [identifierType, setIdentifierType] = React.useState<VetecardFull['identifier']['type']>(
    ident.identifier.type
  )

  const { isLoading, mutate } = useMutation(
    (payload: EditMarkingPayload) => editMarking(ident.id, payload),
    {
      onSuccess: (response: VetecardFull['identifier']) => {
        queryClient.setQueryData(['vetecard', ident.id], (oldData: VetecardFull | undefined) =>
          oldData
            ? {
                ...oldData,
                identifier: response,
              }
            : undefined
        )
        toast.success(t('markingUpdated'))
        onSuccess && onSuccess()
      },
    }
  )

  const handleSubmit = (values: EditMarkingPayload) => {
    mutate(values)
  }

  const identifierTypeOptions = useTranslatedOptions('identifierType').reverse()
  const identifierLocationOptions = useTranslatedOptions('identifierLocation')

  return (
    <Form
      fields={['type', ...(identifierType === 'none' ? [] : ['number', 'date', 'location'])]}
      allMandatory
    >
      <Select
        name="type"
        label={t('identifierTypeLabel')}
        options={identifierTypeOptions}
        initialValue={ident.identifier.type}
        onChange={(type: VetecardFull['identifier']['type']) => {
          setIdentifierType(type)
        }}
      />
      {identifierType !== 'none' && (
        <>
          <Input
            name="number"
            label={`${t('identifier')} ${t('number')}`}
            initialValue={ident.identifier.number}
          />
          <Input
            name="date"
            type="date"
            label={t('implementationDate')}
            initialValue={ident.identifier.date && formatDate(ident.identifier.date, 'input')}
          />
          <Select
            name="location"
            label={t('identifierLocationLabel')}
            options={identifierLocationOptions}
            initialValue={ident.identifier.location}
          />
        </>
      )}
      <SubmitButton onClick={handleSubmit} loading={isLoading}>
        {t('save')}
      </SubmitButton>
    </Form>
  )
}

export default FormEditMarking
