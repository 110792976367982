import React from 'react'
import { ClipLoader, PulseLoader } from 'react-spinners'
import './style.sass'

interface SpinnerProps {
  variant?: 'block' | 'fullScreen' | 'small'
}

const Spinner = ({ variant = 'small' }: SpinnerProps) => {
  switch (variant) {
    case 'block':
      return (
        <div className="spinner spinner--block">
          <PulseLoader color="#038385" size={24} />
        </div>
      )
    case 'fullScreen':
      return (
        <div className="spinner spinner--fullscreen">
          <PulseLoader color="#038385" size={32} />
        </div>
      )
    case 'small':
      return (
        <div className="spinner spinner--small">
          <ClipLoader size={16} color="inherit" />
        </div>
      )
  }
}

export default Spinner
