import React from 'react'
import {
  type option,
  parseOption,
  type setValue,
  type value,
  withFormControl,
} from 'react-form-component'
import { debounce } from 'throttle-debounce'
import './style.sass'

type ParsedOption = { optionLabel: string; optionValue: string }

interface AutocompleteInputProps {
  name: string
  value: value
  placeholder?: string
  mandatory?: boolean
  setValue: setValue
  options?: option[]
  onChange?: (value: value) => void
  onSelect?: (value: value) => void
  initialPhrase?: string
}

const AutocompleteInput = ({
  name,
  value,
  placeholder,
  mandatory,
  setValue,
  options = [],
  onChange,
  onSelect,
  initialPhrase,
}: AutocompleteInputProps) => {
  const [displayedValue, setDisplayedValue] = React.useState('')
  const [showOptions, setShowOptions] = React.useState(false)

  const parsedOptions = React.useMemo(
    // parseOption function allows support options both as object and a string.
    () => (options?.length ? options.map(item => parseOption(item)) : []),
    [options]
  ) as ParsedOption[]

  // Bind options prop to internal state.
  React.useEffect(() => {
    if (parsedOptions.length && displayedValue) {
      setShowOptions(true)
    } else {
      setShowOptions(false)
    }
  }, [parsedOptions])

  const debouncedOnChange = React.useCallback(
    debounce(500, (nextValue: value) => onChange && onChange(nextValue)),
    []
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = e.target.value
    setDisplayedValue(userInput)
    if (!userInput.length) {
      setValue(name, undefined, mandatory)
      setShowOptions(false)
    }

    if (onChange) {
      // If onChange prop is set, base on options props that should be iupdated by
      // onChange function from parent component.
      debouncedOnChange(userInput)
    }
  }

  // Handle initial search phrase.
  React.useEffect(() => {
    if (initialPhrase) {
      setDisplayedValue(initialPhrase)
      debouncedOnChange(initialPhrase)
    }
  }, [initialPhrase])

  const handleSelectOption = (option: string) => {
    setDisplayedValue(() => {
      // As an input content, display option label. Not its value.
      const match = parsedOptions.find(({ optionValue }) => option === optionValue)
      if (match) return match.optionLabel
      return option
    })
    setValue(name, option, mandatory)
    onSelect && onSelect(option)
    setShowOptions(false)
  }

  const handleBlur = () => {
    // Delay hiding the options to give a chance to select an option
    setTimeout(() => setShowOptions(false), 200)
  }

  return (
    <>
      <input
        type="text"
        name={name}
        value={displayedValue}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={() => setShowOptions(true)}
        className="rfc-input autocomplete-input"
        autoComplete="off"
      />
      {showOptions && (
        <ul className="autocomplete-input__suggestions">
          {parsedOptions.map(({ optionValue, optionLabel }) => (
            <li
              key={optionValue}
              className="autocomplete-input__suggestion"
              onClick={() => handleSelectOption(optionValue)}
              data-cy="suggestion"
            >
              {optionLabel}
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

export default withFormControl(AutocompleteInput)
