import React from 'react'
import { faFileInvoice, faRotateLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { GlassMagnifier } from 'react-image-magnifiers'
import { Link } from 'react-router-dom'
import Button from '../Button'
import './style.sass'

interface FilePreviewProps {
  url: string
  name?: string
  mimeType?: string
}

const FilePreview = ({ url, name = 'File preview', mimeType }: FilePreviewProps) => {
  const { t } = useTranslation()
  const [rotation, setRotation] = React.useState<1 | 2 | 3 | undefined>()

  const handleRotate = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    switch (rotation) {
      case 1:
        setRotation(2)
        break
      case 2:
        setRotation(3)
        break
      case 3:
        setRotation(undefined)
        break

      default:
        setRotation(1)
        break
    }
  }

  const returnPreview = () => {
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    switch (mimeType) {
      case 'image/avif':
      case 'image/gif':
      case 'image/jpeg':
      case 'image/png':
      case 'image/svg+xml':
      case 'image/tiff':
      case 'image/webp':
        return (
          <>
            <GlassMagnifier
              imageSrc={url}
              imageAlt={name}
              className={classNames('file-preview__image', {
                [`file-preview__image--rotate${rotation}`]: rotation,
              })}
            />
            <button onClick={handleRotate} className="file-preview__rotate">
              <FontAwesomeIcon icon={faRotateLeft} title="Rotate" />
            </button>
          </>
        )
      case 'application/json':
      case 'application/pdf':
      case 'application/x-httpd-php':
      case 'application/x-sh':
      case 'application/xhtml+xml':
      case 'text/css':
      case 'text/csv':
      case 'text/html':
      case 'text/javascript':
      case 'text/plain':
        return <iframe src={url} className="file-preview__iframe" />

      default:
        // Unhandled file types.
        return (
          <div className="file-preview__unhandled">
            <FontAwesomeIcon icon={faFileInvoice} className="file-preview__icon" />
            <div>{t('previewNotAvailable')}</div>
          </div>
        )
    }
  }

  return (
    <div className="file-preview">
      {returnPreview()}
      <div className="file-preview__footer">
        <Button component={Link} to={url}>
          {t('downloadFile')}
        </Button>
      </div>
    </div>
  )
}

export default FilePreview
