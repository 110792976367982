import React from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { getSharedVetFiles, shareFilesWithVet } from '../api/formatted/share'
import FormFileSharing, { FormValues } from '../forms/FormFileSharing'
import { type SharedFileIds, type VetecardBasic, VeterinarianBasic } from '../typings'
import useAuth from '../utils/useAuth'

interface FileSharingVetContainerProps {
  vetecardId: VetecardBasic['id']
  veterinarianId: VeterinarianBasic['veterinarianId']
  onSuccess: () => void
}

const FileSharingVetContainer = ({
  vetecardId,
  veterinarianId,
  onSuccess,
}: FileSharingVetContainerProps) => {
  const { t } = useTranslation()
  const { currentUser } = useAuth()

  const { data, isFetching, isError } = useQuery({
    queryKey: ['vetecard', vetecardId, 'shared-files', 'vet', veterinarianId],
    queryFn: () => getSharedVetFiles(currentUser, vetecardId, veterinarianId),
  })

  const { isLoading, mutate } = useMutation(
    (payload: SharedFileIds) => shareFilesWithVet(currentUser, payload, veterinarianId, vetecardId),
    {
      onSuccess: () => {
        toast.success(t('success'))
        onSuccess()
      },
    }
  )

  const handleSubmit = ({ invoices = [], medicalFiles = [], treatmentFiles = [] }: FormValues) => {
    mutate({
      invoices: invoices.map(id => parseInt(id)),
      medicalFiles: medicalFiles.map(id => parseInt(id)),
      treatmentFiles: treatmentFiles.map(id => parseInt(id)),
    })
  }

  return (
    <FormFileSharing
      vetecardId={vetecardId}
      sharedFileIds={data}
      sharedFileIdsIsFetching={isFetching}
      sharedFileIdsIsError={isError}
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  )
}

export default FileSharingVetContainer
