import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { getFullVeterinarian } from '../api/formatted/veterinarian'
import Content from '../components/Content'
import Institution from '../components/Institution'
import Loader from '../components/Loader'
import Title from '../components/Title'
import VeterinarianHead from '../components/VeterinarianHead'

const Veterinarian = () => {
  const { id = '' } = useParams()
  const { t } = useTranslation()

  const { data, isFetching, isError } = useQuery({
    queryKey: ['veterinarian', id],
    queryFn: () => getFullVeterinarian(Number(id)),
  })

  return (
    <Loader loading={isFetching} error={isError}>
      <Content>
        <Title hideDesktop>{t('veterinarian')}</Title>
        {data && (
          <>
            <VeterinarianHead
              firstName={data.firstName}
              lastName={data.lastName}
              profession={data.profession}
            />
            {data.institutions?.map(item => (
              <Institution
                key={item.id}
                id={item.id}
                name={item.name}
                contact={item.contact}
                internalIdentifier={item.internalIdentifier}
              />
            ))}
          </>
        )}
      </Content>
    </Loader>
  )
}

export default Veterinarian
