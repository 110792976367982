import * as t from 'io-ts'
import { nullable } from 'io-ts/Type'
import * as tt from 'io-ts-types'


export const IdentifierTypeTransponder = t.literal('transponder')
export type IdentifierTypeTransponderT = t.TypeOf<typeof IdentifierTypeTransponder>

export const IdentifierTypeTattoo = t.literal('tattoo')
export type IdentifierTypeTattooT = t.TypeOf<typeof IdentifierTypeTattoo>

export const IdentifierTypeNone = t.literal('none')
export type IdentifierTypeNoneT = t.TypeOf<typeof IdentifierTypeNone>

export const IdentifierType = t.union([IdentifierTypeTattoo, IdentifierTypeTransponder, IdentifierTypeNone])
export type IdentifierTypeT = t.TypeOf<typeof IdentifierTypeNone>


export const IdentifierLocationLeftJugularGutter = t.literal('leftJugularGutter')
export type IdentifierLocationLeftJugularGutterT = t.TypeOf<typeof IdentifierLocationLeftJugularGutter>

export const IdentifierLocationLeftNeckline = t.literal('leftNeckline')
export type IdentifierLocationLeftNecklineT = t.TypeOf<typeof IdentifierLocationLeftNeckline>

export const IdentifierLocationLeftEar = t.literal('leftEar')
export type IdentifierLocationLeftEarT = t.TypeOf<typeof IdentifierLocationLeftEar>

export const IdentifierLocationOther = t.literal('other')
export type IdentifierLocationOtherT = t.TypeOf<typeof IdentifierLocationOther>

export const IdentifierLocation = t.union([
  IdentifierLocationLeftJugularGutter,
  IdentifierLocationLeftNeckline,
  IdentifierLocationLeftEar,
  IdentifierLocationOther,
])
export type IdentifierLocationT = t.TypeOf<typeof IdentifierLocation>


export const IdentifierTattoo = t.type({
  type: IdentifierTypeTattoo,
  number: nullable(t.string),
  date: nullable(tt.DateFromISOString),
  location: IdentifierLocation,
})
export type IdentifierTattooT = t.TypeOf<typeof IdentifierTattoo>

export const IdentifierTransponder = t.type({
  type: IdentifierTypeTransponder,
  number: nullable(t.string),
  date: nullable(tt.DateFromISOString),
  location: IdentifierLocation,
})
export type IdentifierTransponderT = t.TypeOf<typeof IdentifierTransponder>

export const IdentifierNone = t.type({
  type: IdentifierTypeNone,
})
export type IdentifierNoneT = t.TypeOf<typeof IdentifierNone>

export const Identifier = t.union([IdentifierTattoo, IdentifierTransponder, IdentifierNone])
export type IdentifierT = t.TypeOf<typeof Identifier>

export const Species = t.union([
  t.literal('dog'),
  t.literal('cat'),
  t.literal('horse'),
  t.literal('other'),
  t.literal('ferret'),
  t.literal('rabbit'),
  t.literal('nac'),
])
export type SpeciesT = t.TypeOf<typeof Species>

export const VetecardRequestId = t.number
export type VetecardRequestIdT = t.TypeOf<typeof VetecardRequestId>

export const VetecardRequestKey = t.type({
  id: VetecardRequestId,
})
export type VetecardRequestKeyT = t.TypeOf<typeof VetecardRequestKey>

export const V0VetecardRequest = t.intersection([
  VetecardRequestKey,
  t.type({
    name: t.string,
    identifier: Identifier,
    species: Species,
    birthDate: tt.DateFromISOString,
    created: tt.DateFromISOString,
  }),
  t.type({
    confirmed: t.boolean,
  }),
])
export type V0VetecardRequestT = t.TypeOf<typeof V0VetecardRequest>

export const VetecardRequest = V0VetecardRequest
export type VetecardRequestT = t.TypeOf<typeof V0VetecardRequest>
