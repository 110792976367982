import React from 'react'
import Form, { Checkbox, FormActions, Input, type fieldsData } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '../components/Button'
import SubmitButton from '../components/SubmitButton'
import { type FoundVeterinarian } from '../typings'

interface FormVerifyVeterinarianProps {
  data: FoundVeterinarian
}

const FormVerifyVeterinarian = ({ data }: FormVerifyVeterinarianProps) => {
  const { t } = useTranslation(['common', 'vetProfessions'])
  const navigate = useNavigate()

  const handleConfirm = (values: fieldsData) => {
    navigate('/')
  }

  return (
    <Form
      fields={[
        'firstName',
        'lastName',
        'professionCategory',
        'name',
        'address',
        'country',
        'phoneNumber1',
        'phoneNumber2',
        'confirmation',
      ]}
      mandatory={['confirmation']}
    >
      <Input
        name="firstName"
        label={`${t('firstName')}:`}
        initialValue={data.firstName}
        inlineLabel
        disabled
      />
      <Input
        name="lastName"
        label={`${t('lastName')}:`}
        initialValue={data.lastName}
        inlineLabel
        disabled
      />
      <Input
        name="professionCategory"
        label={`${t('professionCategory')}:`}
        initialValue={t(`categories.${data.professionalCode}`, { ns: 'vetProfessions' })}
        inlineLabel
        disabled
      />
      <Input
        name="name"
        label={`${t('name')}:`}
        initialValue={data.institution?.name}
        inlineLabel
        disabled
      />
      <Input
        name="address"
        label={`${t('streetAddress')}:`}
        initialValue={data.institution?.address}
        inlineLabel
        disabled
      />
      <Input
        name="city"
        label={`${t('city')}:`}
        initialValue={data.institution?.city}
        inlineLabel
        disabled
      />
      <Input
        name="postcode"
        label={`${t('postCode')}:`}
        initialValue={data.institution?.postalCode}
        inlineLabel
        disabled
      />
      <Input
        name="country"
        label={`${t('country')}:`}
        initialValue={data.institution?.country}
        inlineLabel
        disabled
      />
      <Input
        name="phoneNumber1"
        type="tel"
        label={`${t('phone')}:`}
        initialValue={data.institution?.phoneNumber2}
        inlineLabel
        disabled
      />
      <Input
        name="phoneNumber2"
        type="tel"
        label={`${t('phone')}:`}
        initialValue={data.institution?.phoneNumber2}
        inlineLabel
        disabled
      />
      <Checkbox name="confirmation" text={t('confirmIamThisVet')} />
      <FormActions align="space-between">
        <Button variant="link">{t('thisIsNotMyData')}</Button>
        <SubmitButton onClick={handleConfirm}>{t('continue')}</SubmitButton>
      </FormActions>
    </Form>
  )
}

export default FormVerifyVeterinarian
