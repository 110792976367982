import React from 'react'
import { useTranslation } from 'react-i18next'
import Block from '../components/Block'
import Button from '../components/Button'
import Content from '../components/Content'
import Text from '../components/Text'
import Title from '../components/Title'

const Support = () => {
  const { t } = useTranslation()
  return (
    <Content>
      <Title>{t('support.support')}</Title>
      <Block title={t('support.faq')}>
        <Text>{t('support.faqDescr')}</Text>
        <Button component="a" href={`${process.env.DASHBOARD_WEBSITE_BASEURL}faq/`} target="_blank">
          {t('support.faqVisit')}
        </Button>
      </Block>
      <Block title={t('support.contactUs')}>
        <Text>
          {t('support.contactUsViaEmail')}{' '}
          <a href="mailto:contact@vetecard.com">contact@vetecard.com</a>.
        </Text>
        <Button component="a" href="mailto:contact@vetecard.com">
          {t('support.sendUsEmail')}
        </Button>
      </Block>
    </Content>
  )
}

export default Support
