import React from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { revokeVetAccess } from '../api/formatted/share'
import { getSharedVeterinarians } from '../api/formatted/vetecard'
import Box from '../components/Box'
import Loader from '../components/Loader'
import Modal, { type ModalRef } from '../components/Modal'
import Text from '../components/Text'
import UserList from '../components/UserList'
import FormShareWithVeterinarian from '../forms/FormShareWithVeterinarian'
import { type UserShared, type VetecardBasic } from '../typings'
import formatName from '../utils/formatName'
import queryClient from '../utils/queryClient'
import useAuth from '../utils/useAuth'
import FileSharingVetContainer from './FileSharingVetContainer'

interface SharedWithVetContainerProps {
  vetecardId: VetecardBasic['id']
}

const SharedWithVetContainer = ({ vetecardId }: SharedWithVetContainerProps) => {
  const { t } = useTranslation()
  const { currentUser } = useAuth()
  const [managedUser, setManagedUser] = React.useState<UserShared | undefined>(undefined)

  const addModalRef = React.useRef<ModalRef>(null)

  const unsetManagedUser = () => setManagedUser(undefined)

  const { data, isFetching, isError } = useQuery({
    queryKey: ['vetecard', vetecardId, 'shared-vets'],
    queryFn: () => getSharedVeterinarians(currentUser, vetecardId),
  })

  const { mutate: mutateRevoke } = useMutation(
    (veterinarianId: UserShared['veterinarianId']) => revokeVetAccess(vetecardId, veterinarianId),
    {
      onSuccess: (veterinarianId: UserShared['veterinarianId']) => {
        queryClient.setQueryData(
          ['vetecard', vetecardId, 'shared-vets'],
          (oldData?: UserShared[]) =>
            oldData && oldData.filter(item => item.veterinarianId !== veterinarianId)
        )
        unsetManagedUser()
        toast.success(t('success'))
      },
    }
  )

  const handleRevoke = () => mutateRevoke(managedUser?.veterinarianId)

  return (
    <Loader loading={isFetching} error={isError}>
      <UserList
        users={data}
        emptyMessage={t('notSharedWithVets')}
        onManage={veterinarianId =>
          setManagedUser(data?.find(item => item.veterinarianId === veterinarianId))
        }
        onAdd={() => addModalRef.current?.open()}
      />

      <Modal size="lg" title={t('shareWithVet.title')} ref={addModalRef}>
        <Text>{t('shareWithVet.description')}</Text>
        <Text>{t('shareWithVet.note')}</Text>
        <FormShareWithVeterinarian
          vetecardId={vetecardId}
          onSuccess={() => addModalRef.current?.close()}
        />
      </Modal>

      {managedUser && (
        <Modal
          size="lg"
          title={formatName(managedUser?.firstName, managedUser?.lastName)}
          onClose={unsetManagedUser}
          isOpen
        >
          <Box
            title={t('accessManagement.basicInformation')}
            description={t('accessManagement.basicInformationDescr')}
          />
          <Box
            title={t('accessManagement.shareableFiles')}
            description={t('accessManagement.shareableFilesDescr')}
            paddedFooter
            footer={
              <FileSharingVetContainer
                vetecardId={vetecardId}
                veterinarianId={managedUser.veterinarianId || 0}
                onSuccess={() => setManagedUser(undefined)}
              />
            }
          />
          <Box
            title={t('accessManagement.revokeAccess')}
            description={t('accessManagement.revokeAccessDescr')}
            btnLabel={t('revoke')}
            onClick={handleRevoke}
          />
        </Modal>
      )}
    </Loader>
  )
}

export default SharedWithVetContainer
