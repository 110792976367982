import React from 'react'
import { useQuery } from '@tanstack/react-query'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { getEvents } from '../api/formatted/agenda'
import Alert from '../components/Alert'
import Calendar from '../components/Calendar'
import Content from '../components/Content'
import FiltersSection from '../components/FiltersSection'
import Loader from '../components/Loader'
import Title from '../components/Title'
import FormAgendaFilters from '../forms/FormAgendaFilters'
import { type AgendaPayload } from '../typings'
import useAuth from '../utils/useAuth'

const Agenda = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const navigate = useNavigate()
  const {
    currentUser: { keycloakId, userType },
  } = useAuth()

  const params = React.useMemo(() => {
    const { species, vetecardId } = queryString.parse(search, { arrayFormat: 'bracket' })
    return { species, vetecardId: vetecardId ? Number(vetecardId) : undefined } as AgendaPayload
  }, [search])

  const {
    data = [],
    refetch,
    isFetching,
    isError,
  } = useQuery(['events'], () => getEvents(userType, keycloakId, params))

  const handleParams = (values: AgendaPayload) => {
    const newFiltersQueryString = queryString.stringify(values, { arrayFormat: 'bracket' })
    navigate(`/agenda?${newFiltersQueryString}`)
  }

  React.useEffect(() => {
    refetch()
  }, [params])

  const vaccinesApproaching = React.useMemo(
    () => data.some(item => item.type === 'vaccineReminderApproaching'),
    [data]
  )
  const vaccinesExpired = React.useMemo(
    () => data.some(item => item.type === 'vaccineReminderExpired'),
    [data]
  )

  return (
    <Content wide>
      <Title>{t('myAppointments')}</Title>
      <FiltersSection condensed>
        <FormAgendaFilters values={params} onSubmit={handleParams} />
      </FiltersSection>
      <Loader loading={isFetching} error={isError}>
        {vaccinesApproaching && (
          <Alert
            type="warning"
            primary={t('vaccinesApproachingExpiry')}
            secondary={t('vaccinesApproachingExpiryDesc')}
          />
        )}
        {vaccinesExpired && (
          <Alert
            type="warning"
            primary={t('vaccinesExpired')}
            secondary={t('vaccinesExpiredDesc')}
          />
        )}
        <Calendar events={data} />
      </Loader>
    </Content>
  )
}

export default Agenda
