import api from '../.'
import {
  type DeleteFilePayload,
  type EditFilePayload,
  type FileAccess,
  type FileCategory,
  type FileEntity,
  type UploadFilePayload,
  type UserBasic,
  type VetecardBasic,
} from '../../typings'
import { type InsertOwnerVetecardInvoiceApiResponseT } from '../pure/owner/vetecard/invoice/insert'
import { type ListOwnerVetecardInvoicesResponseT } from '../pure/owner/vetecard/invoice/list'
import { type UpdateInvoiceApiResponseT } from '../pure/owner/vetecard/invoice/update'
import { type InsertOwnerVetecardMedicalFileApiResponseT } from '../pure/owner/vetecard/medical_file/insert'
import { type ListOwnerVetecardMedicalFilesResponseT } from '../pure/owner/vetecard/medical_file/list'
import { type UpdateMedicalFileApiResponseT } from '../pure/owner/vetecard/medical_file/update'
import { type ListTreatmentsResponseT } from '../pure/owner/vetecard/treatment/list'
import { type UpdateTreatmentApiResponseT } from '../pure/owner/vetecard/treatment/update'
import { type InsertPetsitterVetecardInvoiceApiResponseT } from '../pure/petsitter/vetecard/invoice/insert'
import { type ListPetsitterVetecardApiInvoicesResponseEntryT } from '../pure/petsitter/vetecard/invoice/list'
import { type InsertPetsitterVetecardMedicalFileApiResponseT } from '../pure/petsitter/vetecard/medical_file/insert'
import { type ListPetsitterVetecardApiMedicalFilesResponseEntryT } from '../pure/petsitter/vetecard/medical_file/list'
import { type InsertPetsitterVetecardTreatmentApiResponseT } from '../pure/petsitter/vetecard/treatment/insert'
import { type ListPetsitterVetecardApiTreatmentsResponseEntryT } from '../pure/petsitter/vetecard/treatment/list'
import { type InsertVeterinarianVetecardInvoiceApiResponseT } from '../pure/veterinarian/vetecard/invoice/insert'
import { type ListVeterinarianVetecardInvoicesResponseT } from '../pure/veterinarian/vetecard/invoice/list'
import { type InsertVeterinarianVetecardMedicalFileApiResponseT } from '../pure/veterinarian/vetecard/medical_file/insert'
import { type ListVeterinarianVetecardMedicalFilesResponseT } from '../pure/veterinarian/vetecard/medical_file/list'
import { type InsertVeterinarianVetecardTreatmentApiResponseT } from '../pure/veterinarian/vetecard/treatment/insert'
import {
  copyObject,
  getMinioClient,
  getObject,
  getPresignedUrl,
  putObject,
  removeFile,
} from './storage'

export const getFiles = async (
  vetecardId: VetecardBasic['id'],
  { userType, keycloakId, veterinarianId }: UserBasic,
  category: FileCategory,
  requestedAccess: FileAccess[] = ['confidental', 'shareable', 'public']
): Promise<FileEntity[]> => {
  const storage = await getMinioClient()
  let apiResponse
  let bucketName = ''

  switch (category) {
    case 'treatment': {
      bucketName = process.env.MINIO_BUCKET_VETECARD_TREATMENT as string

      switch (userType) {
        case 'individual':
        case 'association':
        case 'breeder': {
          const response = (await api.get(
            `/0/owner/${userType}/${keycloakId}/vetecard/${vetecardId}/treatment`
          )) as { data: ListTreatmentsResponseT }
          apiResponse = response.data
          break
        }

        case 'petsitter': {
          const response = (await api.get(
            `/0/petsitter/${keycloakId}/vetecard/${vetecardId}/treatment`
          )) as { data: ListPetsitterVetecardApiTreatmentsResponseEntryT[] }
          apiResponse = response.data
          break
        }

        case 'veterinarian': {
          if (!veterinarianId)
            throw new Error('Failed to upload file as Veterinarian with missing "veterinarianId"')
          const response = (await api.get(
            `/0/veterinarian/${veterinarianId}/vetecard/${vetecardId}/treatment`
          )) as { data: ListTreatmentsResponseT }
          apiResponse = response.data
          break
        }

        default:
          throw new Error(
            `Cannot get treatment files for the user type ${JSON.stringify(userType)}.`
          )
      }
      break
    }

    case 'medical': {
      bucketName = process.env.MINIO_BUCKET_VETECARD_MEDICAL_FILES as string

      switch (userType) {
        case 'individual':
        case 'association':
        case 'breeder': {
          const response = (await api.get(
            `/0/owner/${userType}/${keycloakId}/vetecard/${vetecardId}/medical_file`
          )) as { data: ListOwnerVetecardMedicalFilesResponseT }
          apiResponse = response.data
          break
        }

        case 'petsitter': {
          const response = (await api.get(
            `/0/petsitter/${keycloakId}/vetecard/${vetecardId}/medical_file`
          )) as { data: ListPetsitterVetecardApiMedicalFilesResponseEntryT[] }
          apiResponse = response.data
          break
        }

        case 'veterinarian': {
          if (!veterinarianId)
            throw new Error('Failed to upload file as Veterinarian with missing "veterinarianId"')
          const response = (await api.get(
            `/0/veterinarian/${veterinarianId}/vetecard/${vetecardId}/medical_file`
          )) as { data: ListVeterinarianVetecardMedicalFilesResponseT }
          apiResponse = response.data
          break
        }

        default:
          throw new Error(
            `Cannot get treatment files for the user type ${JSON.stringify(userType)}.`
          )
      }
      break
    }

    case 'invoice': {
      switch (userType) {
        case 'individual':
        case 'association':
        case 'breeder': {
          const response = (await api.get(
            `/0/owner/${userType}/${keycloakId}/vetecard/${vetecardId}/invoice`
          )) as { data: ListOwnerVetecardInvoicesResponseT }
          apiResponse = response.data
          break
        }

        case 'petsitter': {
          const response = (await api.get(
            `/0/petsitter/${keycloakId}/vetecard/${vetecardId}/invoice`
          )) as { data: ListPetsitterVetecardApiInvoicesResponseEntryT[] }
          apiResponse = response.data
          break
        }

        case 'veterinarian': {
          if (!veterinarianId)
            throw new Error('Failed to upload file as Veterinarian with missing "veterinarianId"')
          const response = (await api.get(
            `/0/veterinarian/${veterinarianId}/vetecard/${vetecardId}/invoice`
          )) as { data: ListVeterinarianVetecardInvoicesResponseT }
          apiResponse = response.data
          break
        }

        default:
          throw new Error(
            `Cannot get treatment files for the user type ${JSON.stringify(userType)}.`
          )
      }
      break
    }

    default:
      throw new Error(`Unhandled file category "${category}"`)
  }

  const presignedFiles = await Promise.all(
    apiResponse.map(async item => ({
      ...item,
      url: await getPresignedUrl(storage, vetecardId, bucketName, item.storageKey || ''),
    }))
  )

  return presignedFiles
    .map(
      ({
        id,
        storageKey,
        type,
        access,
        url,
        documentDate,
        treatmentDate,
        veterinarianResponsibilities,
        edit,
        remove,
      }): FileEntity => ({
        id: id ?? NaN,
        name: storageKey ?? '',
        type: (type ?? 'other') as FileEntity['type'],
        category,
        url: url ?? '',
        documentDate: documentDate ? new Date(documentDate) : undefined,
        treatmentDate: treatmentDate ? new Date(treatmentDate) : undefined,
        sharedWith:
          veterinarianResponsibilities === null
            ? undefined
            : veterinarianResponsibilities.map(
                ({ veterinarian: { id: veterinarianId, firstName, lastName, profile } }) => ({
                  veterinarianId,
                  firstName,
                  lastName,
                  userType: 'veterinarian',
                  keycloakId: profile !== null ? profile.id : '',
                })
              ),
        canEdit: edit || undefined,
        canDelete: remove || undefined,
        ...(access === null ? { access: 'public' } : { access }),
      })
    )
    .filter(({ access }) => requestedAccess.includes(access as FileAccess)) // FIXME: WCARD-505
}

export const uploadFile = async (
  { userType, keycloakId, veterinarianId }: UserBasic,
  payload: UploadFilePayload
): Promise<FileEntity> => {
  const {
    file,
    name,
    type,
    access,
    documentDate,
    treatmentDate = payload.documentDate,
    category,
    vetecardId,
  } = payload

  const storage = await getMinioClient()
  let id
  let bucketName = ''
  const buffer = Buffer.from(await file.arrayBuffer())

  switch (category) {
    case 'medical': {
      bucketName = process.env.MINIO_BUCKET_VETECARD_MEDICAL_FILES as string
      const body = {
        storageKey: name,
        type,
        access,
        documentDate: new Date(documentDate),
        treatmentDate: new Date(treatmentDate),
      }

      switch (userType) {
        case 'individual':
        case 'association':
        case 'breeder': {
          const response = (await api.post(
            `/0/owner/${userType}/${keycloakId}/vetecard/${vetecardId}/medical_file`,
            body
          )) as { data: InsertOwnerVetecardMedicalFileApiResponseT }
          id = response.data.medicalFile.id
          break
        }

        case 'petsitter': {
          //FIXME ??
          const response = (await api.post(
            `/0/petsitter/${keycloakId}/vetecard/${vetecardId}/medical_file`,
            body
          )) as { data: InsertPetsitterVetecardMedicalFileApiResponseT }
          id = response.data.medicalFile.id
          break
        }

        case 'veterinarian': {
          if (!veterinarianId)
            throw new Error('Failed to upload file as Veterinarian with missing "veterinarianId"')
          const response = (await api.post(
            `/0/veterinarian/${veterinarianId}/vetecard/${vetecardId}/medical_file`,
            body
          )) as { data: InsertVeterinarianVetecardMedicalFileApiResponseT }
          id = response.data.medicalFile.id
          break
        }

        default:
          throw new Error(`Tried to upload file as an invalid user. Provided: ${userType}`)
      }
      break
    }

    case 'invoice': {
      bucketName = process.env.MINIO_BUCKET_VETECARD_INVOICES as string
      const body = {
        storageKey: name,
        type,
        access,
        documentDate: new Date(documentDate),
        treatmentDate: new Date(treatmentDate),
      }

      switch (userType) {
        case 'individual':
        case 'association':
        case 'breeder': {
          const response = (await api.post(
            `/0/owner/${userType}/${keycloakId}/vetecard/${vetecardId}/invoice`,
            body
          )) as { data: InsertOwnerVetecardInvoiceApiResponseT }
          id = response.data.invoice.id
          break
        }

        case 'petsitter': {
          const response = (await api.post(
            `/0/petsitter/${keycloakId}/vetecard/${vetecardId}/invoice`,
            body
          )) as { data: InsertPetsitterVetecardInvoiceApiResponseT }
          id = response.data.invoice.id
          break
        }

        case 'veterinarian': {
          if (!veterinarianId)
            throw new Error('Failed to upload file as Veterinarian with missing "veterinarianId"')
          const response = (await api.post(
            `/0/veterinarian/${veterinarianId}/vetecard/${vetecardId}/invoice`,
            body
          )) as { data: InsertVeterinarianVetecardInvoiceApiResponseT }
          id = response.data.invoice.id
          break
        }

        default:
          throw new Error(`Tried to upload file as an invalid user. Provided: ${userType}`)
      }
      break
    }

    case 'treatment': {
      bucketName = process.env.MINIO_BUCKET_VETECARD_TREATMENT as string
      const body = {
        storageKey: name,
        type,
        access,
        documentDate: new Date(documentDate),
        treatmentDate: new Date(treatmentDate),
      }

      switch (userType) {
        case 'individual':
        case 'association':
        case 'breeder': {
          const response = (await api.post(
            `/0/owner/${userType}/${keycloakId}/vetecard/${vetecardId}/treatment`,
            body
          )) as { data: InsertPetsitterVetecardTreatmentApiResponseT }
          id = response.data.treatment.id
          break
        }

        case 'petsitter': {
          const response = (await api.post(
            `/0/petsitter/${keycloakId}/vetecard/${vetecardId}/treatment`,
            body
          )) as { data: InsertPetsitterVetecardTreatmentApiResponseT }
          id = response.data.treatment.id
          break
        }

        case 'veterinarian': {
          if (!veterinarianId)
            throw new Error('Failed to upload file as Veterinarian with missing "veterinarianId"')
          const response = (await api.post(
            `/0/veterinarian/${veterinarianId}/vetecard/${vetecardId}/treatment`,
            body
          )) as { data: InsertVeterinarianVetecardTreatmentApiResponseT }
          id = response.data.treatment.id
          break
        }

        default:
          throw new Error(`Tried to upload file as an invalid user. Provided: ${userType}`)
      }
      break
    }

    default:
      throw new Error(`Unhandled file category "${category}"`)
  }

  await putObject(storage, vetecardId, bucketName, name, buffer)
  return {
    id,
    name,
    type,
    category,
    access,
    url: await getPresignedUrl(storage, vetecardId, bucketName, name),
    documentDate: new Date(documentDate),
    treatmentDate: new Date(treatmentDate),
    canEdit: true,
    canDelete: true,
  }
}

export const downloadFile = async (
  category: FileCategory,
  vetecardId: VetecardBasic['id'],
  name: string
): Promise<void> => {
  const storage = await getMinioClient()
  let bucketName = ''

  switch (category) {
    case 'medical':
      bucketName = process.env.MINIO_BUCKET_VETECARD_MEDICAL_FILES as string
      break
    case 'invoice':
      bucketName = process.env.MINIO_BUCKET_VETECARD_INVOICES as string
      break
    case 'treatment':
      bucketName = process.env.MINIO_BUCKET_VETECARD_TREATMENT as string
      break
    default:
      throw new Error(`Unhandled file category "${category}"`)
  }

  const response = await getObject(storage, vetecardId, bucketName, name)
  console.log('response: ', response)
  // TODO: Trigger a file download here.
  // await window.showSaveFilePicker({})
  return
}

export const editFile = async (payload: EditFilePayload): Promise<FileEntity> => {
  const {
    id,
    category,
    vetecardId,
    name,
    type,
    access,
    documentDate,
    treatmentDate = payload.documentDate,
  } = payload

  const storage = await getMinioClient()
  let storageKey
  let bucketName = ''

  const body = {
    id,
    storageKey: name,
    type,
    access,
    documentDate: new Date(documentDate),
    treatmentDate: new Date(treatmentDate),
  }

  switch (category) {
    case 'treatment': {
      bucketName = process.env.MINIO_BUCKET_VETECARD_TREATMENT as string
      const response = (await api.patch(`/0/vetecard/${vetecardId}/treatment/${id}`, body)) as {
        data: UpdateTreatmentApiResponseT
      }
      storageKey = response.data.storageKey
      break
    }

    case 'medical': {
      bucketName = process.env.MINIO_BUCKET_VETECARD_MEDICAL_FILES as string
      const response = (await api.patch(`/0/vetecard/${vetecardId}/medical_file/${id}`, body)) as {
        data: UpdateMedicalFileApiResponseT
      }
      storageKey = response.data.storageKey
      break
    }

    case 'invoice': {
      bucketName = process.env.MINIO_BUCKET_VETECARD_INVOICES as string
      const response = (await api.patch(`/0/vetecard/${vetecardId}/invoice/${id}`, body)) as {
        data: UpdateInvoiceApiResponseT
      }
      storageKey = response.data.storageKey
      break
    }
    default:
      throw new Error(`Unhandled file category ${JSON.stringify(category)}`)
  }

  if (body.storageKey !== storageKey) {
    await copyObject(storage, vetecardId, bucketName, storageKey, body.storageKey)
    await removeFile(storage, vetecardId, bucketName, storageKey)
    storageKey = body.storageKey
  }

  return {
    category,
    id,
    name,
    type,
    access,
    documentDate: documentDate ? new Date(documentDate) : undefined,
    treatmentDate: treatmentDate ? new Date(treatmentDate) : undefined,
    url: await getPresignedUrl(storage, vetecardId, bucketName, storageKey),
    createdAt: new Date(),
    canDelete: true, // FIXME: WCARD-545
    canEdit: true, // FIXME: WCARD-545
  }
}

export const deleteFile = async (payload: DeleteFilePayload): Promise<DeleteFilePayload['id']> => {
  const { id, name, vetecardId, category } = payload
  const storage = await getMinioClient()
  let storageKey
  let bucketName = ''

  switch (category) {
    case 'medical': {
      bucketName = process.env.MINIO_BUCKET_VETECARD_MEDICAL_FILES as string
      if (id) {
        const response = (await api.delete(`/0/vetecard/${vetecardId}/medical_file/${id}`)) as {
          data: string
        }
        storageKey = response.data
      } else {
        storageKey = name
      }
      break
    }

    case 'invoice': {
      bucketName = process.env.MINIO_BUCKET_VETECARD_INVOICES as string
      if (id) {
        const response = (await api.delete(`0/vetecard/${vetecardId}/invoice/${id}`)) as {
          data: string
        }
        storageKey = response.data
      } else {
        storageKey = name
      }
      break
    }

    case 'treatment': {
      bucketName = process.env.MINIO_BUCKET_VETECARD_TREATMENT as string
      if (id) {
        const response = (await api.delete(`/0/vetecard/${vetecardId}/treatment/${id}`)) as {
          data: string
        }
        storageKey = response.data
      } else {
        storageKey = name
      }
      break
    }

    default:
      throw new Error(`Unhandled file category "${category}"`)
  }

  await removeFile(storage, vetecardId, bucketName, storageKey)
  return id
}
