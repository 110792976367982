import * as t from 'io-ts'
import { nullable } from 'io-ts/Type'
import { DateFromISOString } from 'io-ts-types'


export const minioBucketVetecardMedicalFiles = process.env.MINIO_BUCKET_VETECARD_MEDICAL_FILES as string

if (typeof minioBucketVetecardMedicalFiles !== 'string') {
  throw new Error('Missing "MINIO_BUCKET_VETECARD_MEDICAL_FILES" environment variable')
}


export const MedicalFileType = t.union([
  t.literal('document'),
  t.literal('report'),
  t.literal('radiologic'),
  t.literal('photo'),
  t.literal('video'),
  t.literal('other'),
])
export type MedicalFileTypeT = t.TypeOf<typeof MedicalFileType>

export const MedicalFileAccess = t.union([
  t.literal('confidental'),
  t.literal('shareable'),
  t.literal('public'),
])
export type MedicalFileAccessT = t.TypeOf<typeof MedicalFileAccess>

export const MedicalFileKey = t.type({
  id: t.number,
})
export type MedicalFileKeyT = t.TypeOf<typeof MedicalFileKey>

export const MedicalFile = t.type({
  storageKey: t.string,
  type: nullable(MedicalFileType),
  access: nullable(MedicalFileAccess),
  documentDate: nullable(DateFromISOString),
  treatmentDate: nullable(DateFromISOString),
})
export type MedicalFileT = t.TypeOf<typeof MedicalFile>

export const MedicalFileWithKey = t.intersection([
  MedicalFileKey,
  MedicalFile,
])
export type MedicalFileWithKeyT = t.TypeOf<typeof MedicalFileWithKey>
