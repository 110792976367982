import { useNavigate } from 'react-router-dom'

export default function useGoBack() {
  const navigate = useNavigate()

  const goBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate('/', { replace: true }) // the current entry in the history stack will be replaced with the new one with { replace: true }
    }
  }
  return goBack
}
