import * as t from 'io-ts'
import { nullable } from 'io-ts/Type'

import { DateFromISOString } from 'io-ts-types'


export const V0VeterinarianCategory = t.string
export type V0VeterinarianCategoryT = t.TypeOf<typeof V0VeterinarianCategory>

export const VeterinarianCategory = V0VeterinarianCategory
export type VeterinarianCategoryT = t.TypeOf<typeof VeterinarianCategory>

export const VeterinarianProfileId = t.string //FIXME tt.UUID
export type VeterinarianProfileIdT = t.TypeOf<typeof VeterinarianProfileId>

export const VeterinarianProfileKey = t.type({
  id: VeterinarianProfileId,
})
export type VeterinarianProfileKeyT = t.TypeOf<typeof VeterinarianProfileKey>

export const VeterinarianProfileEmail = t.string
export type VeterinarianProfileEmailT = t.TypeOf<typeof VeterinarianProfileEmail>

export const V0VeterinarianProfile = t.type({
  id: VeterinarianProfileId,
  modified: nullable(DateFromISOString),
  created: nullable(DateFromISOString),
  logged: nullable(DateFromISOString),
  email: nullable(VeterinarianProfileEmail),
  politeness: nullable(t.string),
  field: nullable(t.string),
  category: nullable(V0VeterinarianCategory),
  specialization: t.array(t.string),
})
export type V0VeterinarianProfileT = t.TypeOf<typeof V0VeterinarianProfile>

export const VeterinarianProfile = V0VeterinarianProfile
export type VeterinarianProfileT = t.TypeOf<typeof VeterinarianProfile>
