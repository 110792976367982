import React from 'react'
import { useMutation } from '@tanstack/react-query'
import Form, { Input } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { uploadConsent } from '../api/formatted/consent'
import SubmitButton from '../components/SubmitButton'
import {
  type Consent,
  type FileInput,
  type UploadConsentPayload,
  type VetecardBasic,
} from '../typings'
import queryClient from '../utils/queryClient'

interface FormUploadConsentProps {
  vetecardId: VetecardBasic['id']
  onSuccess?: () => void
}

interface FormValues {
  file: FileInput
  documentDate: string
  signatureDate: string
}

const FormUploadConsent = ({ vetecardId, onSuccess }: FormUploadConsentProps) => {
  const { isLoading, mutate } = useMutation(
    (payload: UploadConsentPayload) => uploadConsent(payload),
    {
      onSuccess: (file: Consent) => {
        queryClient.setQueryData(['vetecard', vetecardId, 'consent'], (oldData: Consent[] = []) => [
          file,
          ...oldData,
        ])
        toast.success(t('fileUploaded'))
        onSuccess && onSuccess()
      },
    }
  )
  const { t } = useTranslation()

  const handleSubmit = ({ file, documentDate, signatureDate }: FormValues) => {
    mutate({
      file: file.dataFile,
      documentDate,
      signatureDate,
      vetecardId,
    })
  }

  return (
    <Form fields={['file', 'documentDate', 'signatureDate']} allMandatory>
      <Input name="file" label={t('fileToUpload')} type="file" />
      <Input name="documentDate" label={t('documentDate')} type="date" />
      <Input name="signatureDate" label={t('signatureDate')} type="date" />
      <SubmitButton onClick={handleSubmit} loading={isLoading}>
        {t('upload')}
      </SubmitButton>
    </Form>
  )
}

export default FormUploadConsent
