import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Link, useLocation } from 'react-router-dom'
import { type Link as LinkT } from '../../typings'
import './style.sass'

interface NavVerticalProps {
  links: LinkT[]
  className?: string
}

const NavVertical = ({ links, className }: NavVerticalProps) => {
  const { pathname } = useLocation()

  return (
    <nav className={classNames('nav-vertical', className)}>
      {links.map(({ url, label, icon, onClick }, index) =>
        url ? (
          <Link
            to={url}
            key={index}
            className={classNames('nav-vertical__link', {
              'nav-vertical__link--active': url === pathname,
            })}
          >
            {icon && (
              <div className="nav-vertical__icon">
                <FontAwesomeIcon icon={icon} />
              </div>
            )}{' '}
            {label}
          </Link>
        ) : (
          <button
            onClick={onClick}
            key={index}
            className={classNames('nav-vertical__link')}
            type="button"
          >
            {icon && <FontAwesomeIcon className="nav-vertical__icon" icon={icon} />} {label}
          </button>
        )
      )}
    </nav>
  )
}

export default NavVertical
