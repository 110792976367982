import { either } from 'fp-ts/lib/Either'
import * as t from 'io-ts'


// export interface IMatchesRegexpString extends t.StringType {
//   readonly matchesRegexpString: unique symbol
// }

// export const matchesRegexpString = (regexp: RegExp) => t.brand(
//   t.string,
//   (value: string): value is t.Branded<string, IMatchesRegexpString> => regexp.test(value),
//   'matchesRegexpString' //FIXME: include expression
// )

export const matchesRegexpString = (regexp: RegExp) => new t.Type<string, string, unknown>(
  `matchesRegexpString<${regexp.source}>}>`,
  t.string.is,
  (u, c) =>
    either.chain(
      t.string.validate(u, c),
      (value: string) => {
      return regexp.test(value) ? t.success(value) : t.failure(u, c, `Value "${value}" doesn't match RegExp ${regexp.source}`)
    }),
  String
)
