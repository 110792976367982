import React from 'react'
import Form, { Input, Row } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import SubmitButton from '../components/SubmitButton'
import VeterinarianSearchInput from '../containers/VeterinarianSearchInput'
import { type FileInput, type Vaccination } from '../typings'
import formatDate from '../utils/formatDate'
import formatName from '../utils/formatName'

interface FormVaccinationProps {
  initialValues?: Partial<Vaccination>
  onSubmit: (values: FormVaccinationValues) => void
  isLoading: boolean
  renewId?: Vaccination['id']
}

export interface FormVaccinationValues {
  file?: FileInput
  type: string
  manufacturer: string
  batchNumber: string
  vaccinationDate: string
  validFrom: string
  validTo: string
  signedBy: number
  renewId?: number
}

const FormVaccination = ({ initialValues, onSubmit, isLoading, renewId }: FormVaccinationProps) => {
  const { t } = useTranslation()

  const handleSubmit = (values: FormVaccinationValues) => {
    onSubmit(values)
  }

  return (
    <Form
      fields={[
        'type',
        'manufacturer',
        'batchNumber',
        'vaccinationDate',
        'validFrom',
        'validTo',
        'signedBy',
        ...(!initialValues ? ['file'] : []),
        ...(renewId ? ['renewId'] : []),
      ]}
      mandatory={[
        'type',
        'manufacturer',
        'batchNumber',
        'vaccinationDate',
        'validFrom',
        'validTo',
        'signedBy',
      ]}
    >
      {!initialValues && <Input name="file" label={t('fileToUpload')} type="file" />}
      <Input name="type" label={t('type')} initialValue={initialValues?.type} />
      <Input
        name="manufacturer"
        label={t('manufacturer')}
        initialValue={initialValues?.manufacturer}
      />
      <Input name="batchNumber" label={t('batch')} initialValue={initialValues?.batchNumber} />
      <Input
        name="vaccinationDate"
        label={t('vaccinationDate')}
        type="date"
        initialValue={
          initialValues?.vaccinationDate && formatDate(initialValues.vaccinationDate, 'input')
        }
      />
      <Row>
        <Input
          name="validFrom"
          label={t('validFrom')}
          type="date"
          initialValue={initialValues?.validFrom && formatDate(initialValues.validFrom, 'input')}
        />
        <Input
          name="validTo"
          label={t('validUntil')}
          type="date"
          initialValue={initialValues?.validTo && formatDate(initialValues.validTo, 'input')}
        />
      </Row>
      <VeterinarianSearchInput
        name="signedBy"
        label={t('signedBy')}
        initialPhrase={formatName(
          initialValues?.signedBy?.firstName,
          initialValues?.signedBy?.lastName
        )}
      />
      {renewId && (
        <Input
          name="renewId"
          label={t('ID of the vaccine that has been renewed')}
          initialValue={renewId}
          disabled
        />
      )}
      <SubmitButton onClick={handleSubmit} loading={isLoading}>
        {t('upload')}
      </SubmitButton>
    </Form>
  )
}

export default FormVaccination
