import React from 'react'
import './style.sass'

interface CountProps {
  number: number
  plus?: boolean
}

const Count = ({ number, plus }: CountProps) => (
  <div className="count">
    {plus && '+'}
    {number}
  </div>
)

export default Count
