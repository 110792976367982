import * as t from 'io-ts'
import { nullable } from 'io-ts/Type'

import { DateFromISOString } from 'io-ts-types'

import { Veterinarian } from '../../../veterinarian'


export const minioBucketVetecardOtherVaccinations = process.env.MINIO_BUCKET_VETECARD_OTHER_VACCINATIONS as string

if (typeof minioBucketVetecardOtherVaccinations !== 'string') {
  throw new Error('Missing "MINIO_BUCKET_VETECARD_OTHER_VACCINATIONS" environment variable')
}

export const OtherVaccination = t.type({
  storageKey: nullable(t.string),
  type: nullable(t.string),
  manufacturer: nullable(t.string),
  batchNumber: nullable(t.string),
  vaccinationDate: nullable(DateFromISOString),
  validFrom: nullable(DateFromISOString),
  validTo: nullable(DateFromISOString),
  signedBy: nullable(Veterinarian.props.id),
})
export type OtherVaccinationT = t.TypeOf<typeof OtherVaccination>

export const OtherVaccinationId = t.number
export type OtherVaccinationIdT = t.TypeOf<typeof OtherVaccinationId>

export const OtherVaccinationKey = t.type({
  id: OtherVaccinationId,
})
export type OtherVaccinationKeyT = t.TypeOf<typeof OtherVaccinationKey>

export const OtherVaccinationWithKey = t.intersection([
  OtherVaccinationKey,
  OtherVaccination,
])
export type OtherVaccinationWithKeyT = t.TypeOf<typeof OtherVaccinationWithKey>
