import React from 'react'
import Form, { Radio } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { type FileCategory, type VaccinationCategory } from '../typings'

interface FormSelectFileCategoryProps {
  initialValue?: FileCategory | VaccinationCategory
  onSelect: (category: FileCategory | VaccinationCategory) => void
}

const FormSelectFileCategory = ({ initialValue, onSelect }: FormSelectFileCategoryProps) => {
  const { t } = useTranslation()

  return (
    <Form fields={['category']}>
      <Radio
        name="category"
        options={[
          { label: t('treatmentFile'), value: 'treatment' },
          { label: t('medicalFile'), value: 'medical' },
          { label: t('billPrescription'), value: 'invoice' },
          { label: t('mandatoryVaccination'), value: 'mandatory' },
          { label: t('otherVaccination'), value: 'other' },
        ]}
        initialValue={initialValue}
        onChange={onSelect}
        noBottomGutter
      />
    </Form>
  )
}

export default FormSelectFileCategory
