import React from 'react'
import { useQueries } from '@tanstack/react-query'
import Form, { Checkboxes } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { getFiles } from '../api/formatted/files'
import Loader from '../components/Loader'
import SubmitButton from '../components/SubmitButton'
import { type FileEntity, type SharedFileIds, type VetecardBasic } from '../typings'
import formatDate from '../utils/formatDate'
import useAuth from '../utils/useAuth'

export interface FormValues {
  treatmentFiles?: string[]
  medicalFiles?: string[]
  invoices?: string[]
}

interface FormFileSharingProps {
  vetecardId: VetecardBasic['id']
  sharedFileIds?: SharedFileIds
  sharedFileIdsIsFetching: boolean
  sharedFileIdsIsError: boolean
  onSubmit: (values: FormValues) => void
  isLoading: boolean
}

const FormFileSharing = ({
  vetecardId,
  sharedFileIds,
  sharedFileIdsIsFetching,
  sharedFileIdsIsError,
  onSubmit,
  isLoading,
}: FormFileSharingProps) => {
  const { t } = useTranslation()
  const { currentUser } = useAuth()

  const filesToOptions = (files: FileEntity[]) =>
    files.map(({ id, name, type /*, url*/, documentDate }) => ({
      label: (
        <div>
          <strong>{name}</strong> {t(`fileType.${type}`)}, {formatDate(documentDate)}
        </div>
      ),
      value: id,
    }))

  const queries = useQueries({
    queries: [
      {
        queryKey: ['vetecard', vetecardId, 'files', 'treatment'],
        queryFn: () => getFiles(vetecardId, currentUser, 'treatment', ['shareable']),
      },
      {
        queryKey: ['vetecard', vetecardId, 'files', 'medical'],
        queryFn: () => getFiles(vetecardId, currentUser, 'medical', ['shareable']),
      },
      {
        queryKey: ['vetecard', vetecardId, 'files', 'invoice'],
        queryFn: () => getFiles(vetecardId, currentUser, 'invoice', ['shareable']),
      },
    ],
  })

  const treatmentFiles = (queries[0].data || []) as FileEntity[]
  const medicalFiles = (queries[1].data || []) as FileEntity[]
  const invoices = (queries[2].data || []) as FileEntity[]

  const treatmentFilesOptions = React.useMemo(
    () => filesToOptions(treatmentFiles),
    [treatmentFiles]
  )
  const medicalFilesOptions = React.useMemo(
    () => filesToOptions(medicalFiles),
    [medicalFiles, sharedFileIds]
  )
  const invoicesOptions = React.useMemo(() => filesToOptions(invoices), [invoices, sharedFileIds])
  const treatmentFilesValues = React.useMemo(
    () =>
      treatmentFiles.map(({ id }) => id).filter(id => sharedFileIds?.treatmentFiles.includes(id)),
    [treatmentFiles, sharedFileIds]
  )
  const medicalFilesValues = React.useMemo(
    () => medicalFiles.map(({ id }) => id).filter(id => sharedFileIds?.medicalFiles.includes(id)),
    [medicalFiles, sharedFileIds]
  )
  const invoicesValues = React.useMemo(
    () => invoices.map(({ id }) => id).filter(id => sharedFileIds?.invoices.includes(id)),
    [invoices, sharedFileIds]
  )

  return (
    <Loader
      loading={queries.some(result => result.isLoading) || sharedFileIdsIsFetching}
      error={queries.some(result => result.isError) || sharedFileIdsIsError}
    >
      <Form fields={['treatmentFiles', 'medicalFiles', 'invoices']}>
        {treatmentFilesOptions.length ? (
          <Checkboxes
            name="treatmentFiles"
            label={t('treatment')}
            options={treatmentFilesOptions}
            initialValue={treatmentFilesValues}
          />
        ) : null}
        {medicalFilesOptions.length ? (
          <Checkboxes
            name="medicalFiles"
            label={t('medicalFollowup')}
            options={medicalFilesOptions}
            initialValue={medicalFilesValues}
          />
        ) : null}
        {invoicesOptions.length ? (
          <Checkboxes
            name="invoices"
            label={t('billsPrescriptions')}
            options={invoicesOptions}
            initialValue={invoicesValues}
          />
        ) : null}
        <SubmitButton size="sm" onClick={onSubmit} loading={isLoading}>
          {t('apply')}
        </SubmitButton>
      </Form>
    </Loader>
  )
}

export default FormFileSharing
