import React from 'react'
import { useQuery } from '@tanstack/react-query'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import { getVetecards } from '../api/formatted/vetecard'
import Button from '../components/Button'
import FiltersSection from '../components/FiltersSection'
import LoadMore from '../components/LoadMore'
import Loader from '../components/Loader'
import Panel from '../components/Panel'
import Table from '../components/Table'
import FormVetecardSearch from '../forms/FormVetecardSearch'
import { type VetecardsPayload } from '../typings'
import { useTranslatedOptions } from '../utils/helpers'
import useAuth from '../utils/useAuth'

const VetecardsAdvancedContainer = () => {
  const { t } = useTranslation()
  const { hasPermission, currentUser } = useAuth()
  const { search } = useLocation()
  const navigate = useNavigate()
  const speciesOptions = useTranslatedOptions('species')

  const params = React.useMemo(
    () => queryString.parse(search, { arrayFormat: 'bracket' }) as VetecardsPayload,
    [search]
  )

  // https://tanstack.com/query/latest/docs/react/examples/react/pagination
  const {
    data: { vetecards, hasMore } = { vetecards: [], hasMore: false },
    refetch,
    isFetching,
    isError,
  } = useQuery(['vetecard-list'], () => getVetecards(currentUser, params), {
    refetchOnMount: false, // Reuse data from query on main Dashboard page, if it is present.
  })

  React.useEffect(() => {
    if (Object.keys(params).length) refetch()
  }, [params])

  const handleParams = (values: VetecardsPayload) => {
    const newFiltersQueryString = queryString.stringify(values, { arrayFormat: 'bracket' })
    navigate(`/vetecards?${newFiltersQueryString}`)
  }

  const handleReset = () => {
    navigate('/vetecards')
  }

  const vetecardsWithLinks = React.useMemo(
    () =>
      vetecards.length
        ? vetecards.map(({ name, species, birthDate, ...item }) => ({
            ...item,
            name: <Link to={`/vetecards/${item.id}`}>{name}</Link>,
            species: t(`species.${species}`),
            birthDate: birthDate.toLocaleDateString(),
          }))
        : [],
    [vetecards, t]
  )

  return (
    <Loader loading={isFetching} error={isError}>
      <FiltersSection>
        <FormVetecardSearch
          onSubmit={handleParams}
          onReset={handleReset}
          values={params}
          speciesOptions={speciesOptions}
        />
      </FiltersSection>
      <Panel>
        <Table
          labels={[
            { name: t('name'), field: 'name' },
            { name: t('id'), field: 'id' },
            { name: t('speciesLabel'), field: 'species' },
            { name: t('birthDate'), field: 'birthDate' },
          ]}
          data={vetecardsWithLinks}
          bottomMargin
        />
        <LoadMore loading={isFetching} show={hasMore} onClick={() => handleParams({ page: 1 })} />
        {hasPermission('request-vetecard') && (
          <Button component={Link} to="/vetecards/request">
            {t('requestVetecard')}
          </Button>
        )}
      </Panel>
    </Loader>
  )
}

export default VetecardsAdvancedContainer
