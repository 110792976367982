import React from 'react'
import { faBoxArchive } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@tanstack/react-query'
import queryString from 'query-string'
import Form, { Select } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { getVetecards } from '../api/formatted/vetecard'
import Alert from '../components/Alert'
import Block from '../components/Block'
import Button from '../components/Button'
import FiltersSection from '../components/FiltersSection'
import Grid from '../components/Grid'
import Loader from '../components/Loader'
import Text from '../components/Text'
import VetecardAdd from '../components/VetecardAdd'
import VetecardCard, { VetecardCardStack } from '../components/VetecardCard'
import { type VetecardBasic, type VetecardStack, type VetecardsPayload } from '../typings'
import { useTranslatedOptions } from '../utils/helpers'
import useAuth from '../utils/useAuth'

const VetecardsCardsContainer = () => {
  const { t } = useTranslation()
  const { hasPermission, currentUser } = useAuth()
  const { search } = useLocation()
  const navigate = useNavigate()
  const [showArchived, setShowArchived] = React.useState(false)
  const speciesOptions = useTranslatedOptions('species')

  const {
    data: { vetecards } = { vetecards: [], hasMore: false },
    isFetching,
    isError,
    refetch,
  } = useQuery(
    ['vetecard-list'],
    () => getVetecards(currentUser, params, { withImage: true, withRequests: true }),
    {
      refetchOnMount: false, // Reuse data from query on main Dashboard page, if it is present.
    }
  )

  const params = React.useMemo(
    () => queryString.parse(search, { arrayFormat: 'bracket' }) as VetecardsPayload,
    [search]
  )

  React.useEffect(() => {
    refetch()
  }, [params])

  const hasUnisngedConsents = React.useMemo(
    () => vetecards.some(item => item.requestStatus === 'consentAgreement'),
    [vetecards]
  )

  const hasUnpaidRequests = React.useMemo(
    () => vetecards.some(item => item.requestStatus === 'payment'),
    [vetecards]
  )

  type ReduceObj = { [key: string]: VetecardStack }
  const stacks = React.useMemo(
    () =>
      params.species?.length || vetecards.length < 8
        ? []
        : Object.values(
            vetecards.reduce<ReduceObj>(
              (acc, { id, species }) => ({
                ...acc,
                [species]: {
                  species,
                  vetecards: acc[species]?.vetecards ? [...acc[species].vetecards, id] : [id],
                },
              }),
              {}
            )
          )
            .filter(item => item.vetecards.length > 1)
            .sort((a, b) => {
              if (a.vetecards.length < b.vetecards.length) return 1
              if (a.vetecards.length > b.vetecards.length) return -1
              return 0
            }),
    [vetecards, params]
  )

  const unstackedVetecards = React.useMemo(() => {
    if (stacks.length) {
      const stackedVetecards = stacks.reduce(
        (acc: Array<VetecardBasic['id']>, cur: VetecardStack) => [...acc, ...cur.vetecards],
        []
      )
      return vetecards.filter(item => !stackedVetecards.includes(item.id))
    }
    return vetecards
  }, [vetecards, params, stacks])

  const handleParams = (values: VetecardsPayload) => {
    const newFiltersQueryString = queryString.stringify(values, { arrayFormat: 'bracket' })
    navigate(`/vetecards?${newFiltersQueryString}`)
  }

  const SpeciesSelect = () => (
    <Form fields={['species']}>
      <Select
        name="species"
        label={t('speciesLabel')}
        inlineLabel
        options={speciesOptions}
        initialValue={params.species}
        onChange={(species: string) => handleParams({ species })}
        noBottomGutter
      />
    </Form>
  )

  return (
    <Loader loading={isFetching} error={isError}>
      <FiltersSection condensed>
        <SpeciesSelect />
      </FiltersSection>
      <Block>
        {hasUnisngedConsents && (
          <Alert
            type="info"
            primary={t('You have unsigned consent')}
            secondary={t('Some of your VET e-Cards has unsigned consent.')}
          />
        )}
        {hasUnpaidRequests && (
          <Alert
            type="info"
            btnLabel={t('payment.title')}
            btnUrl="/payment"
            primary={t('inactiveVetecards')}
            secondary={t('inactiveVetecardsDescr')}
          />
        )}
        <Grid columns={4}>
          {hasPermission('request-vetecard') && <VetecardAdd />}
          {stacks.map(item => (
            <VetecardCardStack {...item} key={item.species} />
          ))}
          {unstackedVetecards.map(item => (
            <VetecardCard {...item} key={item.id} />
          ))}
        </Grid>
      </Block>
      {showArchived ? (
        <Block title={t('archive')}>
          <Text>{t('archiveEmpty')}</Text>
        </Block>
      ) : (
        <Button size="sm" variant="link" onClick={() => setShowArchived(true)}>
          <FontAwesomeIcon icon={faBoxArchive} /> Show archived VET e-Cards
        </Button>
      )}
    </Loader>
  )
}

export default VetecardsCardsContainer
