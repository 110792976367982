import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { type Link as LinkT } from '../../typings'
import './style.sass'

interface MobileTabsProps {
  items: LinkT[]
  className?: string
}

const MobileTabs = ({ items, className }: MobileTabsProps) => (
  <div className={classNames('mobile-tabs', className)}>
    {items.map(({ url, label, icon }) =>
      url ? (
        <Link to={url} className="mobile-tabs__item" key={label}>
          {icon && <FontAwesomeIcon className="mobile-tabs__icon" icon={icon} />}
          <div className="mobile-tabs__label">{label}</div>
        </Link>
      ) : null
    )}
  </div>
)

export default MobileTabs
