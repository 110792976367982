import React from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '../components/Alert'
import Content from '../components/Content'
import Title from '../components/Title'

const Billing = () => {
  const { t } = useTranslation()
  return (
    <Content>
      <Title>{t('billing')}</Title>
      <Alert
        type="info"
        primary={t('sectionInPreparation.title')}
        secondary={t('sectionInPreparation.description')}
      />
    </Content>
  )
}

export default Billing
