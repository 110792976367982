import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import CatSvg from '../../assets/pictograms/cat.svg'
import DogSvg from '../../assets/pictograms/dog.svg'
import FerretSvg from '../../assets/pictograms/ferret.svg'
import HorseSvg from '../../assets/pictograms/horse.svg'
import OtherSvg from '../../assets/pictograms/other.svg'
import RabbitSvg from '../../assets/pictograms/rabbit.svg'
import VetSvg from '../../assets/pictograms/vet.svg'
import { type RequestStatus, type Species } from '../../typings'
import EditLink from './../EditLink'
import './style.sass'

type PictureIcon = Species | 'vet'

interface PictureProps {
  url?: string
  species?: PictureIcon
  isRequest?: boolean
  standalone?: boolean
  square?: boolean
  onEdit?: () => void
  requestStatus?: RequestStatus
  className?: string
}

const chooseIcon = (species?: PictureIcon) => {
  switch (species) {
    case 'cat':
      return <CatSvg />
    case 'dog':
      return <DogSvg />
    case 'ferret':
      return <FerretSvg />
    case 'horse':
      return <HorseSvg />
    case 'rabbit':
      return <RabbitSvg />
    case 'vet':
      return <VetSvg />
    default:
      return <OtherSvg />
  }
}

const Picture = ({
  url,
  species,
  isRequest = false,
  standalone,
  square,
  onEdit,
  requestStatus,
  className,
}: PictureProps) => {
  const { t } = useTranslation()
  return (
    <div
      className={classNames(
        'picture',
        {
          'picture--light': isRequest,
          'picture--standalone': standalone,
          'picture--square': square,
          'picture--clickable': onEdit,
        },
        className
      )}
      onClick={onEdit}
    >
      {url ? <img className="picture__img" src={url} alt={species} /> : chooseIcon(species)}
      {requestStatus && (
        <div className="picture__label">
          {requestStatus === 'consentAgreement'
            ? t('Unsigned consent')
            : requestStatus === 'payment'
            ? t('vetecardStates.awaitingPayment')
            : requestStatus === 'processingPayment' && t('vetecardStates.processingPayment')}
        </div>
      )}
      {!isRequest && onEdit && (
        <EditLink className="picture__edit">
          <span className="hidden-xs">{t('edit')}</span>
        </EditLink>
      )}
    </div>
  )
}

export default Picture
