import React from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { confirmVetecardRequest, getVetecardRequests } from '../api/formatted/vetecardRequests'
import Content from '../components/Content'
import Loader from '../components/Loader'
import Panel from '../components/Panel'
import Table from '../components/Table'
import Title from '../components/Title'
import { type VetecardAdvanced } from '../typings'
import formatDate from '../utils/formatDate'
import formatName from '../utils/formatName'
import queryClient from '../utils/queryClient'
import useAuth from '../utils/useAuth'

const VetecardRequests = () => {
  const { t } = useTranslation()
  const { currentUser } = useAuth()
  const isVeterinarian = currentUser.userType === 'veterinarian'

  const {
    data = [],
    isFetching,
    isError,
  } = useQuery(['vetecard-requests'], () => getVetecardRequests(currentUser))

  const { isLoading, mutate } = useMutation(
    (id: VetecardAdvanced['id']) => confirmVetecardRequest(currentUser, id),
    {
      onSuccess: (id: VetecardAdvanced['id']) => {
        toast.success('Assignation to vetecard confirmed')
        queryClient.setQueryData(['vetecard-requests'], (oldData: VetecardAdvanced[] = []) => {
          return oldData.map(item =>
            item.id === id
              ? {
                  ...item,
                  // isConfirmed: true, This flag has been deprecated.
                }
              : item
          )
        })
      },
    }
  )

  const dataWithConfirm = React.useMemo(
    () =>
      data.map(({ name, species, createdAt, identifier, birthDate, owner, ...item }) => ({
        ...item,
        name: <Link to={`/vetecard-requests/${item.id}`}>{name}</Link>,
        species: t(`species.${species}`),
        createdAt: formatDate(createdAt),
        identification: (
          <>
            {t(`identifierLocation.${identifier.location}`)}{' '}
            {t(`identifierType.${identifier.type}`)}: {identifier.number}
            <br />
            {t('birthDate')}: {formatDate(birthDate)}
          </>
        ),
        owner: (
          <Link to={`/user-profile/${owner.keycloakId}`}>
            {formatName(owner.firstName, owner.lastName)}
          </Link>
        ),
        // confirmation:
        //   !isConfirmed && isVeterinarian ? (
        //     <Button onClick={() => mutate(id)} variant="dark" disabled={isLoading}>
        //       {t('confirm')}
        //     </Button>
        //   ) : (
        //     t('unpaid')
        //   ),
      })),
    [data, t]
  )

  return (
    <Content wide={isVeterinarian}>
      <Title>{t('vetecardRequests')}</Title>
      <Panel>
        <Loader loading={isFetching} error={isError}>
          <Table
            labels={[
              { name: t('name'), field: 'name' },
              { name: t('speciesLabel'), field: 'species' },
              { name: t('requestedOn'), field: 'createdAt' },
              { name: t('identification'), field: 'identification' },
              { name: t('owner'), field: 'owner' },
              // { name: t('status'), field: 'confirmation' },
            ]}
            data={dataWithConfirm}
          />
        </Loader>
      </Panel>
    </Content>
  )
}

export default VetecardRequests
