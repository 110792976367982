import React from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { archiveMainVeterinarian, getMainVeterinarians } from '../api/formatted/vetecard'
import AddButton from '../components/AddButton'
import Loader from '../components/Loader'
import Modal, { type ModalRef } from '../components/Modal'
import VeterinarianCard from '../components/VeterinarianCard'
import FormAssignVeterinarian from '../forms/FormAssignVeterinarian'
import { type VetecardBasic, type VeterinarianBasic } from '../typings'
import queryClient from '../utils/queryClient'
import useAuth from '../utils/useAuth'

interface MainVeterinarianContainerProps {
  vetecardId: VetecardBasic['id']
}

const MainVeterinarianContainer = ({ vetecardId }: MainVeterinarianContainerProps) => {
  const modalRef = React.useRef<ModalRef>(null)
  const { t } = useTranslation()
  const { hasPermission } = useAuth()
  const canManageMainVeterinarians = hasPermission('vetecards-manage_main_veterinarian')

  const { data, isFetching, isError } = useQuery({
    queryKey: ['vetecard', vetecardId, 'referring-vets'],
    queryFn: () => getMainVeterinarians(vetecardId),
  })

  const { mutate } = useMutation(
    (veterinarianId: VeterinarianBasic['veterinarianId']) =>
      archiveMainVeterinarian(veterinarianId, vetecardId),
    {
      onSuccess: (veterinarianId: VeterinarianBasic['veterinarianId']) => {
        queryClient.setQueryData(
          ['vetecard', vetecardId, 'referring-vets'],
          (oldData?: VeterinarianBasic[]) =>
            oldData && oldData.filter(item => item.veterinarianId !== veterinarianId)
        )
        toast.success(t('mainVeterinarianArchived'))
      },
    }
  )

  return (
    <Loader loading={isFetching} error={isError}>
      {!!data?.length &&
        data.map(item => (
          <VeterinarianCard
            key={item.veterinarianId}
            {...item}
            onDelete={canManageMainVeterinarians ? () => mutate(item.veterinarianId || 0) : undefined }
          />
        ))}
      {canManageMainVeterinarians ?
        <>
          <AddButton variant="ghost" size="xs" padded onClick={() => modalRef.current?.open()}>
            {t('addVeterinarian')}
          </AddButton>
          <Modal title={t('addMainVeterinarian')} ref={modalRef}>
            <FormAssignVeterinarian
              vetecardId={vetecardId}
              onSuccess={() => modalRef.current?.close()}
            />
          </Modal>
        </> :
        <></>
      }
    </Loader>
  )
}

export default MainVeterinarianContainer
