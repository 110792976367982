import React from 'react'
import { useTranslation } from 'react-i18next'
import Content from '../components/Content'
import Panel from '../components/Panel'
import Title from '../components/Title'
import FormEditPersonalInfo from '../forms/FormEditPersonalInfo'

const EditPersonalInfo = () => {
  const { t } = useTranslation()
  return (
    <Content>
      <Title>{t('editPersonalInfo')}</Title>
      <Panel>
        <FormEditPersonalInfo />
      </Panel>
    </Content>
  )
}

export default EditPersonalInfo
