import * as t from 'io-ts'


export interface IBetweenLengthString extends String {
  readonly betweenLengthString: unique symbol
}

export const betweenLengthString = (min: number, max: number) => t.brand(
  t.string,
  (value: string): value is t.Branded<string, IBetweenLengthString> => value.length <= max && value.length >= min,
  'betweenLengthString'
)
