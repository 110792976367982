import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { type Announcement } from '../../typings'
import formatDate from '../../utils/formatDate'
import Button from '../Button'
import EditLink from '../EditLink'
import Tags from '../Tags'
import './style.sass'

interface AnnouncementCardProps extends Announcement {
  onEdit?: (announcement: Announcement) => void
  onDismiss?: (id: Announcement['id']) => void
}

const AnnouncementCard = ({ onEdit, onDismiss, ...announcement }: AnnouncementCardProps) => {
  const { t } = useTranslation()
  const { id, title, content, author, createdAt, isPublished } = announcement

  return (
    <div
      className={classNames('announcement-card', {
        'announcement-card--unpublished': !isPublished,
      })}
    >
      <div className="announcement-card__text">
        <div className="announcement-card__title">
          {!isPublished && (
            <>
              <Tags small tags={[t('unpublished')]} />{' '}
            </>
          )}
          {title}
        </div>
        <div className="announcement-card__content">{content}</div>
        <div className="announcement-card__footer">
          <div className="announcement-card__meta">
            {author} · {formatDate(createdAt)}
          </div>
          {onEdit ? (
            <EditLink onClick={() => onEdit(announcement)}>{t('edit')}</EditLink>
          ) : (
            onDismiss && (
              <Button size="xs" variant="link" onClick={() => onDismiss && onDismiss(id)}>
                {t('dismiss')}
              </Button>
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default AnnouncementCard
