import React from 'react'
import { useMutation } from '@tanstack/react-query'
import Form, { Input, Select } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { addEvent } from '../api/formatted/agenda'
import SubmitButton from '../components/SubmitButton'
import { type Event, type EventPayload } from '../typings'
import formatDate from '../utils/formatDate'
import { useTranslatedOptions } from '../utils/helpers'
import queryClient from '../utils/queryClient'

interface FormEventProps {
  date: Date
  onSuccess: () => void
}

const FormEvent = ({ date, onSuccess }: FormEventProps) => {
  const { t } = useTranslation()
  const typeOptions = useTranslatedOptions('selectableEventTypes')
  const { isLoading, mutate } = useMutation((payload: EventPayload) => addEvent(payload), {
    onSuccess: (event: Event) => {
      queryClient.setQueryData(['events'], (oldData: Event[] = []) => [event, ...oldData])
      toast.success(t('success'))
      onSuccess && onSuccess()
    },
  })

  const handleSubmit = (values: EventPayload) => {
    mutate(values)
  }

  return (
    <Form fields={['title', 'date', 'type']} allMandatory>
      <Input name="title" label={t('title')} />
      <Input name="date" label={t('Date')} type="date" initialValue={formatDate(date, 'input')} />
      <Select name="type" label={t('type')} options={typeOptions} initialValue="appointment" />
      <SubmitButton onClick={handleSubmit} loading={isLoading}>
        {t('save')}
      </SubmitButton>
    </Form>
  )
}

export default FormEvent
