import React from 'react'
import { useTranslation } from 'react-i18next'
import Content from '../components/Content'
import VetecardsAdvancedContainer from '../containers/VetecardsAdvancedContainer'
import VetecardsCardsContainer from '../containers/VetecardsCardsContainer'
import Title from '../components/Title'
import useAuth from '../utils/useAuth'

const MyVetecards = () => {
  const { hasPermission } = useAuth()
  const { t } = useTranslation()

  const advancedView = hasPermission('vetecards-advanced')

  return (
    <Content wide={advancedView}>
      <Title>{advancedView ? 'VET e-Cards referring to me' : t('myVetecards')} </Title>
      {advancedView ? <VetecardsAdvancedContainer /> : <VetecardsCardsContainer />}
    </Content>
  )
}

export default MyVetecards
