import React from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { downloadConsent, getConsents } from '../api/formatted/consent'
import AddButton from '../components/AddButton'
import DownloadLink from '../components/DownloadLink'
import Loader from '../components/Loader'
import Modal, { type ModalRef } from '../components/Modal'
import Table from '../components/Table'
import FormUploadConsent from '../forms/FormUploadConsent'
import { type VetecardBasic } from '../typings'
import formatDate from '../utils/formatDate'
import useAuth from '../utils/useAuth'

interface ConsentContainerProps {
  vetecardId: VetecardBasic['id']
}

const ConsentContainer = ({ vetecardId }: ConsentContainerProps) => {
  const { t } = useTranslation()
  const { hasPermission } = useAuth()
  const canAddEngagement = hasPermission('vetecards-add_engagement')

  const modalRef = React.useRef<ModalRef>(null)
  const {
    data = [],
    isFetching,
    isError,
  } = useQuery({
    queryKey: ['vetecard', vetecardId, 'consent'],
    queryFn: () => getConsents(vetecardId),
  })

  const { mutate } = useMutation((name: string) => downloadConsent(vetecardId, name), {
    onMutate: () => toast.info(t('downloadStart')),
    onError: () => toast.error(t('downloadError')),
  })

  const handleDownload = (name: string) => mutate(name)

  return (
    <Loader loading={isFetching} error={isError}>
      <Table
        labels={[
          { name: t('name'), field: 'name' },
          { name: t('documentDate'), field: 'documentDate' },
          { name: t('dateOfSignature'), field: 'signatureDate' },
          { field: 'actions' },
        ]}
        data={data.map(({ name, url, documentDate, signatureDate }) => ({
          // name: <DownloadLink onClick={() => handleDownload(name)}>{name}</DownloadLink>,
          name,
          documentDate: formatDate(documentDate),
          signatureDate: formatDate(signatureDate),
          actions: <DownloadLink url={url} />,
        }))}
        emptyText={t('noResults.title')}
      />
      {canAddEngagement ? (
        <>
          <AddButton padded onClick={() => modalRef.current?.open()}>
            {t('uploadConsent')}
          </AddButton>
          <Modal size="lg" title={t('uploadConsent')} ref={modalRef}>
            <FormUploadConsent
              vetecardId={vetecardId}
              onSuccess={() => modalRef.current?.close()}
            />
          </Modal>
        </>
      ) : (
        <></>
      )}
    </Loader>
  )
}

export default ConsentContainer
