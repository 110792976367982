import React from 'react'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Form, { Input, MultiSelect, Row, type fieldsData } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import SubmitButton from '../components/SubmitButton'
import YearRangeInput from '../components/YearRangeInput/YearRangeInput'
import { type Dictionary, type VetecardsPayload } from '../typings'

interface FormVetecardSearchProps {
  onSubmit: (fields: fieldsData) => void
  onReset: () => void
  values: VetecardsPayload
  speciesOptions: Dictionary
}

const FormVetecardSearch = ({
  onSubmit,
  onReset,
  values,
  speciesOptions,
}: FormVetecardSearchProps) => {
  const { t } = useTranslation()

  return (
    <Form fields={['phrase', 'species', 'birth']}>
      <Row noBottomGutter>
        <Input
          name="phrase"
          label="Keyphrase"
          placeholder="Search by name or ID"
          initialValue={values.phrase}
        />
        <MultiSelect
          name="species"
          label={t('speciesLabel')}
          options={speciesOptions}
          initialValue={values.species}
        />
        <YearRangeInput name="birth" label={t('yearOfBirth.title')} initialValue={values.birth} />
        <div>
          <SubmitButton onClick={onSubmit}>
            {t('filter')} <FontAwesomeIcon icon={faFilter} />
          </SubmitButton>
        </div>
        <SubmitButton variant="link" reset onClick={onReset}>
          {t('reset')}
        </SubmitButton>
      </Row>
    </Form>
  )
}

export default FormVetecardSearch
