import React from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@tanstack/react-query'
import { type value } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { searchIndividuals } from '../api/formatted/search'
import AutocompleteInput from '../components/AutocompleteInput'
import formatName from '../utils/formatName'

interface IndividualSearchInputProps {
  name: string
  label: string
  help?: string
}

const IndividualSearchInput = ({ name, label, help }: IndividualSearchInputProps) => {
  const { t } = useTranslation()
  const [searchPhrase, setSearchPhrase] = React.useState<value>('')

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['individuals-search'],
    queryFn: () => searchIndividuals(searchPhrase),
    enabled: false,
    onError: () => toast.error(t('individualsDbError')),
  })

  const options = React.useMemo(
    () =>
      data
        ? data.map(({ keycloakId, firstName, lastName, email, contact }) => ({
            label: `${formatName(firstName, lastName)} (${email}) - ${contact?.city}`,
            value: keycloakId,
          }))
        : [],
    [data]
  )

  React.useEffect(() => {
    if (searchPhrase) refetch()
  }, [searchPhrase])

  return (
    <AutocompleteInput
      name={name}
      label={label}
      help={help}
      options={options}
      suffix={isFetching ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : undefined}
      onChange={(value: value) => setSearchPhrase(value)}
    />
  )
}

export default IndividualSearchInput
