import React from 'react'
import Form, { Input, Row, Select } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import SubmitButton from '../components/SubmitButton'
import {
  type FileAccess,
  type FileCategory,
  type FileEntity,
  type FileInput,
  type FileType,
} from '../typings'
import formatDate from '../utils/formatDate'
import { useTranslatedOptions } from '../utils/helpers'

interface FormFileProps {
  category: FileCategory
  initialValues?: Partial<FileEntity>
  onSubmit: (values: FormFileValues) => void
  isLoading: boolean
}

export interface FormFileValues {
  file: FileInput
  name: string
  type: FileType
  access: FileAccess
  documentDate: string
  treatmentDate?: string
}

const FormFile = ({ category, initialValues, onSubmit, isLoading }: FormFileProps) => {
  const { t } = useTranslation()
  const [fileName, setFileName] = React.useState('')

  React.useEffect(() => {
    if (initialValues?.name) setFileName(initialValues?.name)
  }, [initialValues])

  const accessLevelOptions = useTranslatedOptions('accessLevel')
  const typeOptions = useTranslatedOptions('fileType')

  const nameArray = React.useMemo(() => fileName.split('.'), [fileName])
  const extension = `${nameArray[nameArray.length - 1]}`
  const name = fileName.replace(`.${extension}`, '')

  const isTreatment = category === 'treatment'
  const hasExtension = !!fileName.length

  const handleSubmit = ({
    file,
    name,
    type,
    access,
    documentDate,
    treatmentDate,
  }: FormFileValues) => {
    onSubmit({
      file,
      name: name ? `${name}.${extension}` : fileName,
      type,
      access,
      documentDate,
      treatmentDate,
    })
  }

  return (
    <Form
      fields={[
        'type',
        'access',
        'documentDate',
        'treatmentDate',
        ...(!initialValues ? ['file'] : []),
        ...(hasExtension ? ['name'] : []),
      ]}
      mandatory={[
        'type',
        'access',
        'documentDate',
        ...(!initialValues ? ['file'] : []),
        ...(isTreatment ? ['treatmentDate'] : []),
      ]}
    >
      {!initialValues && (
        <Input
          name="file"
          label={t('fileToUpload')}
          type="file"
          onChange={(value: File) => {
            setFileName(value.name)
          }}
        />
      )}
      {hasExtension && (
        <Input
          name="name"
          label={t('fileName')}
          suffix={`.${extension}`}
          placeholder={name}
          help={!initialValues && t('fileNameOverride')}
          initialValue={initialValues?.name && name}
        />
      )}
      <Row>
        <Select
          name="type"
          label={t('type')}
          options={typeOptions}
          initialValue={initialValues?.type}
        />
        <Select
          name="access"
          label={t('accessLevelLabel')}
          options={accessLevelOptions}
          initialValue={initialValues?.access}
        />
      </Row>
      <Row>
        <Input
          name="documentDate"
          label={t('documentDate')}
          type="date"
          initialValue={
            initialValues?.documentDate && formatDate(initialValues.documentDate, 'input')
          }
        />
        <Input
          name="treatmentDate"
          label={t('treatmentDate')}
          type="date"
          initialValue={
            initialValues?.treatmentDate && formatDate(initialValues.treatmentDate, 'input')
          }
        />
      </Row>
      <SubmitButton onClick={handleSubmit} loading={isLoading}>
        {t('save')}
      </SubmitButton>
    </Form>
  )
}

export default FormFile
