import React from 'react'
import classNames from 'classnames'
import formatName from '../../utils/formatName'
import Avatar from '../Avatar'
import './style.sass'

interface UserProps {
  firstName?: string
  lastName?: string
  secondary?: React.ReactNode
  big?: boolean
  disabled?: boolean
  onClick?: () => void
  className?: string
}

const User = ({ firstName, lastName, secondary, big, disabled, onClick, className }: UserProps) => {
  const name = formatName(firstName, lastName)
  return (
    <div
      className={classNames('user', className, { 'user--big': big, 'user--disabled': disabled })}
      onClick={onClick}
    >
      <Avatar size={big ? 'lg' : 'sm'} name={name} className="user__avatar" />
      <div className="user__name">{name}</div>
      {secondary && <div className="user__secondary">{secondary}</div>}
    </div>
  )
}

export default User
