import React from 'react'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { expireCheckoutSession } from '../api/formatted/payment'
import Button from '../components/Button'
import Content from '../components/Content'
import Spinner from '../components/Spinner'
import Text from '../components/Text'
import Title from '../components/Title'
import useAuth from '../utils/useAuth'
import useLocalStorage from '../utils/useLocalStorage'

const PaymentCancel = () => {
  const { t } = useTranslation()
  const { currentUser } = useAuth()
  const [paymentActive, setPaymentActive] = useLocalStorage<boolean>('paymentActive', false)

  const { mutate, isLoading, isSuccess } = useMutation(() => expireCheckoutSession(currentUser), {
    onSuccess: () => {
      if (paymentActive) setPaymentActive(false)
    },
  })

  React.useEffect(() => {
    mutate()
  }, [])

  return (
    <Content>
      <Title>{t('payment.cancelled')}</Title>
      <Text>
        {isLoading ? (
          <>
            <Spinner variant="small" /> {t('payment.cancellingLoading')}
          </>
        ) : isSuccess ? (
          t('payment.cancelledDescr')
        ) : (
          t('payment.cancellingError')
        )}
      </Text>
      <Button component={Link} to="/payment" disabled={isLoading}>
        {t('payment.goBack')}
      </Button>
    </Content>
  )
}

export default PaymentCancel
