import React from 'react'
import { type IconProp } from '@fortawesome/fontawesome-svg-core'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from '../IconButton'
import './style.sass'

interface DownloadLinkProps {
  children?: React.ReactNode
  url?: string
  onClick?: () => void
  icon?: IconProp
}

const DownloadLink = ({ children, url, onClick, icon = faDownload }: DownloadLinkProps) => {
  const props = {
    ...(url ? { component: 'a', href: url } : {}),
    onClick,
  }
  return (
    <IconButton className="download-link" {...props}>
      {children} <FontAwesomeIcon icon={icon} />
    </IconButton>
  )
}

export default DownloadLink
