import api from '../.'
import { type FoundVeterinarian, type UserBasic, type VetecardBasic } from '../../typings'
import { type V0EmployedVeterinarian } from '../pure/employed_veterinarian'

// This file should contain only mocked functions. All working api calls shoud
// go to a dedicated file inside this dir.

export const ping = async (): Promise<string> => {
  const { data } = await api.get('ping')
  return data as string
}

export const foundVeterinarian = async (rpps: string): Promise<FoundVeterinarian> => {
  const { data } = (await api.get(`/0/veterinarian/preview/${rpps}`)) as {
    data: V0EmployedVeterinarian
  }
  return {
    ...data,
    id: typeof data.id === 'number' ? data.id.toString() : data.id,
  }
}

export const assignOwnership = async (
  vetecardId: VetecardBasic['id'],
  user?: UserBasic
): Promise<void> => {
  console.log('user: ', user)
  console.log('vetecardId: ', vetecardId)
  await new Promise(resolve => setTimeout(resolve, 1000))
  return
}
