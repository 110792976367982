import React from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { archiveEmailFile, deleteEmailFile, getEmailFiles } from '../api/formatted/inbox'
import Button from '../components/Button'
import DropdownButton from '../components/DropdownButton'
import Loader from '../components/Loader'
import Spinner from '../components/Spinner'
import Table from '../components/Table'
import Tags from '../components/Tags'
import { type InboxDocument } from '../typings'
import formatDate from '../utils/formatDate'
import queryClient from '../utils/queryClient'
import useAuth from '../utils/useAuth'

interface DocumentsContainerProps {
  archive?: boolean
  onValidate: (item: InboxDocument) => void
}

const DocumentsContainer = ({ archive, onValidate }: DocumentsContainerProps) => {
  const { t } = useTranslation()
  const [loadingItem, setLoadingItem] = React.useState<InboxDocument['id'] | undefined>()

  const { currentUser } = useAuth()
  const {
    data = [],
    isFetching,
    isError,
  } = useQuery(['document-inbox', archive ? 'archive' : 'new'], () =>
    getEmailFiles(currentUser, archive)
  )

  const handleError = () => {
    setLoadingItem(undefined)
    toast.error(t('dataError.title'))
  }

  const { mutate: archiveMutate } = useMutation(
    (document: InboxDocument) => archiveEmailFile(currentUser, document),
    {
      onSuccess: (document: InboxDocument) => {
        queryClient.setQueryData(
          ['document-inbox', 'new'],
          (oldData?: InboxDocument[]) => oldData && oldData.filter(item => item.id !== document.id)
        )
        queryClient.setQueryData(
          ['document-inbox', 'archive'],
          (oldData?: InboxDocument[]) => oldData && [document, ...oldData]
        )
        toast.success(t('documentArchived'))
      },
      onError: handleError,
    }
  )

  const { mutate: deleteMutate } = useMutation(
    (document: InboxDocument) => deleteEmailFile(currentUser, document),
    {
      onSuccess: (document: InboxDocument) => {
        queryClient.setQueryData(
          ['document-inbox', 'new'],
          (oldData?: InboxDocument[]) => oldData && oldData.filter(item => item.id !== document.id)
        )
        toast.success(t('success'))
      },
      onError: handleError,
    }
  )

  const handleArchive = (document: InboxDocument) => {
    setLoadingItem(document.id)
    archiveMutate(document)
  }

  const handleDelete = (document: InboxDocument) => {
    setLoadingItem(document.id)
    deleteMutate(document)
  }

  const formattedData = React.useMemo(
    () =>
      data.map(item => {
        const { id, name, date, message, vetecard, isIntegrated } = item
        return {
          id,
          name,
          email: message.email,
          date: formatDate(date),
          vetecard: (
            <>
              <Link to={`/vetecards/${vetecard.id}`}>{vetecard.name}</Link>{' '}
              {isIntegrated && <Tags small tags={[t('fileIntegratedShort')]} />}
            </>
          ),
          actions: archive ? (
            <Button size="sm" onClick={() => handleDelete(item)}>
              {t('delete')}
            </Button>
          ) : loadingItem === id ? (
            <Spinner />
          ) : (
            <DropdownButton
              size="sm"
              onClick={() => onValidate(item)}
              actions={[
                {
                  label: t('archive'),
                  onClick: () => handleArchive(item),
                },
                {
                  label: t('delete'),
                  onClick: () => handleDelete(item),
                },
              ]}
            >
              {t('validate')}
            </DropdownButton>
          ),
        }
      }),
    [data, loadingItem]
  )

  return (
    <Loader loading={isFetching} error={isError}>
      <Table
        labels={[
          { name: t('fileName'), field: 'name' },
          { name: t('from'), field: 'email' },
          { name: t('date'), field: 'date' },
          { name: t('vetecard'), field: 'vetecard' },
          { field: 'actions' },
        ]}
        data={formattedData}
        toRight
        emptyText={t('inboxEmpty')}
        bottomMargin
      />
    </Loader>
  )
}

export default DocumentsContainer
