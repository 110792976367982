import api from '..'
import { type VeterinarianFull } from '../../typings'
import toTitleCase from '../../utils/toTitleCase'
import { type EmployedVeterinarian } from '../pure/employed_veterinarian'

export const getFullVeterinarian = async (
  veterinarianId: VeterinarianFull['veterinarianId']
): Promise<VeterinarianFull> => {
  const { data } = await api.get(`/1/veterinarian/preview/${veterinarianId}`)
  const { firstName, lastName /*, professionalCode, profession*/, institutions } =
    data as EmployedVeterinarian

  return {
    veterinarianId,
    firstName: toTitleCase(firstName),
    lastName: toTitleCase(lastName),
    /*
    profession: {
      politeness: 'mr',
      field: 'W1',
      category: 'W1',
      specialization: ['W1', 'W2'],
    },
    */
    institutions: institutions
      ? institutions.map(
          ({
            id,
            name /*, type */,
            phoneNumber1,
            phoneNumber2,
            code,
            address,
            city,
            country,
            postalCode,
          }) => ({
            id: id.toString(),
            name,
            internalIdentifier: code,
            contact: {
              phone: phoneNumber1 || undefined,
              fax: phoneNumber2 || undefined,
              country: country || undefined,
              city: city === null ? undefined : toTitleCase(city),
              address: address === null ? undefined : toTitleCase(address),
              postalCode: postalCode || undefined,
            },
          })
        )
      : undefined,
  }
}
