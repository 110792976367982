import * as t from 'io-ts'
import { nullable } from 'io-ts/Type'
import { DateFromISOString } from 'io-ts-types'


export const minioBucketVetecardInvoices = process.env.MINIO_BUCKET_VETECARD_INVOICES as string

if (typeof minioBucketVetecardInvoices  !== 'string') {
  throw new Error('Missing "MINIO_BUCKET_VETECARD_INVOICES" environment variable')
}


export const InvoiceType = t.union([
  t.literal('document'),
  t.literal('other'),
  t.literal('photo'),
  t.literal('radiologic'),
  t.literal('report'),
  t.literal('video'),
])
export type InvoiceTypeT = t.TypeOf<typeof InvoiceType>

export const InvoiceAccess = t.union([
  t.literal('confidental'),
  t.literal('public'),
  t.literal('shareable'),
])
export type InvoiceAccessT = t.TypeOf<typeof InvoiceAccess>

export const InvoiceKey = t.type({
  id: t.number,
})
export type InvoiceKeyT = t.TypeOf<typeof InvoiceKey>

export const Invoice = t.type({
  storageKey: t.string,
  type: nullable(InvoiceType),
  access: nullable(InvoiceAccess),
  documentDate: nullable(DateFromISOString),
  treatmentDate: nullable(DateFromISOString),
})
export type InvoiceT = t.TypeOf<typeof Invoice>

export const InvoiceWithKey = t.intersection([
  InvoiceKey,
  Invoice,
])
export type InvoiceWithKeyT = t.TypeOf<typeof InvoiceWithKey>
