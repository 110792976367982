import React from 'react'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import copy from 'copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import FormUploadPhoto from '../../forms/FormUploadPhoto'
import { type RequestStatus, type Species, type VetecardBasic } from '../../typings'
import useAuth from '../../utils/useAuth'
import Modal, { type ModalRef } from '../Modal'
import Picture from '../Picture'
import Tooltip from '../Tooltip'
import './style.sass'

interface VetecardHeadProps {
  vetecardId: VetecardBasic['id']
  name: string
  email?: string
  species?: Species
  photo?: string
  isRequest?: boolean
  requestStatus?: RequestStatus
}

const VetecardHead = ({
  vetecardId,
  name,
  email = '',
  species,
  photo,
  isRequest,
  requestStatus,
}: VetecardHeadProps) => {
  const { t } = useTranslation()
  const modalRef = React.useRef<ModalRef>(null)
  const { hasPermission } = useAuth()
  const canSetPhoto = hasPermission('vetecards-set_photo')

  const handleCopyEmail = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    copy(email)
    toast(t('emailCopied'))
  }

  return (
    <div className="vetecard-head">
      <Picture
        url={photo}
        species={species}
        standalone
        className="vetecard-head__graphic"
        onEdit={canSetPhoto ? () => modalRef.current?.open() : undefined}
        isRequest={isRequest}
        requestStatus={requestStatus}
      />
      <div className="vetecard-head__info">
        <div className="vetecard-head__name">{name}</div>
        {email && (
          <Tooltip message={t('copyToClipboard')} direction="br">
            <button onClick={handleCopyEmail} className="vetecard-head__email">
              {email} <FontAwesomeIcon icon={faCopy} />
            </button>
          </Tooltip>
        )}
      </div>
      <Modal size="sm" title={t('uploadNewPhoto')} ref={modalRef}>
        <FormUploadPhoto vetecardId={vetecardId} onSuccess={() => modalRef.current?.close()} />
      </Modal>
    </div>
  )
}

export default VetecardHead
