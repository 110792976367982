import React from 'react'
import { useTranslation } from 'react-i18next'
import FormEditDescription from '../forms/FormEditDescription'
import FormEditMarking from '../forms/FormEditMarking'
import FormEditPassport from '../forms/FormEditPassport'
import { type VetecardFull } from '../typings'
import formatDate from '../utils/formatDate'
import { useTranslatedEnums } from '../utils/helpers'
import Block from './Block'
import EditLink from './EditLink'
import Field from './Field'
import Grid from './Grid'
import Modal, { type ModalRef } from './Modal'

interface IdentificationProps {
  ident: VetecardFull
  canEdit?: boolean
}

const Identification = ({ ident, canEdit }: IdentificationProps) => {
  const { t } = useTranslation()
  const descriptionModalRef = React.useRef<ModalRef>(null)
  const markingModalRef = React.useRef<ModalRef>(null)
  const passportModalRef = React.useRef<ModalRef>(null)
  const breedOptions = useTranslatedEnums(ident.species, 'breeds')
  const coatingColourOptions = useTranslatedEnums(ident.species, 'coatingColors')

  return (
    <Grid>
      <Block condensed noMargin title={t('card')}>
        <Field inlineLabel label={t('cardId')}>
          {ident.id}
        </Field>
        <Field inlineLabel label={t('animalName')}>
          {ident.name}
        </Field>
        <Field inlineLabel label={t('createdAt')}>
          {formatDate(ident.createdAt)}
        </Field>
        {ident.modifiedAt && (
          <Field inlineLabel label={t('modifiedAt')}>
            {formatDate(ident.modifiedAt)}
          </Field>
        )}
      </Block>
      <Block
        condensed
        noMargin
        title={t('description')}
        right={
          canEdit ? (
            <EditLink onClick={() => descriptionModalRef.current?.open()}>{t('edit')}</EditLink>
          ) : (
            <></>
          )
        }
      >
        <Field inlineLabel label={t('speciesLabel')}>
          {t(`species.${ident.species}`)}
        </Field>
        <Field inlineLabel label={t('breedLabel')}>
          {ident.breed ? (breedOptions && breedOptions[ident.breed]) || ident.breed : 'unspecified'}
        </Field>
        <Field inlineLabel label={t('sexLabel')}>
          {ident.sex ? t(`sex.${ident.sex}`) : '-'}
        </Field>
        <Field inlineLabel label={t('birthDate')}>
          {formatDate(ident.birthDate)}
        </Field>
        <Field inlineLabel label={t('colour')}>
          {ident.coatingColour
            ? coatingColourOptions[ident.coatingColour] || ident.coatingColour
            : '-'}
        </Field>
      </Block>
      <Block
        condensed
        noMargin
        title={t('marking')}
        right={
          canEdit ? (
            <EditLink onClick={() => markingModalRef.current?.open()}>{t('edit')}</EditLink>
          ) : (
            <></>
          )
        }
      >
        <Field inlineLabel label={t('identifierTypeLabel')}>
          {t(`identifierType.${ident.identifier.type}`)}
        </Field>
        {ident.identifier.type !== 'none' && (
          <>
            <Field
              inlineLabel
              label={`${t(`identifierType.${ident.identifier.type}`)} ${t('number')}`}
            >
              {ident.identifier.number}
            </Field>
            <Field inlineLabel label={t('implementationDate')}>
              {ident.identifier.date ? formatDate(ident.identifier.date) : '-'}
            </Field>
            <Field inlineLabel label={t('identifierLocationLabel')}>
              {t(`identifierLocation.${ident.identifier.location}`)}
            </Field>
          </>
        )}
      </Block>
      <Block
        condensed
        noMargin
        title={t('passport')}
        right={
          canEdit ? (
            <EditLink onClick={() => passportModalRef.current?.open()}>{t('edit')}</EditLink>
          ) : (
            <></>
          )
        }
      >
        {ident.passport ? (
          <>
            <Field inlineLabel label={t('passportNumber')}>
              {ident.passport.number}
            </Field>
            <Field inlineLabel label={t('deliveryDate')}>
              {formatDate(ident.passport.deliveryDate)}
            </Field>
            <Field inlineLabel label={t('expirationDate')}>
              {formatDate(ident.passport.expirationDate)}
            </Field>
          </>
        ) : (
          '-'
        )}

        <Modal size="lg" title={t('editDescription')} ref={descriptionModalRef}>
          <FormEditDescription
            ident={ident}
            onSuccess={() => descriptionModalRef.current?.close()}
          />
        </Modal>
        <Modal size="lg" title={t('editMarking')} ref={markingModalRef}>
          <FormEditMarking ident={ident} onSuccess={() => markingModalRef.current?.close()} />
        </Modal>
        <Modal size="lg" title={t('editPassport')} ref={passportModalRef}>
          <FormEditPassport ident={ident} onSuccess={() => passportModalRef.current?.close()} />
        </Modal>
      </Block>
    </Grid>
  )
}

export default Identification
