import React from 'react'
import useAuth from './useAuth'

interface RequireAuthProps {
  children: React.ReactNode
}

const RequireAuth = ({ children }: RequireAuthProps) => {
  const { authenticated, login } = useAuth()

  if (authenticated) return <>{children}</>

  login()
  return null
}

export default RequireAuth
