import api from '../.'
import {
  type UploadVacciationPayload,
  type UserBasic,
  type Vaccination,
  type VaccinationCategory,
  type VetecardBasic,
} from '../../typings'
import { type ListBreederVetecardRequestMandatoryVaccinationsResponseT } from '../pure/breeder/vetecard_request/mandatory_vaccination/list'
import { type V1EmployedVeterinarianT } from '../pure/employed_veterinarian'
import { type InsertMandatoryVaccinationApiResponseT } from '../pure/owner/vetecard/mandatory_vaccination/insert'
import { type ListMandatoryVaccinationsResponseT } from '../pure/owner/vetecard/mandatory_vaccination/list'
import { type InsertOtherVaccinationApiResponseT } from '../pure/owner/vetecard/other_vaccination/insert'
import { type ListOtherVaccinationsResponseT } from '../pure/owner/vetecard/other_vaccination/list'
import { getMinioClient, getObject, getPresignedUrl, putObject } from './storage'

export const getVaccinations = async (
  vetecardId: VetecardBasic['id'],
  category: VaccinationCategory,
  { userType, keycloakId }: UserBasic,
  isRequest?: boolean
): Promise<Vaccination[]> => {
  const storage = await getMinioClient()
  let apiResponse
  let bucketName = ''

  switch (category) {
    case 'mandatory':
      bucketName = process.env.MINIO_BUCKET_VETECARD_MANDATORY_VACCINATIONS as string
      if (isRequest) {
        switch (userType) {
          case 'breeder':
          case 'veterinarian': {
            // TODO: Other user roles? What about the other user types?
            const response = (await api.get(
              `/0/${userType}/${keycloakId}/vetecard_request/${vetecardId}/mandatory_vaccination`
            )) as { data: ListBreederVetecardRequestMandatoryVaccinationsResponseT }
            apiResponse = response.data
            break
          }

          default:
            throw new Error(`Unsupported owner type "${userType}"`)
        }
      } else {
        const response = (await api.get(
          `/0/${userType}/${keycloakId}/vetecard/${vetecardId}/mandatory_vaccination`
        )) as { data: ListMandatoryVaccinationsResponseT }
        apiResponse = response.data
      }
      break

    case 'other':
      bucketName = process.env.MINIO_BUCKET_VETECARD_OTHER_VACCINATIONS as string
      console.log('bucketName: ', bucketName)
      if (isRequest) {
        const response = (await api.get(
          `/0/${userType}/${keycloakId}/vetecard_request/${vetecardId}/other_vaccination`
        )) as { data: ListOtherVaccinationsResponseT }
        apiResponse = response.data
      } else {
        const response = (await api.get(
          `/0/${userType}/${keycloakId}/vetecard/${vetecardId}/other_vaccination`
        )) as { data: ListOtherVaccinationsResponseT }
        apiResponse = response.data
      }
      break

    default:
      throw new Error(`Unhandled vaccination category "${category}"`)
  }

  const presignedVaccinations = await Promise.all(
    apiResponse.map(async item => ({
      ...item,
      url: await getPresignedUrl(storage, vetecardId, bucketName, item.storageKey || '', isRequest),
    }))
  )

  return await Promise.all(
    presignedVaccinations.map(
      async ({
        id,
        signedBy,
        storageKey,
        url,
        type,
        manufacturer,
        batchNumber,
        vaccinationDate,
        validFrom,
        validTo,
      }) => {
        let veterinarian
        if (signedBy) {
          const {
            data: { firstName, lastName },
          } = (await api.get(`/1/veterinarian/preview/${signedBy}`)) as {
            data: V1EmployedVeterinarianT
          }
          veterinarian = {
            veterinarianId: signedBy,
            firstName,
            lastName,
          }
        }
        return {
          id: id || undefined,
          type: type || '',
          manufacturer: manufacturer || '',
          batchNumber: batchNumber || '',
          vaccinationDate: vaccinationDate ? new Date(vaccinationDate) : undefined,
          validFrom: validFrom ? new Date(validFrom) : undefined,
          validTo: validTo ? new Date(validTo) : undefined,
          name: storageKey || '',
          url: url || '',
          signedBy: veterinarian,
          category,
        }
      }
    )
  )
}

export const uploadVaccination = async (
  { userType, keycloakId }: UserBasic,
  payload: UploadVacciationPayload
): Promise<Vaccination> => {
  const {
    file,
    type,
    manufacturer,
    batchNumber,
    vaccinationDate,
    validFrom,
    validTo,
    signedBy,
    category,
    vetecardId,
    renewId,
  } = payload
  // TODO: WCARD-557 Add backend support for renew attribute.
  const storage = await getMinioClient()
  let id
  let bucketName = ''

  const body = {
    storageKey: file?.name || null,
    type,
    manufacturer,
    batchNumber,
    vaccinationDate: vaccinationDate ? new Date(vaccinationDate) : undefined,
    validFrom: validFrom ? new Date(validFrom) : undefined,
    validTo: validTo ? new Date(validTo) : undefined,
    signedBy,
  }

  switch (userType) {
    case 'individual':
    case 'association':
    case 'breeder':
    case 'veterinarian': {
      switch (category) {
        case 'mandatory': {
          bucketName = process.env.MINIO_BUCKET_VETECARD_MANDATORY_VACCINATIONS as string
          const response = (await api.post(
            `/0/${userType}/${keycloakId}/vetecard/${vetecardId}/mandatory_vaccination`,
            body
          )) as { data: InsertMandatoryVaccinationApiResponseT }
          id = response.data.mandatoryVaccination.id
          break
        }

        case 'other': {
          bucketName = process.env.MINIO_BUCKET_VETECARD_OTHER_VACCINATIONS as string
          const response = (await api.post(
            `/0/${userType}/${keycloakId}/vetecard/${vetecardId}/other_vaccination`,
            body
          )) as { data: InsertOtherVaccinationApiResponseT }
          id = response.data.otherVaccination.id
          break
        }

        default:
          throw new Error(`Unhandled vaccination category "${category}"`)
      }
      break
    }

    default:
      throw new Error(`Unsupported owner type "${userType}"`)
  }

  if (file) {
    const buffer = Buffer.from(await file.arrayBuffer())
    await putObject(storage, vetecardId, bucketName, file.name, buffer)
  }

  const {
    data: { firstName, lastName },
  } = (await api.get(`/1/veterinarian/preview/${signedBy}`)) as {
    data: V1EmployedVeterinarianT
  }

  return {
    id,
    type,
    manufacturer,
    batchNumber,
    vaccinationDate: vaccinationDate ? new Date(vaccinationDate) : undefined,
    validFrom: validFrom ? new Date(validFrom) : undefined,
    validTo: validTo ? new Date(validTo) : undefined,
    ...(file
      ? {
          name: file.name,
          url: await getPresignedUrl(storage, vetecardId, bucketName, file.name),
        }
      : {}),
    signedBy: {
      veterinarianId: signedBy,
      firstName: firstName,
      lastName: lastName,
    },
    category,
  }
}

export const downloadVaccination = async (
  { userType }: UserBasic,
  category: VaccinationCategory,
  vetecardId: VetecardBasic['id'],
  name: string
): Promise<void> => {
  const storage = await getMinioClient()
  let bucketName = ''

  switch (userType) {
    case 'individual':
    case 'association':
    case 'breeder': {
      switch (category) {
        case 'mandatory': {
          bucketName = process.env.MINIO_BUCKET_VETECARD_MANDATORY_VACCINATIONS as string
          break
        }
        case 'other':
          bucketName = process.env.MINIO_BUCKET_VETECARD_OTHER_VACCINATIONS as string
          break
        default:
          throw new Error(`Unhandled vaccination category "${category}"`)
      }

      const response = await getObject(storage, vetecardId, bucketName, name)
      console.log('response: ', response)
      // TODO: Trigger a file download here.
      // await window.showSaveFilePicker({})
      return
    }
    default:
      throw new Error(`Unsupported owner type "${category}"`)
  }
}
