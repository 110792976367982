import React from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@tanstack/react-query'
import { type value } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { searchVeterinaries } from '../api/formatted/search'
import AutocompleteInput from '../components/AutocompleteInput'
import { type VeterinarianBasic } from '../typings'
import formatName from '../utils/formatName'

interface VeterinarianSearchInputProps {
  name: string
  label?: string
  help?: string
  placeholder?: string
  initialPhrase?: string
  featuredOptions?: VeterinarianBasic[]
}

const VeterinarianSearchInput = ({
  name,
  label,
  help,
  placeholder,
  initialPhrase = '',
  featuredOptions = [],
}: VeterinarianSearchInputProps) => {
  const { t } = useTranslation()
  const [searchPhrase, setSearchPhrase] = React.useState<value>('')

  const {
    data = [],
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['veterinaries-search'],
    queryFn: () => searchVeterinaries(searchPhrase),
    enabled: false,
    onError: () => toast.error(t('vetDbError')),
  })

  const options = React.useMemo(() => {
    const allSources = [...featuredOptions, ...data]
    const uniqueVets = Array.from(
      new Map(allSources.map(item => [item.veterinarianId, item])).values()
    )
    // Show results from all sources only when veterinaries search is active.
    return data.length
      ? uniqueVets.map(
          ({ veterinarianId, firstName, lastName, institutions }: VeterinarianBasic) => {
            const institutionName = institutions?.length ? institutions[0]?.name : ''
            return {
              label: `${formatName(firstName, lastName)} (${institutionName})`,
              value: veterinarianId,
            }
          }
        )
      : []
  }, [data])

  React.useEffect(() => {
    if (searchPhrase) refetch()
  }, [searchPhrase])

  return (
    <AutocompleteInput
      name={name}
      label={label}
      help={help}
      placeholder={placeholder}
      options={options}
      suffix={isFetching ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : undefined}
      onChange={(value: value) => setSearchPhrase(value)}
      initialPhrase={initialPhrase}
    />
  )
}

export default VeterinarianSearchInput
