import React from 'react'
import { Navigate } from 'react-router-dom'
import useAuth from './useAuth'

interface PermissionRouteProps {
  perm: string
  children: React.ReactNode
}

const PermissionRoute = ({ perm, children }: PermissionRouteProps) => {
  const { hasPermission } = useAuth()

  if (hasPermission(perm)) return <>{children}</>

  // When user is logged in, but access is denied, redirect to 404 page.
  return <Navigate to="/404" />
}

export default PermissionRoute
