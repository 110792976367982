import React from 'react'
import ErrorPage from '../components/ErrorPage'
import { useTranslation } from 'react-i18next'

const PageNotFound = () => {
  const { t } = useTranslation()
  return <ErrorPage title={t('error404.title')} description={t('error404.description')} />
}

export default PageNotFound
