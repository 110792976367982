import React from 'react'
import classNames from 'classnames'
import './style.sass'

interface TabsProps {
  labels: React.ReactNode[]
  children: React.ReactNode[]
}

const Tabs = ({ labels, children }: TabsProps) => {
  const [active, setActive] = React.useState(0)

  return (
    <div className="tabs" data-active-tab={active}>
      <div className="tabs__btns">
        {labels.map((label, index) => (
          <div
            className={classNames('tabs__btn', { 'is-active': index === active })}
            onClick={() => setActive(index)}
            key={index}
          >
            {label}
          </div>
        ))}
      </div>
      <div className="tabs__content is-open">{children[active]}</div>
    </div>
  )
}

export default Tabs
