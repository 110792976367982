import React from 'react'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Panel from './Panel'

type AccordionContextType = [string[], (index: string) => void]

const AccordionContext = React.createContext<AccordionContextType>([[], () => {}])

interface AccordionProps {
  multiple?: boolean
  onToggle?: (openedItems: string[]) => void
  children: React.ReactNode
}

const Accordion = ({ multiple, onToggle, children }: AccordionProps) => {
  const [openedItems, setOpenedItems] = React.useState<string[]>([])

  const handleToggle = (index: string) => {
    if (openedItems.includes(index)) {
      setOpenedItems(openedItems.filter(item => item !== index))
    } else {
      setOpenedItems(prevState => (multiple ? [...prevState, index] : [index]))
    }
  }

  React.useEffect(() => {
    onToggle && onToggle(openedItems)
  }, [openedItems])

  return (
    <AccordionContext.Provider value={[openedItems, handleToggle]}>
      <div>{children}</div>
    </AccordionContext.Provider>
  )
}

export default Accordion

interface AccordionItemProps {
  title: string
  className?: string
  defaultOpen?: boolean
  noPadding?: boolean
  scrollIntoView?: boolean
  children: React.ReactNode
}

export const AccordionItem = ({
  title,
  className,
  defaultOpen,
  noPadding,
  scrollIntoView,
  children,
}: AccordionItemProps) => {
  const [openedItems, handleToggle] = React.useContext(AccordionContext)
  const index = title // Use title as a unique index.
  const isOpen = openedItems.includes(index)

  React.useEffect(() => {
    if (defaultOpen || scrollIntoView) handleToggle(index)
  }, [defaultOpen])

  return (
    <Panel
      title={
        <>
          <span>{title}</span>
          <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
        </>
      }
      onTitleClick={() => handleToggle(index)}
      noPadding={noPadding}
      scrollIntoView={scrollIntoView}
      className={className}
    >
      {isOpen && children}
    </Panel>
  )
}
