import api from '..'
import { type UserBasic, type VetecardAdvanced } from '../../typings'
import { getVetecardRequests } from './vetecardRequests'

export const createCheckoutSession = async (
  vetecardRequests: VetecardAdvanced[],
  phrase?: string,
  ambassador?: string // keycloakID
): Promise<void> => {
  console.log('ambassador: ', ambassador)
  // TODO: Stack call to another endpoin that saves the ambassador's keycloakID?
  const { data } = await api.post(`/0/vetecard/request/payment/intent`, {
    onSucceeded: `${process.env.PUBLIC_WEBSITE_DASHBOARD_URL}/payment/success`,
    onCancelled: `${process.env.PUBLIC_WEBSITE_DASHBOARD_URL}/payment/cancel`,
    requests: vetecardRequests.map(({ id }) => id),
    discount: typeof phrase === 'undefined' ? null : { phrase },
  })
  window.location.href = data //FIXME: the right place for it
  return
}

export const expireCheckoutSession = async (user: UserBasic): Promise<string | void> => {
  const vetecardRequests = await getVetecardRequests(user)
  const unpaidRequests = vetecardRequests.filter(item => item.requestStatus === 'payment')
  if (unpaidRequests.length) {
    return await api.post(
      `/0/vetecard/request/payment/intent/expire`,
      unpaidRequests.map(({ id }) => id)
    )
  }
  return
}
