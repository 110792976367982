import React from 'react'
import { useSubmit } from 'react-form-component'
import Button from './Button'
import Spinner from './Spinner'

interface SubmitButtonProps {
  onClick?: (values: any) => void
  reset?: boolean
  loading?: boolean
  suppressErrorMessage?: boolean
  children: React.ReactNode
  [key: string]: any
}

const SubmitButton = ({
  onClick = () => {},
  reset,
  loading,
  suppressErrorMessage,
  children,
  ...otherProps
}: SubmitButtonProps) => {
  const submit = useSubmit(suppressErrorMessage)
  return (
    <Button
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => submit(e, onClick, reset)}
      disabled={loading}
      {...otherProps}
    >
      {children}
      {loading && <Spinner />}
    </Button>
  )
}

export default SubmitButton
