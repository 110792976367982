import React from 'react'
import { useMutation } from '@tanstack/react-query'
import Form, { FormActions, Input, Radio, Row } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { shareWithOther } from '../api/formatted/share'
import SubmitButton from '../components/SubmitButton'
import IndividualSearchInput from '../containers/IndividualSearchInput'
import { type ShareWithOtherPayload, type UserShared, type VetecardBasic } from '../typings'
import { type TimeRangeType } from '../typings'
import { useTranslatedOptions } from '../utils/helpers'
import queryClient from '../utils/queryClient'
import useAuth from '../utils/useAuth'

interface FormShareWithOtherProps {
  vetecardId: VetecardBasic['id']
  onSuccess: () => void
}

const FormShareWithOther = ({ vetecardId, onSuccess }: FormShareWithOtherProps) => {
  const { t } = useTranslation()
  const [method, setMethod] = React.useState<'autocomplete' | 'email' | undefined>(undefined)
  const [timeRangeType, setTimeRangeType] = React.useState<TimeRangeType | undefined>(undefined)
  const { currentUser } = useAuth()
  const timeRangeOptions = useTranslatedOptions('timeRange')

  const { isLoading, mutate } = useMutation(
    (payload: ShareWithOtherPayload) => shareWithOther(currentUser, payload),
    {
      onSuccess: (user: UserShared) => {
        queryClient.setQueryData(
          ['vetecard', vetecardId, 'shared-other'],
          (oldData: UserShared[] = []) => [user, ...oldData]
        )
        toast.success(t('success'))
        onSuccess()
      },
    }
  )

  const handleSubmit = (payload: ShareWithOtherPayload) => {
    mutate({ ...payload, vetecardId })
  }

  const handleTypeChange = (value: string) => {
    switch (value) {
      case 'breeder':
        setMethod('email')
        break
      case 'petsitter':
        setMethod('autocomplete')
        break
    }
  }

  return (
    <Form
      fields={['otherUserType', 'email', 'autocomplete', 'timeRangeType', 'dateFrom', 'dateTo']}
      mandatory={[
        'otherUserType',
        ...(method === 'email' ? ['email'] : method == 'autocomplete' ? ['autocomplete'] : []),
        ...(timeRangeType === 'date_range' || timeRangeType === 'oneyear' ? ['dateFrom'] : []),
        ...(timeRangeType === 'date_range' ? ['dateTo'] : []),
      ]}
    >
      <Radio
        name="otherUserType"
        label={t('type')}
        options={[
          { label: t('userTypes.breeder'), value: 'breeder' },
          { label: t('petsitter'), value: 'petsitter' },
        ]}
        onChange={handleTypeChange}
      />
      {method === 'email' && <Input name="email" type="email" label={t('breederEmail')} />}
      {method === 'autocomplete' && (
        <IndividualSearchInput name="autocomplete" label={t('petsitterFind')} />
      )}
      {method && (
        <>
          <Radio
            name="timeRangeType"
            label={t('timeRangeLabel')}
            options={timeRangeOptions}
            onChange={(type: TimeRangeType) => setTimeRangeType(type)}
          />
          {(timeRangeType === 'date_range' || timeRangeType === 'oneyear') && (
            <Row>
              <Input name="dateFrom" label={t('validFrom')} type="date" />
              {timeRangeType === 'date_range' && (
                <Input name="dateTo" label={t('validUntil')} type="date" />
              )}
            </Row>
          )}
          <FormActions>
            <SubmitButton onClick={handleSubmit} loading={isLoading}>
              {t('share')}
            </SubmitButton>
          </FormActions>
        </>
      )}
    </Form>
  )
}

export default FormShareWithOther
