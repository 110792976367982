import React from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { signConsent } from '../api/formatted/consent'
import { getVetecardRequestIdent } from '../api/formatted/vetecardRequests'
import Accordion, { AccordionItem } from '../components/Accordion'
import Alert from '../components/Alert'
import Block from '../components/Block'
import Box from '../components/Box'
import Button from '../components/Button'
import Content from '../components/Content'
import FilePreview from '../components/FilePreview'
import Grid from '../components/Grid'
import Identification from '../components/Identification'
import Loader from '../components/Loader'
import Modal, { type ModalRef } from '../components/Modal'
import Spinner from '../components/Spinner'
import Tabs from '../components/Tabs'
import Text from '../components/Text'
import Title from '../components/Title'
import VetecardHead from '../components/VetecardHead'
import VaccinationsContainer from '../containers/VaccinationsContainer'
import FormTransferOwnership from '../forms/FormTransferOwnership'
import { type VetecardBasic, type VetecardFull } from '../typings'
import queryClient from '../utils/queryClient'
import useAuth from '../utils/useAuth'

const VetecardRequest = () => {
  const { t } = useTranslation()
  const { hasPermission } = useAuth()

  const { id } = useParams()
  const vetecardId = id ? parseInt(id) : 0

  const consentModalRef = React.useRef<ModalRef>(null)
  const shareTransferModalRef = React.useRef<ModalRef>(null)

  const {
    data: ident,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ['vetecard-request', vetecardId],
    queryFn: () => getVetecardRequestIdent(vetecardId),
  })

  const { isLoading, mutate } = useMutation(() => signConsent(vetecardId), {
    onSuccess: (vetecardId: VetecardFull['id']) => {
      queryClient.setQueryData(
        ['vetecard-list'],
        (oldData?: { vetecards: VetecardBasic[]; hasMore: boolean }) =>
          oldData && {
            vetecards: oldData.vetecards.map(item =>
              item.id === vetecardId
                ? {
                    ...item,
                    requestStatus: 'payment' as VetecardBasic['requestStatus'],
                  }
                : item
            ),
            hasMore: oldData.hasMore,
          }
      )
      queryClient.setQueryData(
        ['vetecard-request', vetecardId],
        (oldData: VetecardFull | undefined) =>
          oldData
            ? {
                ...oldData,
                requestStatus: 'payment' as VetecardBasic['requestStatus'],
              }
            : undefined
      )
      consentModalRef.current?.close()
      toast.success(t('Consent signed. You can now activate VET e-Card.'))
    },
  })

  const handleAgreement = () => mutate()

  return (
    <Loader loading={isFetching} error={isError}>
      <Content wide>
        {ident && (
          <>
            <Title hideDesktop>{t('vetecard')}</Title>
            <VetecardHead
              vetecardId={ident.id}
              name={ident.name}
              email={ident.email}
              species={ident.species}
              photo={ident.photo}
              isRequest
              requestStatus={ident.requestStatus}
            />
            {ident.requestStatus === 'consentAgreement' && (
              <Alert
                type="warning"
                primary={t('Read and sign the consent')}
                secondary={t('You must sign the consent first to get activate the VET e-Card.')}
                btnLabel={t('Sign')}
                onClick={() => consentModalRef.current?.open()}
              />
            )}
            {hasPermission('pay-vetecards') &&
              (ident.requestStatus === 'payment' ? (
                <Alert
                  type="warning"
                  primary={t('vetecardStates.awaitingPayment')}
                  secondary={t('finalizePaymentToFinish')}
                  btnLabel={t('activate')}
                  btnUrl="/payment"
                />
              ) : (
                ident.requestStatus === 'processingPayment' && (
                  <Alert
                    type="info"
                    primary={t('vetecardStates.processingPayment')}
                    secondary={t('payment.inProgress')}
                  />
                )
              ))}

            <Accordion multiple>
              <AccordionItem title={t('identification')}>
                <Identification
                  ident={ident}
                  canEdit={
                    hasPermission('vetecards-edit_vetecard_details') &&
                    hasPermission('vetecards-advanced')
                  }
                />
              </AccordionItem>

              <AccordionItem title={t('vaccinations')}>
                <Tabs
                  labels={[
                    <>
                      <span className="visible-xs-inline">{t('mandatory')}</span>
                      <span className="hidden-xs"> {t('mandatoryVaccinations')}</span>
                    </>,
                    <>
                      <span className="visible-xs-inline">{t('other')}</span>
                      <span className="hidden-xs"> {t('otherVaccinations')}</span>
                    </>,
                  ]}
                >
                  <VaccinationsContainer
                    vetecardId={vetecardId}
                    category="mandatory"
                    isRequest
                    disableUpload={!hasPermission('vetecards-advanced')}
                  />
                  <VaccinationsContainer
                    vetecardId={vetecardId}
                    category="other"
                    isRequest
                    disableUpload={!hasPermission('vetecards-advanced')}
                  />
                </Tabs>
              </AccordionItem>

              {hasPermission('vetecards-advanced') && (
                <AccordionItem title={t('accessManagement.title')} defaultOpen>
                  <Box
                    title={t('transferOwnership.title')}
                    description={t('transferOwnership.description')}
                    btnLabel={t('Transfer')}
                    onClick={() => shareTransferModalRef.current?.open()}
                  />
                  <Modal size="lg" title={t('transferOwnership.title')} ref={shareTransferModalRef}>
                    <Text>{t('transferOwnership.description')}</Text>
                    <FormTransferOwnership vetecardId={vetecardId} isRequest />
                  </Modal>
                </AccordionItem>
              )}
            </Accordion>

            <Modal size="fullscreen" title={t('Agreement')} ref={consentModalRef}>
              {isLoading ? (
                <Spinner variant="block" />
              ) : (
                <Grid>
                  <FilePreview
                    name="Consent"
                    url={ident.consentUrl || ''}
                    mimeType="application/pdf"
                  />
                  <Block title={t('Digital signature')} condensed>
                    <Button size="lg" onClick={handleAgreement}>
                      Accept and sign the documment
                    </Button>
                  </Block>
                </Grid>
              )}
            </Modal>
          </>
        )}
      </Content>
    </Loader>
  )
}

export default VetecardRequest
