import React from 'react'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { type Institution as InstitutionT } from '../typings'
import Block from './Block'
import Button from './Button'
import ContactDetails from './ContactDetails'
import Field from './Field'
import Grid from './Grid'
import Panel from './Panel'

interface InstitutionProps extends InstitutionT {
  className?: string
}

const Institution = ({
  name,
  contact,
  siren,
  siret,
  finess,
  internalIdentifier,
  className,
}: InstitutionProps) => {
  const { t } = useTranslation()

  return (
    <Panel
      title={name}
      className={classNames('institution', className)}
      footer={
        contact?.phone && (
          <Grid>
            <div>
              <Button block component="a" href={`tel:${contact.phone}`}>
                <FontAwesomeIcon icon={faPhone} /> {t('callToClinic')}
              </Button>
            </div>
            <div>
              <Button block disabled>
                <FontAwesomeIcon icon={faEnvelope} /> {t('sendEmail')}
              </Button>
            </div>
          </Grid>
        )
      }
    >
      <Grid>
        <Block condensed noMargin title={t('institution.contactToClinic')}>
          <ContactDetails {...contact} />
        </Block>
        <Block condensed noMargin title={t('institution.clinicDetails')}>
          {siren && (
            <Field inlineLabel label="SIREN">
              {siren}
            </Field>
          )}
          {siret && (
            <Field inlineLabel label="SIRET">
              {siret}
            </Field>
          )}
          {finess && (
            <Field inlineLabel label={t('finess')}>
              {finess}
            </Field>
          )}
          <Field inlineLabel label={t('dpe')}>
            {internalIdentifier}
          </Field>
        </Block>
      </Grid>
    </Panel>
  )
}

export default Institution
