import React from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { deleteFile, downloadFile, getFiles } from '../api/formatted/files'
import Files from '../components/Files'
import Loader from '../components/Loader'
import {
  type DeleteFilePayload,
  type FileCategory,
  FileEntity,
  type VetecardBasic,
} from '../typings'
import queryClient from '../utils/queryClient'
import useAuth from '../utils/useAuth'

interface FilesContainerProps {
  vetecardId: VetecardBasic['id']
  category: FileCategory
}

const FilesContainer = ({ vetecardId, category }: FilesContainerProps) => {
  const { t } = useTranslation()

  const { currentUser } = useAuth()

  const {
    data = [],
    isFetching,
    isError,
  } = useQuery({
    queryKey: ['vetecard', vetecardId, 'files', category],
    queryFn: () => getFiles(vetecardId, currentUser, category),
  })

  const { mutate: mutateDownload } = useMutation(
    (name: string) => downloadFile(category, vetecardId, name),
    {
      onMutate: () => toast.info(t('downloadStart')),
      onError: () => toast.error(t('downloadError')),
    }
  )

  const { mutate: mutateDelete } = useMutation(
    (payload: DeleteFilePayload) => deleteFile(payload),
    {
      onSuccess: (id: DeleteFilePayload['id']) => {
        queryClient.setQueryData(
          ['vetecard', vetecardId, 'files', category],
          (oldData?: FileEntity[]) => oldData && oldData.filter(item => item.id !== id)
        )
        toast.success(t('fileDeleted'))
      },
    }
  )

  const handleDownload = (name: string) => mutateDownload(name)
  const handleDelete = (payload: DeleteFilePayload) => mutateDelete(payload)

  return (
    <Loader loading={isFetching} error={isError}>
      <Files
        vetecardId={vetecardId}
        data={data}
        category={category}
        onDownload={handleDownload}
        onDelete={handleDelete}
      />
    </Loader>
  )
}

export default FilesContainer
