import React from 'react'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormActions } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import Button from '../components/Button'
import IconButton from '../components/IconButton'
import Text from '../components/Text'
import Modal, { type ModalRef } from './Modal'

interface ConfirmDeleteProps {
  name: string
  text?: string
  onDelete: () => void
  className?: string
}

const ConfirmDelete = ({ name, text, onDelete, className }: ConfirmDeleteProps) => {
  const [loading, setLoading] = React.useState(false)
  const { t } = useTranslation()
  const modalRef = React.useRef<ModalRef>(null)

  const handleDelete = async () => {
    setLoading(true)
    try {
      onDelete()
      modalRef.current?.close()
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }
  return (
    <Modal
      size="sm"
      trigger={
        <IconButton className={className}>
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
      }
      ref={modalRef}
    >
      <Text>{text || t('confirmDelete', { name })}</Text>
      <FormActions>
        <Button variant="link" onClick={() => modalRef.current?.close()}>
          {t('cancel')}
        </Button>
        <Button variant="danger" onClick={handleDelete} loading={loading}>
          {t('delete')}
        </Button>
      </FormActions>
    </Modal>
  )
}

export default ConfirmDelete
