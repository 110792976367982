import React from 'react'
import classNames from 'classnames'
import LogoWhiteSvg from '../../assets/logo-white.svg'
import logoPng from '../../assets/logo.png'
import logoRetinaPng from '../../assets/logo@2x.png'
import './style.sass'

interface LogoProps {
  white?: boolean
  className?: string
}

const Logo = ({ white, className }: LogoProps) =>
  white ? (
    <LogoWhiteSvg />
  ) : (
    <img
      src={logoPng}
      srcSet={`${logoRetinaPng} 2x`}
      className={classNames('logo', className)}
      alt="VET e-Card"
    />
  )

export default Logo
