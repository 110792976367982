import React from 'react'
import classNames from 'classnames'
import Button from '../Button'
import './style.sass'

interface BoxProps {
  title: string
  description?: string
  btnLabel?: React.ReactNode
  onClick?: () => void
  externalUrl?: string
  white?: boolean
  footer?: React.ReactNode
  paddedFooter?: boolean
}

const Box = ({
  title,
  description,
  btnLabel,
  onClick,
  externalUrl,
  white,
  footer,
  paddedFooter,
}: BoxProps) => (
  <div className={classNames('box', { 'box--white': white, 'box--padded-footer': paddedFooter })}>
    <div className="box__main">
      <div className="box__text">
        <div className="box__title">{title}</div>
        {description && <div className="box__description">{description}</div>}
      </div>
      {btnLabel && (
        <Button
          variant="dark"
          size="sm"
          block
          className="box__btn"
          onClick={onClick}
          component={externalUrl && 'a'}
          href={externalUrl}
          target={externalUrl && '_blank'}
          rel={externalUrl && 'noreferrer'}
        >
          {btnLabel}
        </Button>
      )}
    </div>
    {footer && <div className="box__footer">{footer}</div>}
  </div>
)

export default Box
