import api from '../.'
import { type Consent, type UploadConsentPayload, type VetecardBasic } from '../../typings'
import {
  ListConsentsApiResponseEntryT,
  ListConsentsApiResponseT,
} from '../pure/owner/vetecard/consent/list'
import {
  getMinioClient,
  getObject,
  getObjects,
  getPresignedUrl,
  getStoragePath,
  putObject,
} from './storage'

const bucketName = process.env.MINIO_BUCKET_VETECARD_CONSENT as string

export const uploadConsent = async (payload: UploadConsentPayload): Promise<Consent> => {
  const { file, documentDate, signatureDate, vetecardId } = payload
  const storage = await getMinioClient()

  await api.put(`/0/vetecard/${vetecardId}/consent`, {
    storageKey: file.name,
    documentDate: new Date(documentDate),
    signatureDate: new Date(signatureDate),
  })

  await putObject(storage, vetecardId, bucketName, file.name, Buffer.from(await file.arrayBuffer()))

  return {
    id: file.name,
    name: file.name,
    url: await getPresignedUrl(storage, vetecardId, bucketName, file.name),
    documentDate: new Date(documentDate),
    signatureDate: new Date(signatureDate),
    createdAt: new Date(signatureDate), // TODO: Retrieve a real createdAt date from uploaded file.
  }
}

export const signConsent = async (
  vetecardId: VetecardBasic['id']
): Promise<VetecardBasic['id']> => {
  await new Promise(resolve => setTimeout(resolve, 1000))
  return vetecardId
}

export const getConsents = async (vetecardId: VetecardBasic['id']): Promise<Consent[]> => {
  const storage = await getMinioClient()

  const { data: apiResponse } = (await api.get(`/0/vetecard/${vetecardId}/consent`)) as {
    data: ListConsentsApiResponseT
  }

  interface FileDescriptor {
    name: string
    url: string
    size: number
    etag: string
    lastModified: Date
  }

  const asPresigned =
    () =>
    async ({ name: nameWithPrefix, ...rest }: Omit<FileDescriptor, 'url'>) => {
      const name = nameWithPrefix.slice(getStoragePath(vetecardId).length + 1)
      return {
        ...rest,
        name,
        url: await getPresignedUrl(storage, vetecardId, bucketName, name),
      }
    }
  const stream = getObjects(storage, vetecardId, bucketName)
  const storageResponse = (await new Promise((resolve, reject) => {
    let ret: Promise<FileDescriptor>[] = []
    stream.on('data', ({ name, size, etag, lastModified }: FileDescriptor) =>
      ret.push(asPresigned()({ name, size, etag, lastModified }))
    )
    stream.on('end', () => resolve(Promise.all(ret)))
    stream.on('error', err => reject(err))
  })) as FileDescriptor[]

  const response = await Promise.all(
    storageResponse.map(({ name, url }) => {
      const metadata = apiResponse.find(
        ({ storageKey }: ListConsentsApiResponseEntryT) => storageKey === name
      )

      return {
        storageKey: metadata?.storageKey || name,
        documentDate: metadata?.documentDate ? new Date(metadata?.documentDate) : null,
        signatureDate: metadata?.signatureDate ? new Date(metadata?.signatureDate) : null,
        url,
      }
    })
  )

  return response.map(
    ({ storageKey, url, documentDate, signatureDate }): Consent => ({
      id: storageKey,
      name: storageKey,
      url,
      documentDate: documentDate || undefined,
      signatureDate: signatureDate || undefined,
      createdAt: documentDate === null ? new Date() : documentDate,
    })
  )
}

export const downloadConsent = async (
  vetecardId: VetecardBasic['id'],
  name: string
): Promise<void> => {
  const storage = await getMinioClient()
  const response = await getObject(storage, vetecardId, bucketName, name)
  console.log('response: ', response)
  // TODO: Trigger a file download here.
  // await window.showSaveFilePicker({})
  return
}
