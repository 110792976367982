import React from 'react'
import classNames from 'classnames'
import './style.sass'

interface TagsProps {
  tags: string[]
  centered?: boolean
  primary?: boolean
  small?: boolean
  className?: string
}

const Tags = ({ tags, centered, primary, small, className }: TagsProps) =>
  tags ? (
    <div
      className={classNames(
        'tags',
        { 'tags--centered': centered, 'tags--primary': primary, 'tags--small': small },
        className
      )}
    >
      {tags.map((tag, index) => (
        <span key={index} className="tags__tag">
          {tag}
        </span>
      ))}
    </div>
  ) : null

export default Tags
