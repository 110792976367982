import React from 'react'
import { useTranslation } from 'react-i18next'
import Content from '../components/Content'
import Title from '../components/Title'
import FormRequestVetecard from '../forms/FormRequestVetecard'

const RequestVetecard = () => {
  const { t } = useTranslation()
  return (
    <Content>
      <Title>{t('requestVetecard')}</Title>
      <FormRequestVetecard />
    </Content>
  )
}

export default RequestVetecard
