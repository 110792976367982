import api from '../.'
import {
  type EditPersonalInfoPayload,
  type Profession,
  type UserFull,
  type UserType,
} from '../../typings'
import { type V0FetchAssociationProfileResponseT } from '../pure/association/profile/fetch'
import { type V0FetchBreederProfileResponseT } from '../pure/breeder/profile/fetch'
import { type V0SetBreederProfileResponseT } from '../pure/breeder/profile/set'
import { type V0FetchIndividualProfileResponseT } from '../pure/individual/profile/fetch'
import { type FetchPetsitterProfileResponseT } from '../pure/petsitter/profile/fetch'
import { type V0FetchVeterinarianProfileResponseT } from '../pure/veterinarian/profile/fetch'
import { type V0SetVeterinarianProfileResponseT } from '../pure/veterinarian/profile/set'

export const getUserProfile = async (userType: UserType, keycloakId: string): Promise<UserFull> => {
  switch (userType) {
    case 'individual': {
      const response = await api.get(`/0/individual/${keycloakId}/profile`)
      const data = response.data as V0FetchIndividualProfileResponseT
      if (!data) {
        return {
          keycloakId,
          firstName: 'No Name',
          userType,
        }
      }
      const {
        keycloakId: keycloakIdData,
        firstName,
        lastName,
        email,
        phoneNumber,
        faxNumber,
        address,
        city,
        postalCode,
        country,
      } = data
      return {
        keycloakId: keycloakIdData,
        firstName,
        lastName,
        email: email === null ? undefined : email,
        userType,
        contact: {
          phone: phoneNumber === null ? undefined : phoneNumber,
          fax: faxNumber === null ? undefined : faxNumber,
          address: address === null ? undefined : address,
          city: city === null ? undefined : city,
          postalCode: postalCode === null ? undefined : postalCode,
          country: country === null ? undefined : country,
        },
      }
    }
    case 'petsitter': {
      //FIXME: migrate to roles
      const response = await api.get(`/0/petsitter/${keycloakId}/profile`)
      const data = response.data as FetchPetsitterProfileResponseT
      if (!data) {
        return {
          keycloakId,
          firstName: 'No Name',
          userType,
        }
      }
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        faxNumber,
        address,
        city,
        postalCode,
        country,
      } = data
      return {
        keycloakId: keycloakId,
        firstName,
        lastName,
        email: email === null ? undefined : email,
        userType,
        contact: {
          phone: phoneNumber === null ? undefined : phoneNumber,
          fax: faxNumber === null ? undefined : faxNumber,
          address: address === null ? undefined : address,
          city: city === null ? undefined : city,
          postalCode: postalCode === null ? undefined : postalCode,
          country: country === null ? undefined : country,
        },
      }
    }

    case 'veterinarian': {
      const response = await api.get(`/0/veterinarian/${keycloakId}/profile`)
      const data = response.data as V0FetchVeterinarianProfileResponseT
      if (data === null)
        return {
          keycloakId,
          firstName: 'Veterinarian user',
          userType,
        }
      const { id, firstName, lastName, professionalCode, profession, institutions, profile } = data
      return {
        keycloakId,
        veterinarianId: id,
        rpps: professionalCode,
        firstName,
        lastName,
        email: profile !== null && profile.email !== null ? profile.email : undefined,
        userType,
        profession: profession as Profession,
        institutions: institutions.map(
          ({
            id,
            name,
            phoneNumber1,
            phoneNumber2,
            address,
            city,
            postalCode,
            country,
            profile,
            code,
          }) => ({
            id: id.toString(),
            name,
            contact: {
              phone: phoneNumber1 === null ? undefined : phoneNumber1,
              fax: phoneNumber2 === null ? undefined : phoneNumber2,
              address: address === null ? undefined : address,
              city: city === null ? undefined : city,
              postalCode: postalCode === null ? undefined : postalCode,
              country: country === null ? undefined : country,
            },
            siren: profile !== null && profile.siren !== null ? profile.siren : undefined,
            siret: profile !== null && profile.siret !== null ? profile.siret : undefined,
            finess: profile !== null && profile.finess !== null ? profile.finess : undefined,
            code: code,
            internalIdentifier:
              profile !== null && profile.internalIdentifier !== null
                ? profile.internalIdentifier
                : undefined,
          })
        ),
      }
    }

    case 'administrator': {
      return {
        keycloakId,
        firstName: 'Admin user',
        userType,
      }
    }

    case 'breeder': {
      const response = await api.get(`/0/breeder/${keycloakId}/profile`)
      const data = response.data as V0FetchBreederProfileResponseT
      if (data === null) {
        return {
          keycloakId,
          firstName: 'Breeder',
          userType,
        }
      }
      const {
        id,
        profile: { firstName, lastName, email, politeness },
        institution,
      } = data
      return {
        keycloakId: id,
        userType,
        firstName,
        lastName,
        email,
        politeness: politeness || undefined,
        institutions:
          institution === null
            ? []
            : [
                {
                  id: institution.id.toString(),
                  name: institution.name,
                  species: institution.species,
                  contact: {
                    phone: institution.phoneNumber1 || undefined,
                    fax: institution.phoneNumber2 || undefined,
                    address: institution.address || undefined,
                    city: institution.city || undefined,
                    postalCode: institution.postalCode || undefined,
                    country: institution.country || undefined,
                  },
                  siren: institution.siren || undefined,
                  siret: institution.siret || undefined,
                  finess: institution.finess || undefined,
                  finessGeo: institution.finessGeo || undefined,
                },
              ],
      }
    }

    case 'association': {
      const response = await api.get(`/0/association/${keycloakId}/profile`)
      const data = response.data as V0FetchAssociationProfileResponseT
      if (data === null) {
        return {
          keycloakId,
          firstName: 'Association',
          userType,
        }
      } else {
        const {
          id,
          profile: { firstName, lastName, email, politeness },
          institution,
        } = data
        return {
          keycloakId: id,
          userType,
          firstName,
          lastName,
          email,
          politeness: politeness || undefined,
          institutions:
            institution === null
              ? []
              : [
                  {
                    id: institution.id.toString(),
                    name: institution.name,
                    species: institution.species,
                    contact: {
                      phone: institution.phoneNumber1 || undefined,
                      fax: institution.phoneNumber2 || undefined,
                      address: institution.address || undefined,
                      city: institution.city || undefined,
                      postalCode: institution.postalCode || undefined,
                      country: institution.country || undefined,
                    },
                    siren: institution.siren || undefined,
                    siret: institution.siret || undefined,
                    finess: institution.finess || undefined,
                    finessGeo: institution.finessGeo || undefined,
                  },
                ],
        }
      }
    }

    default:
      throw new Error('Wrong userType')
  }
}

export const saveUserProfile = async (
  userType: UserType,
  keycloakId: string = '',
  rpps: string | null = null,
  payload: EditPersonalInfoPayload
): Promise<UserFull> => {
  switch (userType) {
    case 'individual': {
      const {
        firstName,
        lastName,
        // email,
        phoneNumber1,
        phoneNumber2 = null,
        address,
        city,
        postalCode,
        country,
        // politeness = '',
      } = payload
      await api.put(`/0/individual/${keycloakId}/profile`, {
        keycloakId,
        // politeness,
        firstName,
        lastName,
        email: null,
        phoneNumber: phoneNumber1,
        faxNumber: phoneNumber2,
        address,
        city,
        postalCode,
        country,
      })
      return {
        keycloakId,
        userType,
        firstName,
        lastName,
        contact: {
          phone: phoneNumber1,
          fax: phoneNumber2 || undefined,
          address,
          city,
          postalCode,
          country,
        },
      }
    }

    case 'petsitter': {
      //FIXME: migrate to roles
      const {
        firstName,
        lastName,
        // email,
        phoneNumber1,
        phoneNumber2 = null,
        address,
        city,
        postalCode,
        country,
        // politeness = '',
      } = payload
      await api.put(`/0/petsitter/${keycloakId}/profile`, {
        id: keycloakId,
        // politeness,
        firstName,
        lastName,
        email: null,
        phoneNumber: phoneNumber1,
        faxNumber: phoneNumber2,
        address,
        city,
        postalCode,
        country,
      })
      return {
        keycloakId,
        userType,
        firstName,
        lastName,
        contact: {
          phone: phoneNumber1,
          fax: phoneNumber2 || undefined,
          address,
          city,
          postalCode,
          country,
        },
      }
    }

    case 'veterinarian': {
      const {
        // email,
        politeness = null,
        field = null,
        category = null,
        specialization = [],
        institutionId,
        siren = null,
        siret = null,
        finess = null,
      } = payload
      if (rpps === null) {
        throw new Error('Invalid veterinarian: no professional code')
      }
      const response = await api.put(`/0/veterinarian/${keycloakId}/profile`, {
        professionalCode: rpps,
        profile: {
          id: keycloakId,
          modified: null,
          created: null,
          logged: null,
          email: null,
          politeness,
          field,
          category: category,
          specialization,
        },
        institutions:
          typeof institutionId === 'undefined'
            ? []
            : [
                {
                  id: parseInt(institutionId),
                  profile: {
                    siren,
                    siret,
                    finess,
                    finessGeo: null,
                    internalIdentifier: null,
                    nationalStructureIdentifier: null,
                  },
                },
              ],
      })
      const returnedVet = response.data as V0SetVeterinarianProfileResponseT
      return {
        keycloakId: returnedVet.profile.id,
        veterinarianId: returnedVet.id,
        rpps: returnedVet.professionalCode,
        userType,
        email: returnedVet.profile.email || undefined,
        firstName: returnedVet.firstName,
        lastName: returnedVet.lastName,
        profession: {
          politeness: returnedVet.profile.politeness || undefined,
          field: returnedVet.profile.field || undefined,
          category: returnedVet.profile.category || undefined,
          specialization: returnedVet.profile.specialization || [],
        },
        institutions: returnedVet.institutions.map(
          ({
            id,
            name,
            phoneNumber1,
            phoneNumber2,
            address,
            city,
            postalCode,
            country,
            profile,
          }) => ({
            id: id.toString(),
            name,
            contact: {
              phone: phoneNumber1 || undefined,
              fax: phoneNumber2 || undefined,
              address: address || undefined,
              city: city || undefined,
              postalCode: postalCode || undefined,
              country: country || undefined,
            },
            siren: profile?.siren || undefined,
            siret: profile?.siret || undefined,
            finess: profile?.finess || undefined,
            internalIdentifier: profile?.internalIdentifier || undefined,
          })
        ),
      }
    }

    case 'breeder': {
      const {
        firstName,
        lastName,
        politeness = null,
        institutionId = null,
        institutionName = '',
        species = [],
        siren = null,
        siret = null,
        finess = null,
        finessGeo = null,
        phoneNumber1,
        phoneNumber2 = null,
        address = null,
        city = '',
        postalCode = null,
        country = null,
      } = payload
      const response = await api.put(`/0/breeder/${keycloakId}/profile`, {
        id: keycloakId,
        profile: {
          firstName,
          lastName,
          email: '', // TODO: Remove empty string after field list is stable.
          email_confirmed: true,
          politeness,
        },
        institution: {
          ...(institutionId === null ? {} : { id: institutionId }),
          name: institutionName,
          species,
          siren,
          siret,
          finess,
          finessGeo,
          phoneNumber1: phoneNumber1 || null,
          phoneNumber2,
          address,
          city,
          country,
          postalCode,
          active: true, // TODO: Should it be here?
        },
      })
      const returnedBreeder = response.data as V0SetBreederProfileResponseT
      return {
        keycloakId: returnedBreeder.id,
        userType,
        firstName: returnedBreeder.profile.firstName,
        lastName: returnedBreeder.profile.lastName,
        email: returnedBreeder.profile.email,
        politeness: returnedBreeder.profile.politeness || undefined,
        institutions:
          returnedBreeder.institution === null
            ? []
            : [
                {
                  id: returnedBreeder.institution.id.toString(),
                  name: returnedBreeder.institution.name,
                  species: returnedBreeder.institution.species,
                  contact: {
                    phone: returnedBreeder.institution.phoneNumber1 || undefined,
                    fax: returnedBreeder.institution.phoneNumber2 || undefined,
                    address: returnedBreeder.institution.address || undefined,
                    city: returnedBreeder.institution.city || undefined,
                    postalCode: returnedBreeder.institution.postalCode || undefined,
                    country: returnedBreeder.institution.country || undefined,
                  },
                  siren: returnedBreeder.institution.siren || undefined,
                  siret: returnedBreeder.institution.siret || undefined,
                  finess: returnedBreeder.institution.finess || undefined,
                  finessGeo: returnedBreeder.institution.finessGeo || undefined,
                },
              ],
      }
    }

    case 'association': {
      const {
        firstName,
        lastName,
        politeness = null,
        institutionName = '',
        species = [],
        siren = null,
        siret = null,
        finess = null,
        finessGeo = null,
        phoneNumber1,
        phoneNumber2 = null,
        address = null,
        city = '',
        postalCode = null,
        country = null,
        rna,
      } = payload
      await api.put(`/0/association/${keycloakId}/profile`, {
        id: keycloakId,
        profile: {
          firstName,
          lastName,
          email: '', // TODO: Remove empty string after field list is stable.
          email_confirmed: true,
          politeness,
        },
        institution: {
          id: 1, // TODO: Change after field list is stable.
          name: institutionName,
          species,
          siren,
          siret,
          finess,
          finessGeo,
          phoneNumber1: phoneNumber1 || null,
          phoneNumber2,
          address,
          city,
          country,
          postalCode,
        },
      })
      return {
        keycloakId,
        userType,
        firstName,
        lastName,
        institutions: [
          {
            id: '1', // TODO: Change after field list is stable.
            name: institutionName,
            contact: {
              phone: phoneNumber1 || undefined,
              fax: phoneNumber2 || undefined,
              address: address || undefined,
              city: city || undefined,
              postalCode: postalCode || undefined,
              country: country || undefined,
            },
            siren: siren || undefined,
            siret: siret || undefined,
            finess: finess || undefined,
            finessGeo: finessGeo || undefined,
          },
        ],
      }
    }

    default:
      throw new Error('Wrong userType')
  }
}
