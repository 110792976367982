import React from 'react'
import { useMutation } from '@tanstack/react-query'
import Form, { FormActions } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { assignVeterinarian } from '../api/formatted/vetecard'
import SubmitButton from '../components/SubmitButton'
import VeterinarianSearchInput from '../containers/VeterinarianSearchInput'
import { type VetecardBasic, type VeterinarianBasic } from '../typings'
import queryClient from '../utils/queryClient'
import useAuth from '../utils/useAuth'

interface FormShareWithVeterinarianProps {
  vetecardId: VetecardBasic['id']
  onSuccess: () => void
}

const FormAssignVeterinarian = ({ vetecardId, onSuccess }: FormShareWithVeterinarianProps) => {
  const { t } = useTranslation()
  const { currentUser } = useAuth()

  const { isLoading, mutate } = useMutation(
    (veterinarianId: VeterinarianBasic['veterinarianId']) =>
      assignVeterinarian(currentUser, veterinarianId, vetecardId),
    {
      onSuccess: (newVet: VeterinarianBasic) => {
        queryClient.setQueryData(
          ['vetecard', vetecardId, 'referring-vets'],
          (oldData: VeterinarianBasic[] = []) => [newVet, ...oldData]
        )
        toast.success(t('success'))
        onSuccess()
      },
    }
  )

  const handleSubmit = ({
    veterinarianId,
  }: {
    veterinarianId: VeterinarianBasic['veterinarianId']
  }) => {
    mutate(veterinarianId)
  }

  return (
    <Form fields={['veterinarianId']} allMandatory>
      <VeterinarianSearchInput name="veterinarianId" placeholder={t('enterVeterinarian')} />
      <FormActions>
        <SubmitButton onClick={handleSubmit} loading={isLoading}>
          {t('share')}
        </SubmitButton>
      </FormActions>
    </Form>
  )
}

export default FormAssignVeterinarian
