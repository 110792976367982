import React from 'react'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { integrateEmailFile, integrateEmailVaccination } from '../api/formatted/inbox'
import Content from '../components/Content'
import InboxFile from '../components/InboxFile'
import Modal, { type ModalRef } from '../components/Modal'
import Panel from '../components/Panel'
import Tabs from '../components/Tabs'
import Text from '../components/Text'
import Title from '../components/Title'
import DocumentsContainer from '../containers/DocumentsContainer'
import {
  type InboxDocument,
  type IntegrateFilePayload,
  type IntegrateVaccinationPayload,
} from '../typings'
import queryClient from '../utils/queryClient'
import useAuth from '../utils/useAuth'

const DocumentInbox = () => {
  const { t } = useTranslation()
  const { currentUser } = useAuth()
  const [document, setDocument] = React.useState<InboxDocument | undefined>(undefined)
  const modalRef = React.useRef<ModalRef>(null)

  const { isLoading: fileIsLoading, mutate: fileMutate } = useMutation(
    (payload: IntegrateFilePayload) => integrateEmailFile(currentUser, payload),
    {
      onSuccess: ({ attachmentId, category }: IntegrateFilePayload) => {
        queryClient.setQueryData(
          ['document-inbox', 'new'],
          (oldData?: InboxDocument[]) => oldData && oldData.filter(item => item.id !== attachmentId)
        )
        queryClient.invalidateQueries({ queryKey: ['document-inbox', 'archive'] })
        if (document) {
          const { vetecard } = document
          queryClient.invalidateQueries({ queryKey: ['vetecard', vetecard.id, 'files', category] })
        }
        toast.success(t('fileIntegrated'))
        modalRef.current?.close()
      },
    }
  )

  const { isLoading: vaccinationIsLoading, mutate: vaccinationMutate } = useMutation(
    (payload: IntegrateVaccinationPayload) => integrateEmailVaccination(currentUser, payload),
    {
      onSuccess: ({ attachmentId, category }: IntegrateVaccinationPayload) => {
        queryClient.setQueryData(
          ['document-inbox', 'new'],
          (oldData?: InboxDocument[]) => oldData && oldData.filter(item => item.id !== attachmentId)
        )
        queryClient.invalidateQueries({ queryKey: ['document-inbox', 'archive'] })
        if (document) {
          const { vetecard } = document
          queryClient.invalidateQueries({
            queryKey: ['vetecard', vetecard.id, 'vaccinations', category],
          })
        }
        toast.success(t('vaccinationIntegrated'))
        modalRef.current?.close()
      },
    }
  )

  const openDocument = (item: InboxDocument) => {
    setDocument(item)
    modalRef.current?.open()
  }

  return (
    <Content wide>
      <Title>{t('documentInbox')}</Title>
      <Text>{t('documentInboxDescr')}</Text>
      <Panel>
        <Tabs labels={[t('new'), t('archive')]}>
          <DocumentsContainer onValidate={openDocument} />
          <DocumentsContainer onValidate={openDocument} archive />
        </Tabs>
      </Panel>
      <Modal size="fullscreen" title={t('Validate document')} ref={modalRef}>
        <InboxFile
          document={document}
          onFileIntegrate={(values: IntegrateFilePayload) => fileMutate(values)}
          onVaccinationIntegrate={(values: IntegrateVaccinationPayload) =>
            vaccinationMutate(values)
          }
          isLoading={fileIsLoading || vaccinationIsLoading}
        />
      </Modal>
    </Content>
  )
}

export default DocumentInbox
