import React from 'react'
import classNames from 'classnames'
import './style.sass'

interface FiltersSectionProps {
  condensed?: boolean
  children: React.ReactNode
}

const FiltersSection = ({ condensed, children }: FiltersSectionProps) => (
  <div className={classNames('filters-section', { 'filters-section--condensed': condensed })}>
    {children}
  </div>
)

export default FiltersSection
