import React from 'react'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import './style.sass'

interface ModalProps {
  isOpen?: boolean
  trigger?: React.ReactNode
  title?: string
  size?: 'xs' | 'sm' | 'lg' | 'fullscreen'
  onClose?: () => void
  children: React.ReactNode
}

export interface ModalRef {
  open: () => void
  close: () => void
}

const Modal = React.forwardRef<ModalRef, ModalProps>(
  ({ isOpen: isOpenProp, trigger, title, size, onClose, children }, ref) => {
    const [isOpen, setIsOpen] = React.useState(false)

    React.useImperativeHandle(
      ref,
      () => ({
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
      }),
      []
    )

    React.useEffect(() => {
      if (isOpenProp) {
        setIsOpen(isOpenProp)
      }
    }, [])

    React.useEffect(() => {
      if (isOpenProp !== isOpen) {
        setIsOpen(isOpenProp || false)
      }
    }, [isOpenProp])

    return (
      <div className={classNames('modal', { [`modal--${size}`]: size }, 'is-open')}>
        {trigger && <span onClick={() => setIsOpen(true)}>{trigger}</span>}

        {isOpen && (
          <div className="modal__inner">
            <div className="modal__content">
              {title && <h2 className="modal__title">{title}</h2>}
              {children}
            </div>
            {(size === 'lg' || size === 'fullscreen') && (
              <div
                className="modal__close"
                onClick={() => {
                  setIsOpen(false)
                  onClose && onClose()
                }}
              >
                <FontAwesomeIcon icon={faClose} />
              </div>
            )}
          </div>
        )}
        {isOpen && (
          <div
            className="modal__overlay"
            onClick={() => {
              setIsOpen(false)
              onClose && onClose()
            }}
          />
        )}
      </div>
    )
  }
)

export default Modal
