import React from 'react'
import { faMessage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { type Link as LinkT } from '../../typings'
import useAuth from '../../utils/useAuth'
import Button from '../Button'
import Logo from '../Logo'
import NavVertical from '../NavVertical'
import './style.sass'

interface SidebarProps {
  links: LinkT[]
  className?: string
}

const Sidebar = ({ links, className }: SidebarProps) => {
  const { t } = useTranslation()
  const { hasPermission } = useAuth()
  return (
    <div className={classNames('sidebar', className)}>
      <a href="/" className="sidebar__logo">
        <Logo white />
      </a>
      <NavVertical links={links} className="sidebar__links" />
      {hasPermission('messages') && (
        <Button variant="dark" component={Link} to="/support" block>
          {t('support.support')}
        </Button>
      )}
    </div>
  )
}

export default Sidebar
