import React from 'react'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Link } from '../../typings'
import Button from '../Button'
import './style.sass'

interface DropdownButtonProps {
  children?: React.ReactNode
  onClick?: () => void
  href?: string
  actions: Link[]
  size?: 'xs' | 'sm' | 'lg'
  disabled?: boolean
}

const DropdownButton = ({
  children,
  onClick,
  href,
  actions,
  size,
  disabled,
}: DropdownButtonProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false)

  return (
    <div className={classNames('dropdown-btn')}>
      <div className="dropdown-btn__group">
        <Button
          className="dropdown-btn__primary"
          component={href && 'a'}
          href={href}
          onClick={onClick}
          size={size}
          disabled={disabled}
        >
          {children}
        </Button>
        <Button
          className="dropdown-btn__secondary"
          onClick={() => setIsExpanded(!isExpanded)}
          size={size}
        >
          <FontAwesomeIcon icon={faEllipsis} />
        </Button>
      </div>
      {isExpanded && (
        <>
          <div className="dropdown-btn__overlay" onClick={() => setIsExpanded(false)} />
          <div className="dropdown-btn__actions">
            {actions.map(({ label, onClick }, index) => (
              <div key={index} className="dropdown-btn__action" onClick={onClick}>
                {label}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default DropdownButton
