import React from 'react'
import { useTranslation } from 'react-i18next'
import Content from '../components/Content'
import Institution from '../components/Institution'
import Panel from '../components/Panel'
import Title from '../components/Title'
import UserDetails from '../components/UserDetails'
import useAuth from '../utils/useAuth'

const PersonalInfo = () => {
  const { t } = useTranslation()
  const { currentUser } = useAuth()

  return (
    <Content>
      <Title>{t('myProfile')}</Title>
      <Panel>
        <UserDetails {...currentUser} canEdit />
      </Panel>

      {currentUser.institutions &&
        currentUser.institutions.map(item => <Institution key={item.id} {...item} />)}
    </Content>
  )
}

export default PersonalInfo
