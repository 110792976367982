import { Client, CopyConditions } from 'minio'
import api from '../.'
import { type VetecardBasic } from '../../typings'

export const getMinioClient = async (): Promise<Client> => {
  // TODO: Minio client should not be initiated on every request, but created once globally.
  const minioApiHost = process.env.MINIO_API_HOST
  const minioApiPort = process.env.MINIO_API_PORT

  const {
    data: { AccessKeyId: accessKey, SecretAccessKey: secretKey, SessionToken: sessionToken },
  } = (await api.post(`/0/storage/identity`)) as {
    data: { AccessKeyId: string; SecretAccessKey: string; SessionToken: string }
  }

  if (typeof minioApiHost === 'undefined') {
    throw new Error('Missing "MINIO_API_HOST" environment variable')
  }
  if (typeof minioApiPort === 'undefined') {
    throw new Error('Missing "MINIO_API_PORT" environment variable')
  }
  const minioApiPortNumber = parseInt(minioApiPort)
  if (isNaN(minioApiPortNumber)) {
    throw new Error(`Invalid "MINIO_API_PORT" environment variable "${minioApiPortNumber}"`)
  }

  let useSSL = true
  if (process.env.MINIO_USE_SSL === 'false') {
    useSSL = false
  }

  return new Client({
    endPoint: minioApiHost,
    // port: minioApiPortNumber,
    useSSL,
    accessKey,
    secretKey,
    sessionToken,
  })
}

export const getStoragePath = (
  vetecardId: VetecardBasic['id'],
  storageKey?: string,
  isRequest?: boolean
) =>
  `${isRequest ? 'vetecard_request' : 'vetecard'}-${vetecardId}${
    storageKey?.length ? `/${storageKey}` : ''
  }`

export const getPresignedUrl = async (
  storage: Client,
  vetecardId: VetecardBasic['id'],
  bucketName: string,
  storageKey?: string,
  isRequest?: boolean
): Promise<string> =>
  await storage.presignedUrl(
    'GET',
    bucketName,
    getStoragePath(vetecardId, storageKey, isRequest),
    7 * 24 * 60 * 60
  )

export const putObject = async (
  storage: Client,
  vetecardId: VetecardBasic['id'],
  bucketName: string,
  storageKey: string,
  buffer: Buffer
) => await storage.putObject(bucketName, getStoragePath(vetecardId, storageKey), buffer)

export const getObject = async (
  storage: Client,
  vetecardId: VetecardBasic['id'],
  bucketName: string,
  storageKey?: string
) => await storage.getObject(bucketName, getStoragePath(vetecardId, storageKey))

export const getObjects = (storage: Client, vetecardId: VetecardBasic['id'], bucketName: string) =>
  storage.listObjectsV2(bucketName, `vetecard-${vetecardId}/`)

export const copyObject = async (
  storage: Client,
  vetecardId: VetecardBasic['id'],
  bucketName: string,
  sourceStorageKey: string,
  targetStorageKey: string
) =>
  await storage.copyObject(
    bucketName,
    getStoragePath(vetecardId, targetStorageKey),
    `${bucketName}/${getStoragePath(vetecardId, sourceStorageKey)}`,
    new CopyConditions()
  )

export const removeFile = async (
  storage: Client,
  vetecardId: VetecardBasic['id'],
  bucketName: string,
  storageKey: string
) => await storage.removeObject(bucketName, getStoragePath(vetecardId, storageKey))
