import React from 'react'
import { useMutation } from '@tanstack/react-query'
import Form, { Input } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { transferOwnership } from '../api/formatted/vetecard'
import Box from '../components/Box'
import SubmitButton from '../components/SubmitButton'
import { type VetecardBasic } from '../typings'
import formatDate from '../utils/formatDate'
import queryClient from '../utils/queryClient'
import useAuth from '../utils/useAuth'

export interface FormValues {
  email: string
  documentDate?: string
}

interface FormTransferOwnershipProps {
  vetecardId: VetecardBasic['id']
  isRequest?: boolean
}

const FormTransferOwnership = ({ vetecardId, isRequest = false }: FormTransferOwnershipProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentUser } = useAuth()

  const { isLoading, mutate } = useMutation(
    ({ email, documentDate }: FormValues) =>
      transferOwnership(currentUser, vetecardId, isRequest, email, documentDate),
    {
      onSuccess: (vetecardId: VetecardBasic['id']) => {
        queryClient.invalidateQueries({ queryKey: ['vetecard-list'] })
        navigate('/vetecards')
        toast('Functionality not implemented yet.')
      },
    }
  )

  const onSubmit = (values: FormValues) => {
    mutate(values)
  }

  const isBreeder = currentUser.userType === 'breeder'

  return (
    <Form fields={['email', ...(isBreeder ? ['documentDate'] : [])]} allMandatory>
      <Input name="email" type="email" label={t('New owner email')} />
      {isBreeder && (
        <>
          <Box
            title={t('consentGneration.title')}
            description={t('consentGneration.description')}
            footer={
              <Input
                name="documentDate"
                type="date"
                label={t('documentDate')}
                initialValue={formatDate(new Date(), 'input')}
                noBottomGutter
              />
            }
            paddedFooter
          />
          <br />
        </>
      )}
      <SubmitButton variant="danger" onClick={onSubmit} loading={isLoading}>
        {t('transfer')}
      </SubmitButton>
    </Form>
  )
}

export default FormTransferOwnership
