import React from 'react'
import classNames from 'classnames'
import './style.sass'

interface IconButtonProps {
  children?: React.ReactNode
  component?: React.ElementType | string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  gray?: boolean
  className?: string
  [key: string]: any
}

const IconButton = ({
  children,
  component = 'button',
  onClick,
  gray,
  className,
  ...otherProps
}: IconButtonProps) => {
  const ComponentProp = component
  return (
    <ComponentProp
      className={classNames('icon-btn', { 'icon-btn--gray': gray }, className)}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        if (component === 'button') e.preventDefault()
        onClick && onClick(e)
      }}
      {...otherProps}
    >
      {children}
    </ComponentProp>
  )
}

export default IconButton
