import React from 'react'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Link } from '../../typings'
import './style.sass'

interface DropdownProps {
  links: Link[]
  overlay?: boolean
  condensed?: boolean
  anchor?: 'left' | 'right'
  noCaret?: boolean
  className?: string
  children: React.ReactNode
}

const Dropdown = ({
  links,
  overlay,
  condensed,
  anchor = 'left',
  noCaret,
  className,
  children,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div
      className={classNames(
        'dropdown',
        {
          'dropdown--open': isOpen,
          'dropdown--condensed': condensed,
          'dropdown--anchor-right': anchor === 'right',
          'dropdown--no-caret': noCaret,
        },
        className
      )}
    >
      <span className="dropdown__label" onClick={() => setIsOpen(!isOpen)}>
        {children} <FontAwesomeIcon icon={faChevronDown} className="dropdown__arrow" />
      </span>
      <div className="dropdown__inner">
        <nav className="dropdown__nav">
          {links.map(({ url, label, onClick }, index) =>
            url ? (
              <a href={url} className="dropdown__link" key={index}>
                {label}
              </a>
            ) : typeof onClick === 'function' ? (
              <div
                onClick={() => {
                  setIsOpen(false)
                  onClick()
                }}
                className="dropdown__link"
                key={index}
              >
                {label}
              </div>
            ) : (
              <div className="dropdown__link" key={index}>
                {label}
              </div>
            )
          )}
        </nav>
      </div>
      {isOpen && (
        <div
          className={classNames('dropdown__overlay', { 'dropdown__overlay--dark': overlay })}
          onClick={() => setIsOpen(false)}
        />
      )}
    </div>
  )
}

export default Dropdown
