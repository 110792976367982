import React from 'react'
import { useTranslation } from 'react-i18next'
import { type UserBasic, type UserShared, type VeterinarianBasic } from '../../typings'
import formatDate from '../../utils/formatDate'
import AddButton from '../AddButton'
import Button from '../Button'
import User from '../User'
import './style.sass'

interface UserListProps {
  users?: UserShared[]
  emptyMessage?: string
  onAdd?: () => void
  onClick?: (id: UserBasic['keycloakId'] | VeterinarianBasic['veterinarianId']) => void
  onManage?: (id: UserBasic['keycloakId'] | VeterinarianBasic['veterinarianId']) => void
}

const UserList = ({ users = [], emptyMessage = '', onAdd, onClick, onManage }: UserListProps) => {
  const { t } = useTranslation()
  return (
    <div className="user-list">
      {users.length
        ? users.map(({ keycloakId, veterinarianId, firstName, lastName, dateTo }) => {
            const today = new Date(new Date().setHours(0, 0, 0, 1))
            const isExpired = dateTo && dateTo < today
            const id = veterinarianId || keycloakId
            return (
              <div key={id} className="user-list__item">
                <User
                  firstName={firstName}
                  lastName={lastName}
                  secondary={
                    isExpired
                      ? t('hasExpired')
                      : dateTo
                      ? t('daysLeft', { days: formatDate(dateTo, 'daysLeft') })
                      : t('permantent')
                  }
                  disabled={isExpired}
                  onClick={() => onClick && onClick(id)}
                />
                {onManage && (
                  <Button size="xs" variant="link" onClick={() => onManage(id)}>
                    {t('manage')}
                  </Button>
                )}
              </div>
            )
          })
        : null}
      <div className="user-list__footer">
        {!users.length ? emptyMessage : null}
        {onAdd && (
          <AddButton size="xs" onClick={onAdd}>
            {t('addPerson')}
          </AddButton>
        )}
      </div>
    </div>
  )
}

export default UserList
