import React from 'react'
import classNames from 'classnames'
import './style.sass'

interface BlockProps {
  title?: React.ReactNode
  right?: React.ReactNode
  condensed?: boolean
  noMargin?: boolean
  children?: React.ReactNode
}

const Block = ({ title, right, noMargin, condensed, children }: BlockProps) => (
  <div
    className={classNames('block', { 'block--condensed': condensed, 'block--no-margin': noMargin })}
  >
    {title && (
      <h2 className="block__title">
        {title}
        {right && <div className="block__right">{right}</div>}
      </h2>
    )}
    <div className="block__content">{children}</div>
  </div>
)

export default Block
