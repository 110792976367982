import React from 'react'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import Button, { type ButtonProps } from '../Button'
import './style.sass'

interface AddButtonProps extends ButtonProps {
  padded?: boolean
}

const AddButton = ({ onClick, variant, padded, children, ...rest }: AddButtonProps) => (
  <div className={classNames('add-button', { 'add-button--padded': padded })}>
    <Button variant={variant} onClick={onClick} {...rest}>
      <FontAwesomeIcon icon={faPlus} /> {children}
    </Button>
  </div>
)

export default AddButton
