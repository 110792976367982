import React from 'react'
import { useMutation } from '@tanstack/react-query'
import Form, { Input } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { editPassport } from '../api/formatted/vetecard'
import SubmitButton from '../components/SubmitButton'
import { type EditPassportPayload, type VetecardFull } from '../typings'
import formatDate from '../utils/formatDate'
import queryClient from '../utils/queryClient'

interface FormEditPassportProps {
  ident: VetecardFull
  onSuccess?: () => void
}

const FormEditPassport = ({ ident, onSuccess }: FormEditPassportProps) => {
  const { t } = useTranslation()

  const { isLoading, mutate } = useMutation(
    (payload: EditPassportPayload) => editPassport(ident.id, payload),
    {
      onSuccess: (passport: VetecardFull['passport']) => {
        queryClient.setQueryData(['vetecard', ident.id], (oldData: VetecardFull | undefined) =>
          oldData
            ? {
                ...oldData,
                passport,
              }
            : undefined
        )
        toast.success(t('passportUpdated'))
        onSuccess && onSuccess()
      },
    }
  )

  const handleSubmit = (values: EditPassportPayload) => {
    mutate(values)
  }

  return (
    <Form fields={['number', 'deliveryDate', 'expirationDate']} allMandatory>
      <Input name="number" label={t('passportNumber')} initialValue={ident.passport?.number} />
      <Input
        name="deliveryDate"
        type="date"
        label={t('deliveryDate')}
        initialValue={
          ident.passport?.deliveryDate && formatDate(ident.passport.deliveryDate, 'input')
        }
      />
      <Input
        name="expirationDate"
        type="date"
        label={t('expirationDate')}
        initialValue={
          ident.passport?.expirationDate && formatDate(ident.passport.expirationDate, 'input')
        }
      />
      <SubmitButton onClick={handleSubmit} loading={isLoading}>
        {t('save')}
      </SubmitButton>
    </Form>
  )
}

export default FormEditPassport
