import React from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@tanstack/react-query'
import Form, { Row, Select } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import SubmitButton from '../components/SubmitButton'
import { type AgendaPayload } from '../typings'
import { useTranslatedOptions } from '../utils/helpers'
import useAuth from '../utils/useAuth'
import { getVetecards } from '../api/formatted/vetecard'

interface FormAgendaFiltersProps {
  onSubmit: (fields: AgendaPayload) => void
  values: AgendaPayload
}

const FormAgendaFilters = ({ onSubmit, values }: FormAgendaFiltersProps) => {
  const { t } = useTranslation()
  const speciesOptions = useTranslatedOptions('species')

  const { currentUser } = useAuth()

  const { data: { vetecards } = { vetecards: [] }, isFetching } = useQuery(
    ['agenda-vetecards'],
    () => getVetecards(currentUser)
    // TODO: Cancell if My vetecard does not need to be rendered
  )

  return (
    <Form fields={['species', 'vetecardId']}>
      <Row>
        <Select
          name="species"
          label={t('speciesLabel')}
          options={speciesOptions}
          initialValue={values.species}
        />
        <Select
          name="vetecardId"
          label={t('vetecard')}
          options={vetecards.map(item => ({ label: item.name, value: item.id }))}
          initialValue={values.vetecardId}
          suffix={isFetching ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : undefined}
        />
        <SubmitButton onClick={onSubmit}>{t('filter')}</SubmitButton>
      </Row>
    </Form>
  )
}

export default FormAgendaFilters
