import * as t from 'io-ts'
import { nullable } from 'io-ts/Type'

import { betweenLengthString } from '../../validation/string/between_length'
import { matchesRegexpString } from '../../validation/string/matches_regexp'

import { VeterinarianProfile } from './profile'


export const VeterinarianId = t.number
export type VeterinarianIdT = t.TypeOf<typeof VeterinarianId>

export const VeterinarianKey = t.type({id: VeterinarianId})
export type VeterinarianKeyT = t.TypeOf<typeof VeterinarianKey>

export const Veterinarian = t.type({
  id: VeterinarianId,
  firstName: t.string,
  lastName: t.string,
  professionalCode: t.string,
  profession: t.string,
})
export type VeterinarianT = t.TypeOf<typeof Veterinarian>

export const VeterinarianWithProfile = t.intersection([
  Veterinarian,
  t.type({
    profile: VeterinarianProfile,
  }),
])
export type VeterinarianWithProfileT = t.TypeOf<typeof VeterinarianWithProfile>

export const InstitutionProfile = t.type({
  siren: nullable(t.string),
  siret: nullable(t.string),
  finess: nullable(t.string),
  finessGeo: nullable(t.string),
  nationalStructureIdentifier: nullable(t.string),
  internalIdentifier: nullable(t.string),
})
export type InstitutionProfile = t.TypeOf<typeof InstitutionProfile>

export const InstitutionId = t.Integer
export type InstitutionIdT = t.TypeOf<typeof InstitutionId>

export const InstitutionKey = t.type({
  id: InstitutionId,
})
export type InstitutionKeyT = t.TypeOf<typeof InstitutionKey>

export const Institution = t.type({
  id: InstitutionId,
  name: t.string,
  type: t.string,
  code: t.string,
  phoneNumber1: nullable(t.string),
  phoneNumber2: nullable(t.string),
  address: nullable(t.string),
  city: nullable(t.string),
  country: nullable(t.string),
  postalCode: nullable(t.string),
})
export type Institution = t.TypeOf<typeof Institution>

export const InstitutionWithProfile = t.intersection([
  Institution,
  t.type({ profile: nullable(InstitutionProfile) }),
])
export type InstitutionWithProfileT = t.TypeOf<typeof InstitutionWithProfile >

export const VeterinarianWithInstitutions = t.intersection([
  Veterinarian,
  t.type({
    institutions: t.array(Institution),
  }),
])
export type VeterinarianWithInstitutionsT = t.TypeOf<typeof VeterinarianWithInstitutions>

export const sirenT = nullable(matchesRegexpString(/^\d{0,9}$/)) // FIXMEb
export const siretT = nullable(betweenLengthString(1,14)) // FIXME
export const finessT = nullable(matchesRegexpString(/^\d{0,9}$/))  // FIXME
