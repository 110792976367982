import React from 'react'
import { useTranslation } from 'react-i18next'
import { type UserFull } from '../../typings'
import Block from '../Block'
import ContactDetails from '../ContactDetails'
import EditLink from '../EditLink'
import Tags from '../Tags'
import User from '../User'
import './style.sass'

interface UserDetailsProps extends Omit<UserFull, 'keycloakId'> {
  canEdit?: boolean
}

const UserDetails = ({
  firstName,
  lastName,
  contact,
  profession,
  rpps,
  userType,
  canEdit,
}: UserDetailsProps) => {
  const { t } = useTranslation(['common', 'vetProfessions'])

  const tags = profession
    ? [
        ...(profession.category
          ? [t(`categories.${profession.category}`, { ns: 'vetProfessions' })]
          : []),
        ...(profession.field ? [t(`fields.${profession.field}`, { ns: 'vetProfessions' })] : []),
        ...(profession.specialization
          ? profession.specialization.map(value =>
              t(`specializations.${value}`, { ns: 'vetProfessions' })
            )
          : []),
      ]
    : []

  return (
    <div className="user-details">
      <div className="user-details__profile">
        <User
          firstName={firstName}
          lastName={lastName}
          secondary={t(`userTypes.${userType}`)}
          big
        />
        {canEdit && (
          <div className="user-details__action">
            <EditLink to={`/personal-info/edit`}>{t('edit')}</EditLink>
          </div>
        )}
        {contact && (
          <Block title={t('contact')} condensed>
            <ContactDetails {...contact} />
          </Block>
        )}
        {profession && (
          <Block title={t('profession')} condensed>
            {tags.length ? (
              <>
                <div className="user-details__label">{t('specialization')}:</div>
                <Tags centered tags={tags} />
              </>
            ) : null}
            {rpps && (
              <div className="user-details__small">
                {t('rppsNumber')}: {rpps}
              </div>
            )}
          </Block>
        )}
      </div>
    </div>
  )
}

export default UserDetails
