import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import BellSvg from '../../assets/icons/bell.svg'
import CashSvg from '../../assets/icons/cash.svg'
import FileAddSvg from '../../assets/icons/file-add.svg'
import { type Notification as NotificationT } from '../../typings'
import formatDate from '../../utils/formatDate'
import './style.sass'

interface NotificationProps {
  type: NotificationT['type']
  vetecard: NotificationT['vetecard']
  date?: NotificationT['date']
}

const Notification = ({ type, vetecard, date }: NotificationProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const iconByType = React.useMemo(() => {
    switch (type) {
      case 'document':
        return <FileAddSvg />
      case 'payment':
        return <CashSvg />
      default:
        return <BellSvg />
    }
  }, [type])

  const titleByType = React.useMemo(() => {
    switch (type) {
      case 'document':
        return t('haveNewDocument')
      case 'payment':
        return t('paymentAccepted')
      case 'reminder':
        return t('reminder.title')
      default:
        return t('notifications.single')
    }
  }, [type])

  const bodyByType = React.useMemo(() => {
    switch (type) {
      case 'document':
        return t('notifications.document', { vetecardName: vetecard.name })
      case 'payment':
        return t('notifications.payment', { vetecardName: vetecard.name })
      case 'reminder':
        return t('notifications.reminder', { vetecardName: vetecard.name, date: formatDate(date) })
      default:
        return t('')
    }
  }, [type])

  const handleClickByType = () => {
    switch (type) {
      case 'document':
        navigate(`/document-inbox`)
        break
      case 'payment':
        navigate(`/vetecards/${vetecard.id}`)
        break
      case 'reminder':
        navigate(`agenda?vetecardId=${vetecard.id}`)
        break
      default:
        break
    }
  }

  return (
    <div className="notification" onClick={handleClickByType}>
      <div className={classNames('notification__icon', `notification__icon--${type}`)}>
        {iconByType}
      </div>
      <div className="notification__body">
        <div className="notification__title">{titleByType}</div>
        {bodyByType}
      </div>
    </div>
  )
}

export default Notification
