import React from 'react'
import { useQuery } from '@tanstack/react-query'
import Keycloak, { KeycloakLoginOptions } from 'keycloak-js'
import api from '../api'
import { getNotifications } from '../api/formatted/notifications'
import { postPushRegistrations } from '../api/formatted/push'
import { getUserProfile } from '../api/formatted/user'
import { V0FetchVeterinarianProfileResponseT } from '../api/pure/veterinarian/profile/fetch'
import Spinner from '../components/Spinner'
import { type Notification, type UserFull, type UserType } from '../typings'

export const keycloak = new Keycloak({
  url: process.env.DASHBOARD_KEYCLOAK_URL,
  realm: process.env.DASHBOARD_KEYCLOAK_REALM as string,
  clientId: process.env.DASHBOARD_KEYCLOAK_ID as string,
})

interface AuthContextType {
  isLoading: boolean
  authenticated: boolean | undefined
  currentUser: UserFull
  scopes: string[] | []
  hasPermission: (scope: string) => boolean
  notifications: Notification[]
  register: (payload: KeycloakLoginOptions) => void
  login: () => void
  logout: () => void
}

const defaultUser = {
  keycloakId: '',
  userType: '',
} as UserFull

const AuthContext = React.createContext<AuthContextType>({
  isLoading: true,
  authenticated: undefined,
  currentUser: defaultUser,
  scopes: [],
  hasPermission: scope => false,
  notifications: [],
  register: () => {},
  login: () => {},
  logout: () => {},
})

type AuthProviderProps = {
  children: React.ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [authenticated, setAuthenticated] = React.useState(false)
  const [currentUser, setCurrentUser] = React.useState<UserFull>(defaultUser)
  const [scopes, setScopes] = React.useState<string[]>([])
  const { userType, keycloakId, rpps } = currentUser
  const { register, login, logout: keycloakLogout } = keycloak

  const { data: profile, refetch: fetchProfile } = useQuery({
    queryKey: ['current-user'],
    queryFn: () => getUserProfile(userType, keycloakId),
    enabled: false,
    onSuccess: () => setIsLoading(false),
  })

  const { data: notifications = [], refetch: fetchNotifications } = useQuery({
    queryKey: ['notifications'],
    queryFn: () => getNotifications({ keycloakId, userType }),
    enabled: false,
    onSuccess: () => setIsLoading(false),
  })

  React.useEffect(() => {
    keycloak.init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`,
    })
    keycloak.onReady = authenticated => {
      if (authenticated) {
        ;(async () => {
          const userProfile: any = await keycloak.loadUserProfile()
          const { id: keycloakId, email } = userProfile
          const { token, tokenParsed } = keycloak
          api.defaults.headers.common['Authorization'] = `Bearer ${token}`
          const clientUserTypes = [
            'individual',
            'petsitter', //FIXME: migrate to roles
            'veterinarian',
            'breeder',
            'association',
            'administrator',
          ]
          const parsedUserTypes = tokenParsed?.realm_access?.roles.filter(item =>
            clientUserTypes.includes(item)
          )
          const userType = (parsedUserTypes?.length ? parsedUserTypes[0] : '') as UserType
          const scope = tokenParsed?.scope
          setScopes(typeof scope === 'string' ? scope.split(' ') : [])

          let veterinarianId
          if (userType === 'veterinarian') {
            const { data } = (await api.get(`/0/veterinarian/${keycloakId}/profile`)) as {
              data: V0FetchVeterinarianProfileResponseT
            }
            veterinarianId = data?.id
          }

          // Map user object to the form usable on frontend.
          setCurrentUser({
            keycloakId,
            veterinarianId,
            userType,
            email,
            rpps: userProfile?.attributes?.rpps?.find((v: string) => v !== undefined),
          })
          setAuthenticated(true)

          // Ask for notification permission.
          Notification.requestPermission().then(perm => {
            if (perm === 'granted') {
              console.log('Notification permission granted')
              // Subscribe to Push API
              navigator.serviceWorker.getRegistration().then(reg => {
                if (reg) {
                  reg.pushManager.getSubscription().then(existingSubscription => {
                    if (!existingSubscription) {
                      console.log('No Push API subsriptions found. Creating new subscriptions.')
                      reg.pushManager
                        .subscribe({
                          userVisibleOnly: true,
                          applicationServerKey: process.env['SERVER_VAPID_PUBLIC_KEY'],
                        })
                        .then(subscription => {
                          if (subscription) {
                            postPushRegistrations({ keycloakId, userType }, subscription.toJSON())
                          }
                        })
                    } else {
                      console.log('Existing Push API subscriptions found: ', existingSubscription)
                    }
                  })
                }
              })
            } else {
              console.log('Notification permission not granted')
            }
          })
        })()
      } else {
        setIsLoading(false)
      }
    }
    keycloak.onTokenExpired = async () => {
      try {
        // 30 seconds treshold before the token expiration. Does it really works?
        const refreshed = await keycloak.updateToken(30)
        if (refreshed && keycloak.token) {
          api.defaults.headers.common['Authorization'] = `Bearer ${keycloak.token}`
          console.log('Token was successfully refreshed')
        } else {
          console.log('Token is still valid')
        }
      } catch (error) {
        console.log('Failed to refresh the token, or the session has expired')
      }
    }
  }, [])

  const logout = () => {
    // Unsubscribe from notifications.
    navigator.serviceWorker.getRegistration().then(reg => {
      if (reg) {
        reg.pushManager.getSubscription().then(existingSubscription => {
          if (existingSubscription) {
            existingSubscription
              .unsubscribe()
              .then(successful => {
                console.log('Unsubscribed from push notifications successfully:', successful)
                postPushRegistrations(currentUser, existingSubscription.toJSON(), true)
              })
              .catch(error => {
                console.error('Push notification unsubscribe failed:', error)
              })
          }
        })
      }
    })
    keycloakLogout()
  }

  const hasPermission = React.useCallback(
    (scope: string) => (!scopes.length ? false : scopes.includes(scope)),
    [scopes]
  )

  React.useEffect(() => {
    if (keycloakId || rpps) {
      fetchProfile()
      fetchNotifications()
    }
  }, [keycloakId, rpps])

  // After getting the profile via query, fill currentUser with the remaining data.
  React.useEffect(() => {
    if (profile) {
      setCurrentUser({
        ...currentUser,
        ...profile,
        keycloakId: currentUser.keycloakId,
      })
    }
  }, [profile])

  if (isLoading) return <Spinner variant="fullScreen" />
  console.log('scopes: ', scopes)

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        authenticated,
        currentUser,
        scopes,
        hasPermission,
        notifications,
        register,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => React.useContext(AuthContext)
export default useAuth
