import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { type VetecardBasic, type VetecardStack } from '../../typings'
import formatDate from '../../utils/formatDate'
import Count from '../Count'
import Picture from '../Picture'
import './style.sass'

interface VetecardCardProps extends VetecardBasic {}

const VetecardCard = ({
  id,
  name,
  species,
  birthDate,
  photo,
  isRequest,
  requestStatus,
}: VetecardCardProps) => {
  const { t } = useTranslation()
  const href = isRequest ? `/vetecard-requests/${id}` : `/vetecards/${id}`

  return (
    <Link to={href} className="vetecard-card">
      <Picture
        url={photo}
        species={species}
        isRequest={isRequest}
        requestStatus={requestStatus}
        className="vetecard-card__photo"
      />
      <div className="vetecard-card__text">
        <div className="vetecard-card__name">{name}</div>
        <div className="vetecard-card__data">
          {t(`species.${species}`)}, {formatDate(birthDate, 'year')}
        </div>
      </div>
    </Link>
  )
}

export const VetecardCardStack = ({ species, vetecards }: VetecardStack) => {
  const { t } = useTranslation()
  return (
    <Link
      to={`/vetecards?species=${species}`}
      className="vetecard-card vetecard-card--clickable vetecard-card--stack"
    >
      <Picture species={species} className="vetecard-card__photo" />
      <div className="vetecard-card__text">
        <div className="vetecard-card__name">
          {t(`species.${species}`)} <Count number={vetecards.length} />
        </div>
        <div className="vetecard-card__data">{t('showAll')}</div>
      </div>
    </Link>
  )
}

export default VetecardCard
