import React from 'react'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import Form, { Checkbox, FormActions, Input, TextArea } from 'react-form-component'
import { toast } from 'react-toastify'
import { addAnnouncement, editAnnouncement, removeAnnouncement } from '../api/formatted/news'
import Button from '../components/Button'
import SubmitButton from '../components/SubmitButton'
import { type Announcement, type AnnouncementPayload } from '../typings'
import formatDate from '../utils/formatDate'
import queryClient from '../utils/queryClient'

interface FormNewsProps {
  onSuccess?: () => void
  initialValues?: Announcement
}

const FormNews = ({ onSuccess, initialValues }: FormNewsProps) => {
  const { isLoading: addIsLoading, mutate: addMutate } = useMutation(
    (payload: AnnouncementPayload) => addAnnouncement(payload),
    {
      onSuccess: (announcement: Announcement) => {
        queryClient.setQueryData(['news'], (oldData: Announcement[] = []) => [
          announcement,
          ...oldData,
        ])
        toast.success(t('success'))
        onSuccess && onSuccess()
      },
    }
  )

  const { isLoading: editIsLoading, mutate: editMutate } = useMutation(
    (payload: AnnouncementPayload) => editAnnouncement(initialValues?.id || 0, payload),
    {
      onSuccess: (announcement: Announcement) => {
        queryClient.setQueryData(['news'], (oldData: Announcement[] = []) =>
          oldData.map(item => (item.id === announcement.id ? announcement : item))
        )
        toast.success(t('success'))
        onSuccess && onSuccess()
      },
    }
  )
  const { isLoading: removeIsLoading, mutate: removeMutate } = useMutation(
    () => removeAnnouncement(initialValues?.id || 0),
    {
      onSuccess: (id: Announcement['id']) => {
        queryClient.setQueryData(
          ['news'],
          (oldData?: Announcement[]) => oldData && oldData.filter(item => item.id !== id)
        )
        toast.success(t('success'))
        onSuccess && onSuccess()
      },
    }
  )

  const handleSubmit = (values: AnnouncementPayload) => {
    if (initialValues) {
      editMutate(values)
    } else {
      addMutate(values)
    }
  }

  return (
    <Form
      fields={['title', 'content', 'author', 'createdAt', 'isArchived']}
      mandatory={['title', 'content', 'author', 'createdAt']}
    >
      <Input name="title" label={t('title')} initialValue={initialValues?.title} />
      <TextArea name="content" label={t('content')} initialValue={initialValues?.content} />
      <Input
        name="author"
        label={t('author')}
        initialValue={initialValues?.author || 'VET e-Card Team'}
      />
      <Input
        name="createdAt"
        label={t('createdAt')}
        type="date"
        initialValue={formatDate(initialValues?.createdAt || new Date(), 'input')}
      />
      <Checkbox
        name="isPublished"
        text={t('published')}
        initialValue={initialValues?.isPublished !== undefined ? initialValues.isPublished : true}
      />
      <FormActions>
        <SubmitButton onClick={() => removeMutate()} variant="ghost" loading={removeIsLoading}>
          {t('delete')}
        </SubmitButton>
        <Button onClick={() => onSuccess && onSuccess()} variant="ghost">
          {t('cancel')}
        </Button>
        <SubmitButton onClick={handleSubmit} loading={editIsLoading || addIsLoading}>
          {t('save')}
        </SubmitButton>
      </FormActions>
    </Form>
  )
}

export default FormNews
