import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import Button from '../Button'
import './style.sass'

interface AlertProps {
  type?: 'success' | 'warning' | 'error' | 'info'
  primary?: React.ReactNode
  secondary?: React.ReactNode
  btnLabel?: string
  btnUrl?: string
  onClick?: () => void
  noMargin?: boolean
}

const Alert = ({
  type = 'info',
  primary,
  secondary,
  btnLabel,
  btnUrl,
  onClick,
  noMargin,
}: AlertProps) => {
  const hasBtn = btnLabel && (btnUrl || onClick)
  return (
    <div
      className={classNames('alert', `alert--${type}`, {
        'alert--has-btn': hasBtn,
        'alert--has-secondary': secondary,
        'alert--no-margin': noMargin,
      })}
    >
      <div className="alert__primary">{primary}</div>
      {secondary && <div className="alert__secondary">{secondary}</div>}
      <div className="alert__btn">
        {hasBtn &&
          (btnUrl ? (
            <Button component={Link} to={btnUrl} size="sm" variant="secondary">
              {btnLabel}
            </Button>
          ) : (
            <Button onClick={onClick} size="sm" variant="secondary">
              {btnLabel}
            </Button>
          ))}
      </div>
    </div>
  )
}

export default Alert
