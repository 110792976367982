import React from 'react'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { editFile } from '../api/formatted/files'
import FormFile, { type FormFileValues } from '../forms/FormFile'
import {
  type EditFilePayload,
  type FileCategory,
  type FileEntity,
  type VetecardBasic,
} from '../typings'
import queryClient from '../utils/queryClient'

interface EditFileContainerProps {
  vetecardId: VetecardBasic['id']
  category: FileCategory
  initialValues?: Partial<FileEntity>
  onSuccess?: () => void
}

const EditFileContainer = ({
  vetecardId,
  category,
  initialValues,
  onSuccess,
}: EditFileContainerProps) => {
  const { t } = useTranslation()

  const { isLoading, mutate } = useMutation((payload: EditFilePayload) => editFile(payload), {
    onSuccess: (file: FileEntity) => {
      queryClient.setQueryData(
        ['vetecard', vetecardId, 'files', category],
        (oldData: FileEntity[] = []) => oldData.map(item => (item.id === file.id ? file : item))
      )
      toast.success(t('fileUpdated'))
      onSuccess && onSuccess()
    },
  })

  const handleSubmit = (values: FormFileValues) => {
    mutate({
      ...values,
      id: initialValues?.id || 0,
      category,
      vetecardId,
    })
  }

  return (
    <FormFile
      category={category}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  )
}

export default EditFileContainer
