import React from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import timezone from 'dayjs/plugin/timezone'
import { Calendar as BigCalendar, type SlotInfo, dayjsLocalizer } from 'react-big-calendar'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import FormEvent from '../../forms/FormEvent'
import { type Event } from '../../typings'
import formatDate from '../../utils/formatDate'
import Box from '../Box'
import Modal from '../Modal'
import './style.sass'

const localizer = dayjsLocalizer(dayjs)

interface CalendarProps {
  events?: Event[]
}

const Calendar = ({ events = [] }: CalendarProps) => {
  const [selecedEvent, setselecedEvent] = React.useState<Event | undefined>()
  const [isInitialized, setIsInitialized] = React.useState(false)
  const [newEventDate, setNewEventDate] = React.useState<Date | undefined>()
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const navigate = useNavigate()

  React.useEffect(() => {
    dayjs.extend(timezone)
    dayjs.locale('fr')
    setIsInitialized(true)
  }, [language])

  const messages = React.useMemo(
    () => ({
      date: t('calendar.date'),
      time: t('calendar.time'),
      event: t('calendar.event'),
      allDay: t('calendar.allDay'),
      week: t('calendar.week'),
      work_week: t('calendar.work_week'),
      day: t('calendar.day'),
      month: t('calendar.month'),
      previous: t('calendar.previous'),
      next: t('calendar.next'),
      yesterday: t('calendar.yesterday'),
      tomorrow: t('calendar.tomorrow'),
      today: t('calendar.today'),
      agenda: t('calendar.agenda'),
    }),
    [language]
  )

  const localizedEvents = React.useMemo(
    () =>
      events.map(event => {
        const { type, title, start, end, vetecardId, vetecardName } = event
        // Keep in mind that events without the title are ommited from display.
        switch (type) {
          case 'vaccineStart':
            return {
              ...event,
              title: t('events.vaccineStart', { vetecardName }),
              description: t('events.vaccineStartDetails', {
                vetecardName,
                type: title,
                date: formatDate(start),
              }),
              onClick: () => navigate(`/vetecards/${vetecardId}#vaccinations`),
            }
          case 'vaccineReminder':
          case 'vaccineReminderApproaching':
            return {
              ...event,
              title: t('events.vaccineReminder', { vetecardName, date: formatDate(end) }),
              description: t('events.vaccineReminderDesc', {
                vetecardName,
                type: title,
                date: formatDate(end),
              }),
              onClick: () => navigate(`/vetecards/${vetecardId}#vaccinations`),
            }
          case 'vaccineReminderExpired':
            return {
              ...event,
              title: t('events.vaccineReminderLate', { vetecardName, date: formatDate(start) }),
              description: t('events.vaccineReminderLateDesc', {
                vetecardName,
                type: title,
                date: formatDate(start),
              }),
              onClick: () => navigate(`/vetecards/${vetecardId}#vaccinations`),
            }
          case 'treatment':
            return {
              ...event,
              title: t('events.treatment', { vetecardName }),
              description: t('events.treatmentDesc', {
                vetecardName,
                file: title,
              }),
              onClick: () => navigate(`/vetecards/${vetecardId}#vaccinations`),
            }
          case 'appointment':
            return {
              ...event,
              description: t('events.appointmentDesc', { date: formatDate(start) }),
            }
          default:
            return event
        }
      }),
    [events]
  )

  const handleSelectSlot = React.useCallback(
    ({ start }: SlotInfo) => {
      setNewEventDate(start)
    },
    [setNewEventDate]
  )

  if (!isInitialized) return null

  return (
    <div className="calendar">
      <BigCalendar
        localizer={localizer}
        messages={messages}
        events={localizedEvents}
        eventPropGetter={(event, start, end, isSelected) => ({
          event,
          start,
          end,
          isSelected,
          className: event.type ? `calendar__event--${event.type}` : undefined,
        })}
        onSelectEvent={event => setselecedEvent(event)}
        onSelectSlot={handleSelectSlot}
        selectable
      />
      {selecedEvent && (
        <Modal isOpen onClose={() => setselecedEvent(undefined)}>
          <Box
            title={selecedEvent.description || ''}
            btnLabel={selecedEvent.onClick && t('details')}
            onClick={selecedEvent.onClick}
          />
        </Modal>
      )}
      {newEventDate && (
        <Modal title={t('addNewEvent')} isOpen onClose={() => setNewEventDate(undefined)}>
          <FormEvent date={newEventDate} onSuccess={() => setNewEventDate(undefined)} />
        </Modal>
      )}
    </div>
  )
}

export default Calendar
