import React from 'react'
import { useTranslation } from 'react-i18next'
import FormFile, { type FormFileValues } from '../forms/FormFile'
import FormSelectFileCategory from '../forms/FormSelectFileCategory'
import FormVaccination, { type FormVaccinationValues } from '../forms/FormVaccination'
import {
  type FileCategory,
  type FileEntity,
  type InboxDocument,
  type IntegrateFilePayload,
  type IntegrateVaccinationPayload,
  type Vaccination,
  type VaccinationCategory,
} from '../typings'
import Block from './Block'
import Field from './Field'
import FilePreview from './FilePreview'
import Grid from './Grid'

interface InboxFileProps {
  document?: InboxDocument
  onFileIntegrate: (values: IntegrateFilePayload) => void
  onVaccinationIntegrate: (values: IntegrateVaccinationPayload) => void
  isLoading: boolean
}

const InboxFile = ({
  document,
  onFileIntegrate,
  onVaccinationIntegrate,
  isLoading,
}: InboxFileProps) => {
  const { t } = useTranslation()
  const [category, setCategory] = React.useState<FileCategory | VaccinationCategory | undefined>(
    undefined
  )

  const isVaccination = category === 'mandatory' || category === 'other'

  const handleFileSubmit = (values: FormFileValues) => {
    if (document && category) {
      const { message, id, vetecard } = document
      onFileIntegrate({
        ...values,
        category: category as FileCategory,
        messageId: message.id,
        attachmentId: id,
        vetecardId: vetecard.id,
      })
    }
  }

  React.useEffect(() => {
    setCategory(document?.initialValues.category)
  }, [document])

  const handleVaccinationSubmit = (values: FormVaccinationValues) => {
    if (document && category) {
      const { message, id, vetecard, name } = document
      onVaccinationIntegrate({
        ...values,
        name,
        category: category as VaccinationCategory,
        messageId: message.id,
        attachmentId: id,
        vetecardId: vetecard.id,
      })
    }
  }

  if (!document) return null

  return (
    <Grid>
      <div>
        <Block title={t('message')} condensed>
          <Grid>
            <div>
              <Field inlineLabel label={t('vetecard')}>
                {document.vetecard.name}
              </Field>
              <Field inlineLabel label={t('from')}>
                {document.message.email}
              </Field>
            </div>
            <div>
              <Field inlineLabel label={t('subject')}>
                {document.message.subject}
              </Field>
              <Field inlineLabel label={t('content')}>
                {document.message.content}
              </Field>
            </div>
          </Grid>
        </Block>
        <Block title={t('categorySelect')} condensed>
          <FormSelectFileCategory
            initialValue={document.initialValues.category}
            onSelect={category => setCategory(category)}
          />
        </Block>
        {category && (
          <Block title={t('enterFileMetadata')} condensed>
            {isVaccination ? (
              <FormVaccination
                initialValues={document.initialValues as Partial<Vaccination>}
                onSubmit={handleVaccinationSubmit}
                isLoading={false}
              />
            ) : (
              <FormFile
                category={category as FileCategory}
                initialValues={document.initialValues as Partial<FileEntity>}
                onSubmit={handleFileSubmit}
                isLoading={isLoading}
              />
            )}
          </Block>
        )}
      </div>
      <Block title={t('filePreview')} condensed>
        <FilePreview name={document.name} url={document.url} mimeType={document.type} />
      </Block>
    </Grid>
  )
}

export default InboxFile
