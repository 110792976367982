import React from 'react'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.sass'

interface AddBlockProps {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
  children: React.ReactNode
}

const AddBlock = ({ onClick, children }: AddBlockProps) => (
  <div className="add-block" onClick={onClick}>
    <FontAwesomeIcon icon={faPlus} /> {children}
  </div>
)

export default AddBlock
