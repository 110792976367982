import React from 'react'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { type Profession } from '../../typings'
import formatName from '../../utils/formatName'
import useGoBack from '../../utils/useGoBack'
import Picture from '../Picture'
import './style.sass'

interface VeterinarianHeadProps {
  firstName: string
  lastName: string
  profession?: Profession
}

const VeterinarianHead = ({ firstName, lastName, profession }: VeterinarianHeadProps) => {
  const { t } = useTranslation(['common', 'vetProfessions'])
  const goBack = useGoBack()

  const tags = profession
    ? [
        ...(profession.category
          ? [t(`categories.${profession.category}`, { ns: 'vetProfessions' })]
          : []),
        ...(profession.field ? [t(`fields.${profession.field}`, { ns: 'vetProfessions' })] : []),
        ...(profession.specialization
          ? profession.specialization.map(value =>
              t(`specializations.${value}`, { ns: 'vetProfessions' })
            )
          : []),
      ]
    : []

  return (
    <div className="veterinarian-head">
      <Picture species="vet" standalone square className="veterinarian-head__graphic" />
      <div className="veterinarian-head__info">
        <button onClick={goBack} className="veterinarian-head__back">
          <FontAwesomeIcon icon={faChevronLeft} /> {t('backToVetecard')}
        </button>
        <div className="veterinarian-head__name">{formatName(firstName, lastName)}</div>
        {profession && <div className="veterinarian-head__profession">{tags.join(', ')}.</div>}
      </div>
    </div>
  )
}

export default VeterinarianHead
