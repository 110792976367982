import React from 'react'
import Button from '../Button'
import Spinner from '../Spinner'
import { useTranslation } from 'react-i18next'
import './style.sass'

interface LoadMoreProps {
  loading: boolean
  show?: boolean
  onClick: () => void
}

const LoadMore = ({ loading, show, onClick }: LoadMoreProps) => {
  const { t } = useTranslation()
  if (loading) return <Spinner variant="block" />
  if (show)
    return (
      <div className="load-more">
        <Button onClick={onClick}>{t('loadMore')}...</Button>
      </div>
    )
  return null
}

export default LoadMore
