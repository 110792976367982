import { flow, pipe } from 'fp-ts/function'
import { sequence } from 'fp-ts/Array'
import { left, right, flatMap, fromEither, mapLeft, TaskEither } from 'fp-ts/TaskEither'
import {
  flatMap as flatMapEither,
  isLeft as isLeftEither,
  left as leftEither,
  map as mapEither,
  mapLeft as mapLeftEither,
  right as rightEither,
  Applicative as ApplicativeEither,
} from 'fp-ts/Either'

import * as t from 'io-ts'
import { failure } from 'io-ts/PathReporter'

import { Api, ApiError, ApiResponse } from '../../..'

import { VetecardOwnerId, VetecardOwnerType } from '../../owner/vetecard'
import {
  OwnerVetecardInboxFileTemplate,
  OwnerVetecardInboxFileTemplateInvoice,
  OwnerVetecardInboxFileTemplateMandatoryVaccination,
  OwnerVetecardInboxFileTemplateMedical,
  OwnerVetecardInboxFileTemplateNone,
  OwnerVetecardInboxFileTemplateOtherVaccination,
  OwnerVetecardInboxFileTemplateTreatment,
  OwnerVetecardInboxMessageFile,
  OwnerVetecardInboxMessageKey,
} from '.'
import { DateFromISOString } from 'io-ts-types'
import { nullable } from 'io-ts/lib/Type'
import { Vetecard } from '../../vetecard'


export const ListOwnerInboxRequestParametersFilter = t.type({
  file: t.type({
    archived: t.boolean,
  }),
})
export type ListOwnerInboxRequestParametersFilterT = t.TypeOf<typeof ListOwnerInboxRequestParametersFilter>

export const ListOwnerInboxRequestParameters = t.type({
  ownerType: VetecardOwnerType,
  ownerId: VetecardOwnerId,
  filter: ListOwnerInboxRequestParametersFilter,
})
export type ListOwnerInboxRequestParametersT = t.TypeOf<typeof ListOwnerInboxRequestParameters>

export const ListOwnerInboxRequest = ListOwnerInboxRequestParameters
export type ListOwnerInboxRequestT = t.TypeOf<typeof ListOwnerInboxRequest>


export const listOwnerVetecardInboxAxiosTask = (api: Api) =>
    ({ownerType,ownerId,filter}: ListOwnerInboxRequestT ): TaskEither<ApiError, ApiResponse> =>
      () =>
        api.get(
          `/${encodeURIComponent(0)}/owner/${encodeURIComponent(ownerType)}/${encodeURIComponent(ownerId)}/inbox/messages/${encodeURIComponent(JSON.stringify(filter))}`
        ).then(rightEither,leftEither)

export const listOwnerInboxTask = (api: Api) =>
  flow(
    listOwnerVetecardInboxAxiosTask(api),
    mapLeft<ApiError,ListOwnerInboxResponseUnknownT>(
      error => ({type: ListOwnerInboxResponseTypeUnknown.value,error})
    ),
    flatMap(
      ({status,data}): TaskEither<ListOwnerInboxResponseFailureT,ListOwnerInboxResponseSuccessT> =>
        status === 400 ?
          left({type: ListOwnerInboxResponseTypeBadRequest.value,error: data}) :
            status === 200 ?
              right({type: ListOwnerInboxResponseTypeList.value,list: data}) :
              left({type: ListOwnerInboxResponseTypeUnknown.value,error: data})
    ),
    flatMap(
      flow(
        ListOwnerInboxResponseList.decode,
        flatMapEither(
          ({list,...response}) => pipe(
            sequence(ApplicativeEither)(
              list.map(
                ({files,...entry}) => pipe(
                  sequence(ApplicativeEither)(
                    files.map(
                      ({template,...file}) => pipe(
                        ListOwnerInboxFileTemplateMandatoryVaccination.decode(template),
                        maybeTemplate => isLeftEither(maybeTemplate) ? ListOwnerInboxFileTemplateOtherVaccination.decode(template) : maybeTemplate,
                        maybeTemplate => isLeftEither(maybeTemplate) ? ListOwnerInboxFileTemplateInvoice.decode(template) : maybeTemplate,
                        maybeTemplate => isLeftEither(maybeTemplate) ? ListOwnerInboxFileTemplateMedical.decode(template) : maybeTemplate,
                        maybeTemplate => isLeftEither(maybeTemplate) ? ListOwnerInboxFileTemplateTreatment.decode(template) : maybeTemplate,
                        maybeTemplate => isLeftEither(maybeTemplate) ? ListOwnerInboxFileTemplateNone.decode(template) : maybeTemplate,
                        mapEither(
                          template => ({
                            template: template,
                            ...file,
                          })
                        )
                      )
                    )
                  ),
                  mapEither(files => ({
                    files,
                    ...entry,
                  }))
                )
              )
            ),
            mapEither(
              list => ({
                list,
                ...response,
              })
            )
          )
        ),
        mapLeftEither(
          errors => ({
            type: ListOwnerInboxResponseTypeUnknown.value,
            error: new Error(failure(errors).join('\n')),
          })
        ),
        fromEither
      )
    )
  )

export const listOwnerInbox = (api: Api) => {
  const handler = listOwnerInboxTask(api)
  return async (request: ListOwnerInboxRequestT) => {
    const response = await handler(request)()
    if (isLeftEither(response))
    {
      throw response.left.error
    }
    else
    {
      return response.right.list
    }
  }
}

export const ListOwnerInboxResponseTypeBadRequest = t.literal('bad_request')
export const ListOwnerInboxResponseTypeUnknown = t.literal('unknown_error')
export const ListOwnerInboxResponseTypeBadResponse = t.literal('bad_response')
export const ListOwnerInboxResponseTypeList = t.literal('list')
export const ListOwnerInboxResponseTypes = t.union([
  ListOwnerInboxResponseTypeBadRequest,
  ListOwnerInboxResponseTypeUnknown,
  ListOwnerInboxResponseTypeList,
  ListOwnerInboxResponseTypeBadResponse,
])

export const ListOwnerInboxResponseBadRequest = t.type({
  type: ListOwnerInboxResponseTypeBadRequest,
  error: t.unknown,
})
export type ListOwnerInboxResponseBadRequestT = t.TypeOf<typeof ListOwnerInboxResponseBadRequest>

export const ListOwnerInboxResponseUnknown = t.type({
  type: ListOwnerInboxResponseTypeUnknown,
  error: t.unknown,
})
export type ListOwnerInboxResponseUnknownT = t.TypeOf<typeof ListOwnerInboxResponseUnknown>

export const ListOwnerInboxFileTemplateNone = OwnerVetecardInboxFileTemplateNone
export type ListOwnerInboxFileTemplateNoneT = t.TypeOf<typeof ListOwnerInboxFileTemplateNone>

export const ListOwnerInboxFileTemplateTreatment = OwnerVetecardInboxFileTemplateTreatment
export type ListOwnerInboxFileTemplateTreatmentT = t.TypeOf<typeof ListOwnerInboxFileTemplateTreatment>

export const ListOwnerInboxFileTemplateMedical = OwnerVetecardInboxFileTemplateMedical
export type ListOwnerInboxFileTemplateMedicalT = t.TypeOf<typeof ListOwnerInboxFileTemplateMedical>

export const ListOwnerInboxFileTemplateInvoice = OwnerVetecardInboxFileTemplateInvoice
export type ListOwnerInboxFileTemplateInvoiceT = t.TypeOf<typeof ListOwnerInboxFileTemplateInvoice>

export const ListOwnerInboxFileTemplateMandatoryVaccination = t.intersection([
  OwnerVetecardInboxFileTemplateMandatoryVaccination.types[0],
  t.partial({
    storageKey: OwnerVetecardInboxFileTemplateMandatoryVaccination.types[1].props['storageKey'],
    type: OwnerVetecardInboxFileTemplateMandatoryVaccination.types[1].props['type'],
    manufacturer: OwnerVetecardInboxFileTemplateMandatoryVaccination.types[1].props['manufacturer'],
    batchNumber: OwnerVetecardInboxFileTemplateMandatoryVaccination.types[1].props['batchNumber'],
    vaccinationDate: OwnerVetecardInboxFileTemplateMandatoryVaccination.types[1].props['vaccinationDate'],
    validFrom: OwnerVetecardInboxFileTemplateMandatoryVaccination.types[1].props['validFrom'],
    validTo: OwnerVetecardInboxFileTemplateMandatoryVaccination.types[1].props['validTo'],
    signedBy: nullable(t.string),
  }),
])
export type ListOwnerInboxFileTemplateMandatoryVaccinationT = t.TypeOf<typeof ListOwnerInboxFileTemplateMandatoryVaccination>

export const ListOwnerInboxFileTemplateOtherVaccination = t.intersection([
  OwnerVetecardInboxFileTemplateOtherVaccination.types[0],
  t.partial({
    storageKey: OwnerVetecardInboxFileTemplateOtherVaccination.types[1].props['storageKey'],
    type: OwnerVetecardInboxFileTemplateOtherVaccination.types[1].props['type'],
    manufacturer: OwnerVetecardInboxFileTemplateOtherVaccination.types[1].props['manufacturer'],
    batchNumber: OwnerVetecardInboxFileTemplateOtherVaccination.types[1].props['batchNumber'],
    vaccinationDate: OwnerVetecardInboxFileTemplateOtherVaccination.types[1].props['vaccinationDate'],
    validFrom: OwnerVetecardInboxFileTemplateOtherVaccination.types[1].props['validFrom'],
    validTo: OwnerVetecardInboxFileTemplateOtherVaccination.types[1].props['validTo'],
    signedBy: nullable(t.string),
  }),
])
export type ListOwnerInboxFileTemplateOtherVaccinationT = t.TypeOf<typeof ListOwnerInboxFileTemplateOtherVaccination>

export const ListOwnerInboxFileTemplate = t.union([
  ListOwnerInboxFileTemplateNone,
  ListOwnerInboxFileTemplateTreatment,
  ListOwnerInboxFileTemplateMedical,
  ListOwnerInboxFileTemplateInvoice,
  ListOwnerInboxFileTemplateMandatoryVaccination,
  ListOwnerInboxFileTemplateOtherVaccination,
])
export type ListOwnerInboxFileTemplateT = t.TypeOf<typeof ListOwnerInboxFileTemplate>

export const ListOwnerInboxResponseListEntry = t.intersection([
  OwnerVetecardInboxMessageKey,
  t.type({
    received: DateFromISOString,
    sender: t.type({
      email: t.string,
    }),
    subject: nullable(t.string),
    content: t.type({
      text: nullable(t.string),
      html: nullable(t.string),
    }),
    files: t.array(
      t.intersection([
        OwnerVetecardInboxMessageFile,
        t.type({
          url: t.string,
          template: OwnerVetecardInboxFileTemplate,
        }),
      ])
    ),
    vetecard: Vetecard,
  }),
])
export type ListOwnerInboxResponseListEntryT = t.TypeOf<typeof ListOwnerInboxResponseListEntry>

export const ListOwnerInboxResponseList = t.type({
  type: ListOwnerInboxResponseTypeList,
  list: t.array(ListOwnerInboxResponseListEntry),
})
export type ListOwnerInboxResponseListT = t.TypeOf<typeof ListOwnerInboxResponseList>

export const ListOwnerInboxResponseBadResponse = t.type({
  type: ListOwnerInboxResponseTypeBadResponse,
  error: t.unknown,
})
export type ListOwnerInboxResponseBadResponseT = t.TypeOf<typeof ListOwnerInboxResponseBadRequest>

export const ListOwnerInboxResponse = t.union([
  ListOwnerInboxResponseBadRequest,
  ListOwnerInboxResponseUnknown,
  ListOwnerInboxResponseList,
  ListOwnerInboxResponseBadResponse,
])
export type ListOwnerInboxResponseT = t.TypeOf<typeof ListOwnerInboxResponse>

export const ListOwnerInboxResponseSuccess = ListOwnerInboxResponseList
export type ListOwnerInboxResponseSuccessT = t.TypeOf<typeof ListOwnerInboxResponseSuccess>

export const ListOwnerInboxResponseFailure = t.union([
  ListOwnerInboxResponseBadRequest,
  ListOwnerInboxResponseUnknown,
  ListOwnerInboxResponseBadResponse,
])
export type ListOwnerInboxResponseFailureT = t.TypeOf<typeof ListOwnerInboxResponseFailure>
