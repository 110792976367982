import React from 'react'
import classNames from 'classnames'
import './style.sass'

interface PanelProps {
  title?: React.ReactNode
  onTitleClick?: () => void
  footer?: React.ReactNode
  noPadding?: boolean
  scrollIntoView?: boolean
  className?: string
  children?: React.ReactNode
}

const Panel = ({
  title,
  onTitleClick,
  footer,
  noPadding,
  scrollIntoView,
  className,
  children,
}: PanelProps) => {
  const divRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (scrollIntoView) divRef.current?.scrollIntoView() // TODO: Make it working
  }, [scrollIntoView])

  return (
    <div
      className={classNames('panel', className, {
        'panel--no-padding': noPadding,
        'panel--hoverable': onTitleClick && !children,
      })}
      ref={divRef}
    >
      {title && (
        <div className="panel__title" onClick={onTitleClick}>
          {title}
        </div>
      )}
      {children && <div className="panel__content">{children}</div>}
      {footer && <div className="panel__footer">{footer}</div>}
    </div>
  )
}

export default Panel
