import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import validLanguages from './utils/validLanguages'

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: ['common'],
    // lng: 'en', // Should be disabled, as language detector is used.
    fallbackLng: 'fr', // Must stay, otherwise plugin fallbacks for 'dev' language.
    detection: {
      // LanguageDetector config https://github.com/i18next/i18next-browser-languageDetector#detector-options
      // order: ['localStorage', 'navigator'],
      convertDetectedLanguage: lng => {
        const lang = lng.split('-')[0]
        if (Object.keys(validLanguages).includes(lang)) return lang
        return 'fr' // Fallback language
      },
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })
