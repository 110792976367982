import React from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { activateVetecardRequest, getVetecardRequests } from '../api/formatted/vetecardRequests'
import Button from '../components/Button'
import Content from '../components/Content'
import Loader from '../components/Loader'
import Panel from '../components/Panel'
import Table from '../components/Table'
import Title from '../components/Title'
import { type VetecardAdvanced } from '../typings'
import formatDate from '../utils/formatDate'
import formatName from '../utils/formatName'
import queryClient from '../utils/queryClient'
import useAuth from '../utils/useAuth'

const ActivateVetecards = () => {
  const { t } = useTranslation()

  const { currentUser } = useAuth()

  const {
    data = [],
    isFetching,
    isError,
  } = useQuery(['vetecard-requests'], () => getVetecardRequests(currentUser))

  console.log('data: ', data)
  const { isLoading, mutate } = useMutation(
    (id: VetecardAdvanced['id']) => activateVetecardRequest(id),
    {
      onSuccess: (id: VetecardAdvanced['id']) => {
        toast.success(t('vetecardActivated'))
        queryClient.setQueryData(['vetecard-requests'], (oldData: VetecardAdvanced[] = []) =>
          oldData.filter(item => item.id !== id)
        )
      },
    }
  )

  const dataWithActivation = React.useMemo(
    () =>
      data.map(({ id, species, createdAt, identifier, birthDate, owner, ...item }) => ({
        ...item,
        species: t(`species.${species}`),
        createdAt: formatDate(createdAt),
        identification: (
          <>
            {identifier.number
              ? `${t(`identifierType.${identifier.type}`)} (${t(
                  `identifierLocation.${identifier.location}`
                )}): ${identifier.number}`
              : t(`identifierType.${identifier.type}`)}
            <br />
            {t('birthDate')}: {formatDate(birthDate)}
          </>
        ),
        owner: (
          <>
            <Link to={`/user-profile/${owner.keycloakId}`}>
              {formatName(owner.firstName, owner.lastName)}
            </Link>
            {owner?.institutions?.length && <div>{owner.institutions[0].name}</div>}
          </>
        ),
        activation: (
          <Button onClick={() => mutate(id)} variant="dark" disabled={isLoading} data-cy="activate">
            {t('activate')}
          </Button>
        ),
      })),
    [data, t]
  )

  return (
    <Content wide>
      <Title>{t('activateVetecardRequests')}</Title>
      <Panel>
        <Loader loading={isFetching} error={isError}>
          <Table
            labels={[
              { name: t('name'), field: 'name' },
              { name: t('speciesLabel'), field: 'species' },
              { name: t('requestedOn'), field: 'createdAt' },
              { name: t('identification'), field: 'identification' },
              { name: t('owner'), field: 'owner' },
              { field: 'activation' },
            ]}
            data={dataWithActivation}
            emptyText={t('noResults.requestsActivation')}
            toRight
          />
        </Loader>
      </Panel>
      <Button
        size="lg"
        onClick={() => {
          navigator.serviceWorker.getRegistrations().then(registrations => {
            console.log('registrations: ', registrations)
            if (registrations.length) {
              registrations[0].showNotification('Hello world notification', {
                body: 'Notification generated using showNotification() function.',
                // tag: 'hello',
                icon: '/assets/android-chrome-48x48.png',
              })
            }
          })
          // new Notification('Hello world notification', {
          //   body: 'Example notification from VET e-Card.',
          //   // tag: 'hello',
          //   icon: '/assets/android-chrome-48x48.png',
          // })
        }}
      >
        Trigger notification
      </Button>
    </Content>
  )
}

export default ActivateVetecards
