import React from 'react'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { type UserBasic } from '../../typings'
import formatName from '../../utils/formatName'
import Avatar from '../Avatar'
import Tooltip from '../Tooltip'
import './style.sass'

interface AvatarsProps {
  data: UserBasic[]
}

const Avatars = ({ data }: AvatarsProps) => {
  const { t } = useTranslation()
  const [showRest, setshowRest] = React.useState(false)
  const shouldExpand = data.length > 4
  const rest = shouldExpand ? data.splice(0, 2) : []
  const firstThree = data

  return (
    <div className="avatars">
      {firstThree.length ? (
        <>
          {firstThree.map(({ veterinarianId, keycloakId, firstName, lastName }) => {
            const name = formatName(firstName, lastName)
            return (
              <Tooltip key={veterinarianId || keycloakId} message={name} direction="tl">
                <Avatar bordered size="sm" name={name} />
              </Tooltip>
            )
          })}
          {rest.length ? (
            <>
              {!showRest ? (
                <Tooltip message={t('nMore', { number: rest.length })} direction="tl">
                  <Avatar
                    bordered
                    size="sm"
                    name={`+${rest.length}`}
                    makeInitials={false}
                    className="avatars__more"
                    onClick={() => setshowRest(true)}
                  />
                </Tooltip>
              ) : (
                <>
                  {rest.map(({ veterinarianId, keycloakId, firstName, lastName }) => {
                    const name = formatName(firstName, lastName)
                    return (
                      <Tooltip key={veterinarianId || keycloakId} message={name} direction="tl">
                        <Avatar bordered size="sm" name={name} />
                      </Tooltip>
                    )
                  })}
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="avatars__hide"
                    onClick={() => setshowRest(false)}
                  />
                </>
              )}
            </>
          ) : null}
        </>
      ) : (
        '-'
      )}
    </div>
  )
}

export default Avatars
