import React from 'react'
import './style.sass'

interface FullscreenBoxProps {
  title: string
  children: React.ReactNode
}

const FullscreenBox = ({ title, children }: FullscreenBoxProps) => (
  <div className="fullscreen-box">
    <div className="fullscreen-box__box">
      <div className="fullscreen-box__title">{title}</div>
      {children}
    </div>
  </div>
)

export default FullscreenBox
