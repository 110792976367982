import React from 'react'
import Form, { Select, type value } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { kvalToOptions } from '../utils/helpers'
import validLanguages from '../utils/validLanguages'

const LanguageSwitcher = () => {
  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation()

  const languageOptions = kvalToOptions(validLanguages)

  return (
    <Form fields={['language']} allMandatory>
      <Select
        name="language"
        options={languageOptions}
        initialValue={language}
        onChange={(value: value) => {
          changeLanguage(value)
          toast.success(t('changedLanguage'))
        }}
      />
    </Form>
  )
}

export default LanguageSwitcher
