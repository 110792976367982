import React from 'react'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import useGoBack from '../../utils/useGoBack'
import './style.sass'

interface TitleProps {
  right?: React.ReactNode
  hideDesktop?: boolean
  children?: React.ReactNode
}

const Title = ({ right, hideDesktop, children }: TitleProps) => {
  const goBack = useGoBack()

  return (
    <div className={classNames('title', { 'title--hide-desktop': hideDesktop })}>
      <button onClick={goBack} className="title__back">
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      {children}
      <div className="title__right">{right}</div>
    </div>
  )
}

export default Title
