import React from 'react'
import { useTranslation } from 'react-i18next'
import Block from '../components/Block'
import Content from '../components/Content'
import LanguageSwitcher from '../components/LanguageSwitcher'
import Title from '../components/Title'

const Settings = () => {
  const { t } = useTranslation()
  return (
    <Content>
      <Title>{t('settings')}</Title>
      <Block title="Change language">
        <LanguageSwitcher />
      </Block>
    </Content>
  )
}

export default Settings
