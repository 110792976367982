import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { foundVeterinarian } from '../api/formatted'
import FullscreenBox from '../components/FullscreenBox'
import Loader from '../components/Loader'
import Spinner from '../components/Spinner'
import Text from '../components/Text'
import FormVerifyVeterinarian from '../forms/FormVerifyVeterinarian'

const VerifyRegistration = () => {
  const { token = '' } = useParams()

  const { data, isFetching, isError } = useQuery({
    queryKey: ['found-veterinarian'],
    queryFn: () => foundVeterinarian(token),
  })

  return (
    <React.Suspense fallback={<Spinner variant="fullScreen" />}>
      <FullscreenBox title="Verify informations">
        <Loader loading={isFetching}>
          {data ? (
            <>
              <Text>
                Here are the informations, that we found on public goverment database, associated
                with the Numéro d'ordre that you provided.
              </Text>
              <FormVerifyVeterinarian data={data} />
            </>
          ) : (
            <Text>Registration token is invalid</Text>
          )}
        </Loader>
      </FullscreenBox>
    </React.Suspense>
  )
}

export default VerifyRegistration
