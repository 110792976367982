import * as t from 'io-ts'


/**
 * @deprecated given the cost we drop this idea of versioning for now
 */
export interface Front {} // TODO: extends NewDataFormat

/**
 * @deprecated given the cost we drop this idea of versioning for now
 */
export interface V0 {
  hack: true
}

export const TypeIndividual = t.literal('individual')
export type TypeIndividualT = t.TypeOf<typeof TypeIndividual>

export const TypePetsitter = t.literal('petsitter')
export type TypePetsitterT = t.TypeOf<typeof TypePetsitter>

export const TypeVeterinarian = t.literal('veterinarian')
export type TypeVeterinarianT = t.TypeOf<typeof TypeVeterinarian>

export const TypeClinicReceptionist = t.literal('clinic_receptionist')
export type TypeClinicReceptionistT = t.TypeOf<typeof TypeClinicReceptionist>

export const TypeBreeder = t.literal('breeder')
export type TypeBreederT = t.TypeOf<typeof TypeBreeder>

export const TypeAssociation = t.literal('association')
export type TypeAssociationT = t.TypeOf<typeof TypeAssociation>

export const TypeAdministrator = t.literal('administrator')
export type TypeAdministratorT = t.TypeOf<typeof TypeAdministrator>
