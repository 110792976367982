import { Workbox } from 'workbox-window'

export default function registerServiceWorker() {
  // Check if the serviceWorker Object exists in the navigator object ( means if browser supports SW )
  if (/* process.env.NODE_ENV === 'production' && */'serviceWorker' in navigator) {
    const wb = new Workbox('service-worker.js')

    wb.addEventListener('installed', event => {
      /**
       * We have the condition — event.isUpdate because we don’t want to show
       * this message on the very first service worker installation,
       * only on the updated
       */
      if (event.isUpdate) {
        if (confirm(`New app update is available! Click OK to refresh`)) {
          window.location.reload();
        }
      }
    });

    wb.addEventListener('push', (event) => {
      const data = event.data.json();
      const title = data.title || 'New Notification';
      const options = {
          body: data.body || 'You have a new message.',
          icon: '/assets/android-chrome-48x48.png',
          // data: { url: data.url }
      }
  
      event.waitUntil(
        self.registration.showNotification(title, options)
      )
    })

    wb.register()
  }
}
