import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { getUserProfile } from '../api/formatted/user'
import Content from '../components/Content'
import Loader from '../components/Loader'
import Panel from '../components/Panel'
import Title from '../components/Title'
import UserDetails from '../components/UserDetails'

const UserProfile = () => {
  const { keycloakId = '' } = useParams()
  const { t } = useTranslation()

  const { data, isFetching, isError } = useQuery({
    queryKey: ['user', keycloakId],
    queryFn: () => getUserProfile('individual', keycloakId),
  })

  return (
    <Loader loading={isFetching} error={isError}>
      {data && (
        <Content>
          <Title>{t('userProfile')}</Title>
          <Panel>
            <UserDetails {...data} />
          </Panel>
        </Content>
      )}
    </Loader>
  )
}

export default UserProfile
