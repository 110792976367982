import React from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import './style.sass'

interface TileProps {
  title: string
  icon?: IconProp
  url?: string
  onClick?: () => void
  className?: string
}

const Tile = ({ title, icon = faChevronRight, url, onClick, className }: TileProps) =>
  url ? (
    <Link to={url} className={classNames('tile', className)}>
      <div className="tile__title">{title}</div>
      <FontAwesomeIcon className="tile__icon" icon={icon} />
    </Link>
  ) : onClick ? (
    <button onClick={onClick} className={classNames('tile', className)}>
      <div className="tile__title">{title}</div>
      <FontAwesomeIcon className="tile__icon" icon={icon} />
    </button>
  ) : null

export default Tile
