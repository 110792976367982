import React from 'react'
import classNames from 'classnames'
import './style.sass'

interface FieldProps {
  label: string
  values?: React.ReactNode[]
  children?: React.ReactNode
  inlineLabel?: boolean
  className?: string
}

const Field = ({ label, values, children, inlineLabel, className }: FieldProps) => (
  <div className={classNames('field', { 'field--inline-label': inlineLabel }, className)}>
    <div className="field__label">{label}:</div>
    <div className="field__items">
      {children ? (
        <div className="field__item">{children}</div>
      ) : values ? (
        values.map((value, index) => (
          <div className="field__item" key={index}>
            {value}
          </div>
        ))
      ) : (
        <div className="field__item">—</div>
      )}
    </div>
  </div>
)

export default Field
