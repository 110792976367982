import React from 'react'
import { useTranslation } from 'react-i18next'
import Alert from './Alert'
import Spinner from './Spinner'

interface LoaderProps {
  loading: boolean
  error?: boolean
  children: React.ReactNode
}

const Loader = ({ loading, error, children }: LoaderProps) => {
  const { t } = useTranslation()
  if (loading) return <Spinner variant="block" />
  if (error)
    return (
      <Alert type="error" primary={t('dataError.title')} secondary={t('dataError.description')} />
    )
  return <>{children}</>
}
export default Loader
