import { type Notification, type UserBasic } from '../../typings'

export const getNotifications = async (user: UserBasic): Promise<Notification[]> => {
  return [
    // {
    //   id: '1',
    //   type: 'reminder',
    //   vetecard: {
    //     id: 1,
    //     name: 'Panini',
    //     species: 'dog',
    //     birthDate: new Date('05-6-2020'),
    //   },
    //   date: new Date('04-08-2024'),
    // },
    // {
    //   id: '2',
    //   type: 'document',
    //   vetecard: {
    //     id: 1,
    //     name: 'Panini',
    //     species: 'dog',
    //     birthDate: new Date('05-6-2020'),
    //   },
    // },
    // {
    //   id: '3',
    //   type: 'payment',
    //   vetecard: {
    //     id: 1,
    //     name: 'Panini',
    //     species: 'dog',
    //     birthDate: new Date('05-6-2020'),
    //   },
    // },
  ]
}
