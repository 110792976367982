import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'
import { nullable } from 'io-ts/Type'

import { Vetecard } from '../../vetecard'
import { VeterinarianId } from '../../veterinarian'
import { InvoiceKey } from '../vetecard/invoice'
import { MandatoryVaccinationKey } from '../vetecard/mandatory_vaccination'
import { MedicalFileKey } from '../vetecard/medical_file'
import { OtherVaccinationKey } from '../vetecard/other_vaccination'
import { TreatmentAccess, TreatmentFileType, TreatmentKey } from '../vetecard/treatment'


export const OwnerVetecardInboxFileId = t.Integer
export type OwnerVetecardInboxFileIdT = t.TypeOf<typeof OwnerVetecardInboxFileId>

export const OwnerVetecardInboxFileKey = t.type({
  id: OwnerVetecardInboxFileId,
})
export type OwnerVetecardInboxFileKeyT = t.TypeOf<typeof OwnerVetecardInboxFileKey>

export const OwnerVetecardInboxFileTemplateNone = t.type({})
export type OwnerVetecardInboxFileTemplateNoneT = t.TypeOf<typeof OwnerVetecardInboxFileTemplateNone>

export const OwnerVetecardInboxFileTemplateTreatment = t.intersection([
  t.type({
    category: t.literal('treatment'),
  }),
  t.partial({
    type: nullable(TreatmentFileType),
    access: nullable(TreatmentAccess),
    documentDate: nullable(DateFromISOString),
    treatmentDate: nullable(DateFromISOString),
  }),
])
export type OwnerVetecardInboxFileTemplateTreatmentT = t.TypeOf<typeof OwnerVetecardInboxFileTemplateTreatment>

export const OwnerVetecardInboxFileTemplateMedical = t.intersection([
  t.type({
    category: t.literal('medical'),
  }),
  t.partial({
    type: nullable(TreatmentFileType),
    access: nullable(TreatmentAccess),
    documentDate: nullable(DateFromISOString),
    treatmentDate: nullable(DateFromISOString),
  }),
])
export type OwnerVetecardInboxFileTemplateMedicalT = t.TypeOf<typeof OwnerVetecardInboxFileTemplateMedical>

export const OwnerVetecardInboxFileTemplateInvoice = t.intersection([
  t.type({
    category: t.literal('invoice'),
  }),
  t.partial({
    type: nullable(TreatmentFileType),
    access: nullable(TreatmentAccess),
    documentDate: nullable(DateFromISOString),
    treatmentDate: nullable(DateFromISOString),
  }),
])
export type OwnerVetecardInboxFileTemplateInvoiceT = t.TypeOf<typeof OwnerVetecardInboxFileTemplateInvoice>

export const OwnerVetecardInboxFileTemplateMandatoryVaccination = t.intersection([
  t.type({
    category: t.literal('mandatory_vaccination'),
  }),
  t.partial({
    storageKey: nullable(t.string),
    type: nullable(t.string),
    manufacturer: nullable(t.string),
    batchNumber: nullable(t.string),
    vaccinationDate: nullable(DateFromISOString),
    validFrom: nullable(DateFromISOString),
    validTo: nullable(DateFromISOString),
    signedBy: nullable(VeterinarianId),
  }),
])
export type OwnerVetecardInboxFileTemplateMandatoryVaccinationT = t.TypeOf<typeof OwnerVetecardInboxFileTemplateMandatoryVaccination>

export const OwnerVetecardInboxFileTemplateOtherVaccination = t.intersection([
  t.type({
    category: t.literal('other_vaccination'),
  }),
  t.partial({
    storageKey: nullable(t.string),
    type: nullable(t.string),
    manufacturer: nullable(t.string),
    batchNumber: nullable(t.string),
    vaccinationDate: nullable(DateFromISOString),
    validFrom: nullable(DateFromISOString),
    validTo: nullable(DateFromISOString),
    signedBy: nullable(VeterinarianId),
  }),
])
export type OwnerVetecardInboxFileTemplateOtherVaccinationT = t.TypeOf<typeof OwnerVetecardInboxFileTemplateOtherVaccination>

export const OwnerVetecardInboxFileTemplate = t.union([
  OwnerVetecardInboxFileTemplateNone,
  OwnerVetecardInboxFileTemplateTreatment,
  OwnerVetecardInboxFileTemplateMedical,
  OwnerVetecardInboxFileTemplateInvoice,
  OwnerVetecardInboxFileTemplateMandatoryVaccination,
  OwnerVetecardInboxFileTemplateOtherVaccination,
])
export type OwnerVetecardInboxFileTemplateT = t.TypeOf<typeof OwnerVetecardInboxFileTemplate>

export const OwnerVetecardInboxMessageFile = t.intersection([
  OwnerVetecardInboxFileKey,
  t.type({
    name: t.string,
    type: t.string,
    invoice: nullable(InvoiceKey),
    treatment: nullable(TreatmentKey),
    medicalFile: nullable(MedicalFileKey),
    mandatoryVaccination: nullable(MandatoryVaccinationKey),
    otherVaccination: nullable(OtherVaccinationKey),
  }),
])
export type OwnerVetecardInboxMessageFileT = t.TypeOf<typeof OwnerVetecardInboxMessageFile>

export const OwnerVetecardInboxMessageId = t.Integer
export type OwnerVetecardInboxMessageIdT = t.TypeOf<typeof OwnerVetecardInboxMessageId>

export const OwnerVetecardInboxMessageKey = t.type({
  id: OwnerVetecardInboxMessageId,
})
export type OwnerVetecardInboxMessageKeyT = t.TypeOf<typeof OwnerVetecardInboxMessageKey>

export const OwnerVetecardInboxMessage = t.intersection([
  OwnerVetecardInboxMessageKey,
  t.type({
    received: DateFromISOString,
    sender: t.type({
      email: t.string,
    }),
    subject: nullable(t.string),
    content: t.type({
      text: nullable(t.string),
      html: nullable(t.string),
    }),
    files: t.array(OwnerVetecardInboxMessageFile),
    vetecard: Vetecard,
  }),
])
export type OwnerVetecardInboxMessageT = t.TypeOf<typeof OwnerVetecardInboxMessage>
