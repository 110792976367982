import React from 'react'
import { faSortDesc } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { type Dictionary } from '../../typings'
import './style.sass'

type TableLabel = {
  name?: string
  field: string
  sortable?: boolean
}

interface TableProps {
  labels: TableLabel[]
  data: Dictionary[]
  emptyText?: string
  sortBy?: string
  onSort?: (field: string) => void
  toRight?: boolean
  bottomMargin?: boolean
}

const Table = ({
  labels,
  data,
  emptyText,
  sortBy,
  onSort = () => {},
  toRight,
  bottomMargin,
}: TableProps) => (
  <div
    className={classNames('table', {
      'table--to-right': toRight,
      'table__sortable--margin': bottomMargin,
    })}
  >
    <table>
      <thead>
        <tr>
          {labels.map(({ name, field, sortable }) => (
            <th
              key={field}
              className={classNames({
                table__sortable: sortable,
                'table__sortable--active': sortBy === field,
              })}
              onClick={() => sortable && onSort(field)}
            >
              {name}
              {sortable && (
                <div className="table__sorting">
                  <FontAwesomeIcon icon={faSortDesc} />
                </div>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.length ? (
          data.map((row, rowIndex) => (
            <tr key={row.id || rowIndex}>
              {labels.map((label, columnIndex) => (
                <td key={columnIndex}>{row[label.field]}</td>
              ))}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={labels.length} className="table__noresult">
              {emptyText || '-'}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
)

export default Table
