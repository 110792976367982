import React from 'react'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import useAuth from '../utils/useAuth'
import Dropdown from './Dropdown'
import User from './User'

interface UserDropdownProps {
  condensed?: boolean
  className?: string
}

const UserDropdown = ({ condensed, className }: UserDropdownProps) => {
  const { t } = useTranslation()
  const {
    currentUser: { firstName, lastName },
    hasPermission,
    logout,
  } = useAuth()

  const userLinks = [
    {
      label: t('personalInfo'),
      url: '/personal-info',
      permission: 'personal-info',
    },
    // {
    //   label: t('changePassword'),
    //   url: '/personal-info',
    // },
    {
      label: t('settings'),
      url: '/settings',
    },
    {
      label: t('logout'),
      onClick: logout,
    },
  ].filter(({ permission }) => (permission ? hasPermission(permission) : true))

  return (
    <Dropdown links={userLinks} anchor="right" noCaret={!!condensed} className={className}>
      {condensed ? (
        <FontAwesomeIcon icon={faUser} size="lg" />
      ) : (
        <User firstName={firstName} lastName={lastName} />
      )}
    </Dropdown>
  )
}

export default UserDropdown
