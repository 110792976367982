import * as t from 'io-ts'

import { TypeAssociation, TypeBreeder, TypeIndividual } from '../..'


export const VetecardOwnerTypeIndividual = TypeIndividual
export type VetecardOwnerTypeIndividualT = t.TypeOf<typeof VetecardOwnerTypeIndividual>

export const VetecardOwnerTypeAssociation = TypeAssociation
export type VetecardOwnerTypeAssociationT = t.TypeOf<typeof VetecardOwnerTypeAssociation>

export const VetecardOwnerTypeBreeder = TypeBreeder
export type VetecardOwnerTypeBreederT = t.TypeOf<typeof VetecardOwnerTypeBreeder>

export const VetecardOwnerType  = t.union([
  VetecardOwnerTypeIndividual,
  VetecardOwnerTypeAssociation,
  VetecardOwnerTypeBreeder,
])
export type VetecardOwnerTypeT = t.TypeOf<typeof VetecardOwnerType>

export const VetecardOwnerId = t.string/*UUID*/
export type VetecardOwnerIdT = t.TypeOf<typeof VetecardOwnerId>

export const VetecardOwnerKey = t.type({
  type: VetecardOwnerType,
  id: VetecardOwnerId,
})
export type VetecardOwnerKeyT = t.TypeOf<typeof VetecardOwnerKey>
