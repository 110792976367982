import React from 'react'
import { useMutation } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { uploadFile } from '../api/formatted/files'
import FormFile, { type FormFileValues } from '../forms/FormFile'
import {
  type FileCategory,
  type FileEntity,
  type UploadFilePayload,
  type VetecardBasic,
} from '../typings'
import queryClient from '../utils/queryClient'
import useAuth from '../utils/useAuth'

interface UploadFileContainerProps {
  vetecardId: VetecardBasic['id']
  category: FileCategory
  onSuccess?: () => void
}

const UploadFileContainer = ({ vetecardId, category, onSuccess }: UploadFileContainerProps) => {
  const { t } = useTranslation()
  const { currentUser } = useAuth()

  const { isLoading, mutate } = useMutation(
    (payload: UploadFilePayload) => uploadFile(currentUser, payload),
    {
      onSuccess: (file: FileEntity) => {
        queryClient.setQueryData(
          ['vetecard', vetecardId, 'files', category],
          (oldData: FileEntity[] = []) => [file, ...oldData]
        )
        toast.success(t('fileUploaded'))
        onSuccess && onSuccess()
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 409) {
          toast.error(t('fileAlreadyExists'))
        } else {
          toast.error('Oops! Some technical error occured...')
        }
      },
    }
  )

  const handleSubmit = ({ file, ...rest }: FormFileValues) => {
    mutate({
      ...rest,
      file: file.dataFile,
      category,
      vetecardId,
    })
  }

  return <FormFile category={category} onSubmit={handleSubmit} isLoading={isLoading} />
}

export default UploadFileContainer
