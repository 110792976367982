import React from 'react'
import { useTranslation } from 'react-i18next'
import { type AnimalSearchResult } from '../../typings'
import formatDate from '../../utils/formatDate'
import toTitleCase from '../../utils/toTitleCase'
import Button from '../Button'
import './style.sass'

interface SearchResultProps extends AnimalSearchResult {
  onSelect?: () => void
}

const SearchResult = ({
  name,
  sex,
  breed,
  coatingColour,
  birthDate,
  identifier,
  onSelect,
}: SearchResultProps) => {
  const { t } = useTranslation()

  const rowOne = [
    ...(sex ? [t(`sex.${sex}`)] : []),
    ...(breed ? [toTitleCase(breed)] : []),
    ...(coatingColour ? [toTitleCase(coatingColour)] : []),
  ].join(' · ')

  const rowTwo = [
    ...(birthDate ? [`${t('birthDate')}: ${formatDate(birthDate)}`] : []),
    ...(identifier?.number ? [`${t('identifier')}: ${identifier.number}`] : []),
  ].join(' · ')

  return (
    <div className="search-result">
      <div className="search-result__data">
        <div className="search-result__primary">{name || 'No name'}</div>
        {rowOne && <div className="search-result__secondary">{rowOne}</div>}
        {rowTwo && <div className="search-result__secondary">{rowTwo}</div>}
      </div>
      {onSelect && (
        <Button size="sm" onClick={onSelect}>
          {t('select')}
        </Button>
      )}
    </div>
  )
}

export default SearchResult
