import React from 'react'
import { useMutation } from '@tanstack/react-query'
import Form from 'react-form-component'
import { useTranslation } from 'react-i18next'
import { uploadPhoto } from '../api/formatted/vetecard'
import ImageUpload from '../components/ImageUpload'
import SubmitButton from '../components/SubmitButton'
import { type FileInput, type VetecardFull } from '../typings'
import queryClient from '../utils/queryClient'

interface FormUploadPhotoProps {
  vetecardId: VetecardFull['id']
  onSuccess?: () => void
}

const FormUploadPhoto = ({ vetecardId, onSuccess }: FormUploadPhotoProps) => {
  const { t } = useTranslation()

  const { isLoading, mutate } = useMutation((photo: FileInput) => uploadPhoto(vetecardId, photo), {
    onSuccess: (photo: string) => {
      queryClient.setQueryData(
        ['vetecard', vetecardId],
        (oldData?: VetecardFull) =>
          oldData && {
            ...oldData,
            photo,
          }
      )
      onSuccess && onSuccess()
    },
  })

  const handleSubmit = ({ photo }: { photo?: FileInput }) => {
    if (photo) mutate(photo)
  }

  return (
    <Form fields={['photo']} allMandatory>
      <ImageUpload name="photo" type="file" aspect={392 / 256} />
      <SubmitButton block onClick={handleSubmit} loading={isLoading}>
        {t('submit')}
      </SubmitButton>
    </Form>
  )
}

export default FormUploadPhoto
