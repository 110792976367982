import React from 'react'
import { useTranslation } from 'react-i18next'
import Button, { ButtonGroup } from '../components/Button'
import UploadVaccinationContainer from '../containers/UploadVaccinationContainer'
import { type Vaccination, type VaccinationCategory, type VetecardBasic } from '../typings'
import formatDate from '../utils/formatDate'
import useAuth from '../utils/useAuth'
import AddButton from './AddButton'
import Modal, { type ModalRef } from './Modal'
import Table from './Table'
import User from './User'

interface VaccinationsProps {
  vetecardId: VetecardBasic['id']
  data: Vaccination[]
  category: VaccinationCategory
  disableUpload?: boolean
}

const Vaccinations = ({ vetecardId, data, category, disableUpload }: VaccinationsProps) => {
  const { t } = useTranslation()
  const modalRef = React.useRef<ModalRef>(null)
  const [sortBy, setSortBy] = React.useState('date')
  const [renewId, setRenewId] = React.useState<Vaccination['id'] | undefined>(undefined)
  const { hasPermission } = useAuth()
  const canAddVaccination = !disableUpload && hasPermission('vetecards-add_vaccination')

  const openForm = (id: Vaccination['id'] = undefined) => {
    setRenewId(id)
    modalRef.current?.open()
  }

  const formattedData = React.useMemo(
    () =>
      data
        .sort((a, b) => {
          switch (sortBy) {
            case 'type':
              if (a.type > b.type) return 1
              if (a.type < b.type) return -1
              return 0
            case 'manufacturer':
              if (a.manufacturer > b.manufacturer) return 1
              if (a.manufacturer < b.manufacturer) return -1
              return 0
            case 'date':
              if (!a.vaccinationDate || !b.vaccinationDate) return 0
              if (a.vaccinationDate > b.vaccinationDate) return 1
              if (a.vaccinationDate < b.vaccinationDate) return -1
              return 0
            default:
              return 0
          }
        })
        .map(
          ({
            id,
            type,
            url,
            manufacturer,
            batchNumber,
            vaccinationDate,
            validFrom,
            validTo,
            signedBy,
          }) => ({
            type,
            manufacturer,
            batch: batchNumber,
            date: (
              <>
                {t('doneAt')}: {formatDate(vaccinationDate)}
                <br />
                {t('valid')}: {formatDate(validFrom)} - {formatDate(validTo)}
              </>
            ),
            signedBy: signedBy ? <User key={signedBy.veterinarianId} {...signedBy} /> : null,
            actions: (
              <ButtonGroup combined>
                {url && (
                  <Button size="xs" component="a" href={url}>
                    {t('download')}
                  </Button>
                )}
                {canAddVaccination && (
                  <Button size="xs" onClick={() => openForm(id)} variant="ghost">
                    {t('renew')}
                  </Button>
                )}
              </ButtonGroup>
            ),
          })
        ),
    [data, sortBy]
  )
  return (
    <>
      <Table
        labels={[
          { name: t('type'), field: 'type', sortable: true },
          { name: t('manufacturer'), field: 'manufacturer', sortable: true },
          { name: t('batch'), field: 'batch' },
          { name: t('date'), field: 'date', sortable: true },
          { name: t('signedBy'), field: 'signedBy' },
          { field: 'actions' },
        ]}
        data={formattedData}
        emptyText={t('noResults.noVaccinations')}
        sortBy={sortBy}
        onSort={field => setSortBy(field)}
        toRight
      />
      {canAddVaccination ? (
        <>
          <AddButton padded onClick={() => openForm()}>
            {t('uploadVaccination')}
          </AddButton>
          <Modal
            size="lg"
            title={renewId ? t('Renew vaccination') : t('uploadVaccination')}
            ref={modalRef}
          >
            <UploadVaccinationContainer
              vetecardId={vetecardId}
              category={category}
              renewId={renewId}
              onSuccess={() => modalRef.current?.close()}
            />
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default Vaccinations
