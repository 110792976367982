import React from 'react'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import Box from '../components/Box'
import Content from '../components/Content'
import Text from '../components/Text'
import Title from '../components/Title'

const Formalities = () => {
  const { t } = useTranslation()

  return (
    <Content>
      <Title>{t('formalities')}</Title>
      <Text>{t('formalitiesFaq.paragraph1')}</Text>
      <Text>{t('formalitiesFaq.paragraph2')}</Text>

      <Box
        white
        title={t('formalitiesFaq.link1')}
        btnLabel={t('view')}
        externalUrl="https://www.service-public.fr/particuliers/vosdroits/F34986"
      />
      <Box
        white
        title={t('formalitiesFaq.link2')}
        btnLabel={t('view')}
        externalUrl="https://www.service-public.fr/particuliers/vosdroits/F34922#:~:text=La%20loi%20n%C2%B02021,"
      />
      <Box
        white
        title={t('formalitiesFaq.link3')}
        btnLabel={t('view')}
        externalUrl="https://www.service-public.fr/particuliers/vosdroits/F34877"
      />
      <Box
        white
        title={t('formalitiesFaq.link4')}
        btnLabel={t('view')}
        externalUrl="https://www.service-public.fr/particuliers/vosdroits/F31859"
      />
    </Content>
  )
}

export default Formalities
