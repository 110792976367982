import React from 'react'
import { faLocationPin, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { type ContactDetails as ContactDetailsType } from '../../typings'
import './style.sass'

interface ContactDetailsProps extends ContactDetailsType {}

const ContactDetails = ({
  phone,
  fax,
  address,
  postalCode,
  city,
  country,
}: ContactDetailsProps) => {
  const { t } = useTranslation(['countries'])

  const items = [
    {
      icon: faLocationPin,
      content: `${address}, ${postalCode} ${city}, ${t(`countries.${country}`)}`,
    },
    ...(phone
      ? [
          {
            icon: faPhone,
            content: phone,
          },
        ]
      : []),
    ...(fax
      ? [
          {
            icon: faPhone,
            content: fax,
          },
        ]
      : []),
  ]

  return (
    <div className="contact-details">
      {items.map(({ icon, content }, index) => (
        <div className="contact-details__item" key={index}>
          <div className="contact-details__icon">
            <FontAwesomeIcon icon={icon} />
          </div>
          {content}
        </div>
      ))}
    </div>
  )
}

export default ContactDetails
