import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { getAnnouncements } from '../api/formatted/news'
import AddBlock from '../components/AddBlock'
import News from '../components/AnnouncementCard'
import Content from '../components/Content'
import Loader from '../components/Loader'
import Panel from '../components/Panel'
import Title from '../components/Title'
import FormNews from '../forms/FormNews'
import { type Announcement } from '../typings'
import useAuth from '../utils/useAuth'

const ManageNews = () => {
  const { t } = useTranslation()
  const [showForm, setShowForm] = React.useState<Announcement['id'] | null | undefined>(undefined) // null means add new
  const [initialValues, setInitialValues] = React.useState<Announcement | undefined>(undefined)
  const { currentUser } = useAuth()

  const { data = [], isFetching, isError } = useQuery(['news'], () => getAnnouncements(currentUser))

  const handleEdit = (item: Announcement) => {
    setShowForm(item.id)
    setInitialValues(item)
  }

  return (
    <Content>
      <Title>{t('manageNews')}</Title>
      <Loader loading={isFetching} error={isError}>
        {showForm === null ? (
          <Panel title={t('addNews')}>
            <FormNews onSuccess={() => setShowForm(undefined)} />
          </Panel>
        ) : (
          <AddBlock onClick={() => setShowForm(null)}>{t('addNews')}</AddBlock>
        )}
        {data.map(item =>
          item.id === showForm ? (
            <Panel key={item.id} title={t('editNews')}>
              <FormNews
                key={item.id}
                initialValues={initialValues}
                onSuccess={() => setShowForm(undefined)}
              />
            </Panel>
          ) : (
            <News key={item.id} {...item} onEdit={handleEdit} />
          )
        )}
      </Loader>
    </Content>
  )
}

export default ManageNews
