import React from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { downloadVaccination, getVaccinations } from '../api/formatted/vaccinations'
import Loader from '../components/Loader'
import Vaccinations from '../components/Vaccinations'
import { type VaccinationCategory, type VetecardBasic } from '../typings'
import useAuth from '../utils/useAuth'

interface VaccinationsContainerProps {
  vetecardId: VetecardBasic['id']
  category: VaccinationCategory
  isRequest?: boolean
  disableUpload?: boolean
}

const VaccinationsContainer = ({
  vetecardId,
  category,
  isRequest,
  disableUpload,
}: VaccinationsContainerProps) => {
  const { t } = useTranslation()
  const { currentUser } = useAuth()

  const {
    data = [],
    isFetching,
    isError,
  } = useQuery({
    queryKey: ['vetecard', vetecardId, 'vaccinations', category],
    queryFn: () => getVaccinations(vetecardId, category, currentUser, isRequest),
  })

  const { mutate } = useMutation(
    (name: string) => downloadVaccination(currentUser, category, vetecardId, name),
    {
      onMutate: () => toast.info(t('downloadStart')),
      onError: () => toast.error(t('downloadError')),
    }
  )

  const handleDownload = (name: string) => mutate(name)

  return (
    <Loader loading={isFetching} error={isError}>
      <Vaccinations
        vetecardId={vetecardId}
        data={data}
        category={category}
        disableUpload={disableUpload}
      />
    </Loader>
  )
}

export default VaccinationsContainer
