import React from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { revokeOtherAccess, updateShareOther } from '../api/formatted/share'
import { getSharedThirdParty } from '../api/formatted/vetecard'
import Alert from '../components/Alert'
import Box from '../components/Box'
import Loader from '../components/Loader'
import Modal, { type ModalRef } from '../components/Modal'
import Text from '../components/Text'
import UserList from '../components/UserList'
import FormAccessPeriodEdit, { type FormValues } from '../forms/FormAccessPeriodEdit'
import FormShareWithOther from '../forms/FormShareWithOther'
import { type UserShared, type VetecardBasic } from '../typings'
import formatName from '../utils/formatName'
import queryClient from '../utils/queryClient'
import useAuth from '../utils/useAuth'
import FileSharingOtherContainer from './FileSharingOtherContainer'

interface SharedWithOtherContainerProps {
  vetecardId: VetecardBasic['id']
}

const SharedWithOtherContainer = ({ vetecardId }: SharedWithOtherContainerProps) => {
  const { t } = useTranslation()
  const { currentUser } = useAuth()
  const [managedUser, setManagedUser] = React.useState<UserShared | undefined>(undefined)

  const addModalRef = React.useRef<ModalRef>(null)

  const unsetManagedUser = () => setManagedUser(undefined)

  const { data, isFetching, isError } = useQuery({
    queryKey: ['vetecard', vetecardId, 'shared-other'],
    queryFn: () => getSharedThirdParty(currentUser, vetecardId),
  })

  const { mutate: mutateUpdatePeriod, isLoading: mutateUpdatePeriodIsLoading } = useMutation(
    ({ dateFrom, dateTo, timeRangeType }: FormValues) =>
      updateShareOther(currentUser, vetecardId, managedUser, { dateFrom, dateTo, timeRangeType }),
    {
      onSuccess: () => {
        // TODO: Update cache.
        unsetManagedUser()
      },
    }
  )

  const { mutate: mutateRevoke } = useMutation(
    (keycloakId: UserShared['keycloakId']) => revokeOtherAccess(vetecardId, keycloakId),
    {
      onSuccess: (keycloakId: UserShared['keycloakId']) => {
        queryClient.setQueryData(
          ['vetecard', vetecardId, 'shared-other'],
          (oldData?: UserShared[]) =>
            oldData && oldData.filter(item => item.keycloakId !== keycloakId)
        )
        unsetManagedUser()
        toast.success(t('success'))
      },
    }
  )

  const handleUpdatePeriod = (values: FormValues) => mutateUpdatePeriod(values)
  const handleRevoke = () => mutateRevoke(managedUser?.keycloakId || '')

  return (
    <Loader loading={isFetching} error={isError}>
      <UserList
        users={data}
        emptyMessage={t('notSharedWithThird')}
        onManage={keycloakId => setManagedUser(data?.find(item => item.keycloakId === keycloakId))}
        onAdd={() => addModalRef.current?.open()}
      />

      <Modal size="lg" title={t('shareWithOther.title')} ref={addModalRef}>
        <Text>{t('shareWithOther.description')}</Text>
        <Alert type="info" primary={t('comingSoon')} />
        <FormShareWithOther
          vetecardId={vetecardId}
          onSuccess={() => addModalRef.current?.close()}
        />
      </Modal>

      {managedUser && (
        <Modal
          size="lg"
          title={formatName(managedUser?.firstName, managedUser?.lastName)}
          onClose={() => setManagedUser(undefined)}
          isOpen
        >
          <Box
            title={t('accessManagement.basicInformation')}
            description={t('accessManagement.basicInformationDescr')}
          />
          <Box
            title={t('accessManagement.shareableFiles')}
            description={t('accessManagement.shareableFilesDescr')}
            paddedFooter
            footer={
              <FileSharingOtherContainer
                vetecardId={vetecardId}
                keycloakId={managedUser.keycloakId}
                onSuccess={() => setManagedUser(undefined)}
              />
            }
          />
          <Box
            title={t('accessManagement.accessPeriod')}
            description={t('accessManagement.accessPeriodDescr')}
            paddedFooter
            footer={
              <FormAccessPeriodEdit
                dateFrom={managedUser.dateFrom}
                dateTo={managedUser.dateTo}
                timeRangeType={managedUser.timeRangeType}
                onSubmit={handleUpdatePeriod}
                isLoading={mutateUpdatePeriodIsLoading}
              />
            }
          />
          <Box
            title={t('accessManagement.revokeAccess')}
            description={t('accessManagement.revokeAccessDescr')}
            btnLabel={t('revoke')}
            onClick={handleRevoke}
          />
        </Modal>
      )}
    </Loader>
  )
}

export default SharedWithOtherContainer
