import React from 'react'
import { faSquarePlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import './style.sass'

const VetecardAdd = () => {
  const { t } = useTranslation()
  return (
    <Link to="/vetecards/request" className={'veticard-add'} data-cy="vetecard-add">
      <FontAwesomeIcon className="veticard-add__icon" icon={faSquarePlus} />
      <div className="veticard-add__text">{t('requestVetecard')}</div>
    </Link>
  )
}

export default VetecardAdd
