import { QueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 120000, // 2 minutes
      refetchOnWindowFocus: false,
      retry: 0,
    },
    mutations: {
      onError: () => toast.error('Oops! Some technical error occured...'),
    },
  },
})

export default queryClient
