import React from 'react'
import Form, { Input, Radio, Row } from 'react-form-component'
import { useTranslation } from 'react-i18next'
import SubmitButton from '../components/SubmitButton'
import { type TimeRangeType, type UserShared } from '../typings'
import formatDate from '../utils/formatDate'
import { useTranslatedOptions } from '../utils/helpers'

export interface FormValues {
  timeRangeType: TimeRangeType
  dateFrom?: Date
  dateTo?: Date
}

interface FormAccessPeriodEditProps {
  dateFrom: UserShared['dateFrom']
  dateTo: UserShared['dateTo']
  timeRangeType: UserShared['timeRangeType']
  onSubmit: (values: FormValues) => void
  isLoading?: boolean
}

const FormAccessPeriodEdit = ({
  dateFrom,
  dateTo,
  timeRangeType: timeRangeTypeProp,
  onSubmit,
  isLoading,
}: FormAccessPeriodEditProps) => {
  const { t } = useTranslation()
  const [timeRangeType, setTimeRangeType] = React.useState<TimeRangeType | undefined>(undefined)

  const timeRangeOptions = useTranslatedOptions('timeRange')

  React.useEffect(() => {
    setTimeRangeType(timeRangeTypeProp)
  }, [timeRangeTypeProp])

  return (
    <Form
      fields={['timeRangeType', 'dateFrom', 'dateTo']}
      mandatory={[...(timeRangeType === 'date_range' ? ['dateFrom', 'dateTo'] : [])]}
    >
      <Radio
        name="timeRangeType"
        label={t('timeRangeLabel')}
        options={timeRangeOptions}
        onChange={(type: TimeRangeType) => setTimeRangeType(type)}
        initialValue={timeRangeType}
      />
      {timeRangeType === 'date_range' && (
        <Row>
          <Input
            name="dateFrom"
            label={t('validFrom')}
            type="date"
            initialValue={formatDate(dateFrom, 'input')}
          />
          <Input
            name="dateTo"
            label={t('validUntil')}
            type="date"
            initialValue={formatDate(dateTo, 'input')}
          />
        </Row>
      )}
      <SubmitButton size="sm" onClick={onSubmit} loading={isLoading}>
        {t('apply')}
      </SubmitButton>
    </Form>
  )
}

export default FormAccessPeriodEdit
