import React from 'react'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { uploadVaccination } from '../api/formatted/vaccinations'
import FormVaccination, { type FormVaccinationValues } from '../forms/FormVaccination'
import {
  type UploadVacciationPayload,
  type Vaccination,
  type VaccinationCategory,
  type VetecardBasic,
} from '../typings'
import queryClient from '../utils/queryClient'
import useAuth from '../utils/useAuth'

interface UploadVaccinationContainerProps {
  vetecardId: VetecardBasic['id']
  category: VaccinationCategory
  renewId?: Vaccination['id']
  initialValues?: Partial<Vaccination>
  onSuccess?: () => void
}

const UploadVaccinationContainer = ({
  vetecardId,
  category,
  renewId,
  initialValues,
  onSuccess,
}: UploadVaccinationContainerProps) => {
  const { t } = useTranslation()
  const { currentUser } = useAuth()

  const { isLoading, mutate } = useMutation(
    (payload: UploadVacciationPayload) => uploadVaccination(currentUser, payload),
    {
      onSuccess: (vaccination: Vaccination) => {
        queryClient.setQueryData(
          ['vetecard', vetecardId, 'vaccinations', category],
          (oldData: Vaccination[] = []) => [vaccination, ...oldData]
        )
        toast.success(t('fileUploaded'))
        onSuccess && onSuccess()
      },
    }
  )

  const handleSubmit = ({ file, ...rest }: FormVaccinationValues) => {
    mutate({
      file: file?.dataFile || undefined,
      ...rest,
      category,
      vetecardId,
    })
  }

  return (
    <FormVaccination
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      renewId={renewId}
    />
  )
}

export default UploadVaccinationContainer
