import * as t from 'io-ts'
import * as tt from 'io-ts-types'

import { Species, Identifier } from '../vetecard_request'


export const VetecardId = t.number
export type VetecardIdT = t.TypeOf<typeof VetecardId>

export const VetecardKey = t.type({id: VetecardId})
export type VetecardKeyT = t.TypeOf<typeof VetecardKey>

export const V0Vetecard = t.type({
  id: VetecardId,
  name: t.string,
  identifier: Identifier,
  species: Species,
  birth: t.type({
    date: tt.DateFromISOString,
  }),
})
export type V0VetecardT = t.TypeOf<typeof V0Vetecard>

export const Animal = t.type({
  name: t.string,
  species: Species,
  birth: t.type({
    date: tt.DateFromISOString,
  }),
})
export type AnimalT = t.TypeOf<typeof Animal>

export const V1Vetecard = t.type({
  id: VetecardId,
  identifier: Identifier,
  animal: Animal,
})
export type V1VetecardT = t.TypeOf<typeof V1Vetecard>

export const Vetecard = V1Vetecard
export type VetecardT = V1VetecardT

export const VetecardOwnership = t.type({
  owner: t.type({ id: t.string }),
})
export type VetecardOwnershipT = t.TypeOf<typeof VetecardOwnership>
