import * as t from 'io-ts'
import { nullable } from 'io-ts/Type'

import { DateFromISOString } from 'io-ts-types'

import { Veterinarian } from '../../../veterinarian'


export const minioBucketVetecardMandatoryVaccinations = process.env.MINIO_BUCKET_VETECARD_MANDATORY_VACCINATIONS as string

if (typeof minioBucketVetecardMandatoryVaccinations !== 'string') {
  throw new Error('Missing "MINIO_BUCKET_VETECARD_MANDATORY_VACCINATIONS" environment variable')
}

export const MandatoryVaccination = t.type({
  storageKey: nullable(t.string),
  type: nullable(t.string),
  manufacturer: nullable(t.string),
  batchNumber: nullable(t.string),
  vaccinationDate: nullable(DateFromISOString),
  validFrom: nullable(DateFromISOString),
  validTo: nullable(DateFromISOString),
  signedBy: nullable(Veterinarian.props.id),
})
export type MandatoryVaccinationT = t.TypeOf<typeof MandatoryVaccination>

export const MandatoryVaccinationKey = t.type({
  id: t.number,
})
export type MandatoryVaccinationKeyT = t.TypeOf<typeof MandatoryVaccinationKey>

export const MandatoryVaccinationWithKey = t.intersection([
  MandatoryVaccinationKey,
  MandatoryVaccination,
])
export type MandatoryVaccinationWithKeyT = t.TypeOf<typeof MandatoryVaccinationWithKey>
