import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Button from '../Button'
import './style.sass'

interface ErrorPageProps {
  title: string
  description: string
}

const ErrorPage = ({ title, description }: ErrorPageProps) => {
  const { t } = useTranslation()
  return (
    <div className="error-page">
      <div className="error-page__container">
        <h1 className="error-page__title">{title}</h1>
        <h2 className="error-page__description">{description}</h2>
        <Button component={Link} to="/">
          {t('backToHome')}
        </Button>
      </div>
    </div>
  )
}

export default ErrorPage
