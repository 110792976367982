import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Button from '../components/Button'
import Content from '../components/Content'
import Text from '../components/Text'
import Title from '../components/Title'
import useLocalStorage from '../utils/useLocalStorage'

const PaymentSuccess = () => {
  const { t } = useTranslation()
  const [paymentActive, setPaymentActive] = useLocalStorage<boolean>('paymentActive', false)

  React.useEffect(() => {
    if (paymentActive) setPaymentActive(false)
  }, [])

  return (
    <Content>
      <Title>{t('payment.success')}</Title>
      <Text>{t('payment.successDescr')}</Text>
      <Button component={Link} to="/vetecards">
        {t('payment.goToVeteCards')}
      </Button>
    </Content>
  )
}

export default PaymentSuccess
