import React from 'react'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import './style.sass'

interface EditLinkProps {
  onClick?: () => void
  to?: string
  className?: string
  children?: React.ReactNode
}
const EditLink = ({ onClick, to, className, children }: EditLinkProps) =>
  onClick ? (
    <button className={classNames('edit-link', className)} onClick={onClick}>
      {children} <FontAwesomeIcon icon={faPenToSquare} />
    </button>
  ) : to ? (
    <Link className={classNames('edit-link', className)} to={to}>
      {children} <FontAwesomeIcon icon={faPenToSquare} />
    </Link>
  ) : (
    <div className={classNames('edit-link', className)}>
      {children} <FontAwesomeIcon icon={faPenToSquare} />
    </div>
  )

export default EditLink
