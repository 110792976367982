import React from 'react'
import classNames from 'classnames'
import initials from 'initials'
import './style.sass'

interface AvatarProps {
  image?: string
  name: string
  size?: 'lg' | 'sm'
  bordered?: boolean
  onClick?: () => void
  makeInitials?: boolean
  className?: string
}
const Avatar = ({
  image,
  name,
  size,
  bordered,
  onClick,
  makeInitials = true,
  className,
}: AvatarProps) => (
  <div
    className={classNames(
      'avatar',
      {
        [`avatar--${size}`]: size,
        'avatar--bordered': bordered,
        'avatar--clickable': onClick,
      },
      className
    )}
    onClick={onClick}
  >
    {image ? (
      <img src={image} alt={`${name}'s avatar`} />
    ) : makeInitials ? (
      initials(name).substring(0, 2).toUpperCase()
    ) : (
      name
    )}
  </div>
)

export default Avatar
