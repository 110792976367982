import React from 'react'
import { type setValue, type value, withFormControl } from 'react-form-component'
import PhoneInputLib, { type CountryData } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './style.sass'

interface PhoneInputProps {
  name: string
  value: value
  mandatory?: boolean
  setValue: setValue
}

const PhoneInput = ({ name, value, mandatory, setValue }: PhoneInputProps) => {
  // To allow numbers with french 0 prefix, workaround from this issue is applied
  // https://github.com/bl00mber/react-phone-input-2/issues/354
  const [format, setFormat] = React.useState('')
  const [innerValue, setInnerValue] = React.useState('')
  const formatDigits = React.useMemo(() => format.replace(/[ +]/g, '').length, [format])

  React.useEffect(() => {
    // Value is undefined when it is a pure dial code. In that case input should not be emptied.
    if (value) setInnerValue(value)
  }, [value])

  return (
    <PhoneInputLib
      placeholder="Enter phone number"
      value={innerValue}
      country="fr"
      onChange={(value: value, data: CountryData, e, formattedValue: string) => {
        data.format && setFormat(data.format)
        setValue(name, value !== data.dialCode ? formattedValue : undefined, mandatory)
      }}
      enableLongNumbers={formatDigits > 10 ? formatDigits : false}
      onMount={(_, data: CountryData) => data.format && setFormat(data.format)}
      inputClass="phone-input__input rfc-input"
      dropdownClass="phone-input__dropdown"
    />
  )
}

export default withFormControl(PhoneInput)
