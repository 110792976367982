import React from 'react'
import Content from '../components/Content'
import useAuth from '../utils/useAuth'

const Sandbox = () => {
  const {
    currentUser: { userType, keycloakId },
  } = useAuth()

  React.useEffect(() => {
    if (userType === 'administrator') {
      // Put tested functions here.
    }
  }, [])

  return <Content>Sandbox page for development purposes.</Content>
}

export default Sandbox
