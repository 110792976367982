import React from 'react'
import { type setValue, withFormControl } from 'react-form-component'
import { YearRangePicker } from 'react-year-range-picker'
import './style.sass'

type YearRangeValue = [number | undefined, number | undefined]

interface YearRangeInputProps {
  name: string
  value: YearRangeValue
  mandatory: boolean
  setValue: setValue
}

const YearRangeInput = ({
  name,
  value: [startYear, endYear] = [undefined, undefined],
  mandatory,
  setValue,
}: YearRangeInputProps) => {
  return (
    <YearRangePicker
      minYear={new Date().getFullYear() - 50}
      maxYear={new Date().getFullYear()}
      onSelect={(newStartYear, newEndYear) => {
        setValue(name, [newStartYear, newEndYear], mandatory)
      }}
      startYear={startYear}
      endYear={endYear}
      spacer="-"
      // Inherit react-form-component styles.
      // https://firflant.github.io/react-form-component/?path=/docs/docs-theming--page#styling-via-classes
      classNames="year-range-input rfc-input rfc-select"
      selectedColor="#038385"
    />
  )
}
// @ts-ignore
export default withFormControl(YearRangeInput)
