import { useTranslation } from 'react-i18next'
import { type Dictionary, type UserType } from '../typings'

export const kvalToOptions = (r: Record<string, string>) =>
  Object.entries(r).map(([value, label]: [value: string, label: string]) => ({ label, value }))

export const useTranslatedEnums = (key: string, namespace: string = 'common') => {
  type Translations = { [key: string]: Dictionary }
  const { i18n } = useTranslation([namespace])
  const translations = i18n.store.data[i18n.language] as Translations
  const enums = translations[namespace][key]
  if (enums) return enums
  // Fallback to FR if currenct language does not provide translations for enums.
  const defaultTranslations = i18n.store.data['fr'] as Translations
  const defaultEnums = defaultTranslations[namespace][key]
  return defaultEnums || {}
}

export const useTranslatedOptions = (key: string, namespace: string = 'common') => {
  const enums = useTranslatedEnums(key, namespace)
  return kvalToOptions(enums || {})
}

export const getDateDaysTreshold = (date: Date, days: number) =>
  new Date(new Date(date.getTime() + days * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0)) // https://stackoverflow.com/a/20329800/16578550

export const isOwnerType = (userType: UserType) =>
  ['individual', 'association', 'breeder'].includes(userType)
