import api from '..'
import { type UserBasic } from '../../typings'

export const postPushRegistrations = async (
  { userType, keycloakId }: UserBasic,
  subscribtion: PushSubscriptionJSON,
  remove?: boolean
): Promise<void> => {
  const endpoints = [
    ...(userType === 'administrator'
      ? [`/0/${userType}/${keycloakId}/vetecard_request/insertion/subscription`, `/0/${userType}/${keycloakId}/vetecard_request/activation/subscription`,]
      : []),
    `/0/${userType}/${keycloakId}/vetecard/mandatory_vaccination/insertion/subscription`,
    `/0/${userType}/${keycloakId}/vetecards/mandatory_vaccinations/valid_until/expiring/subscription`,
    `/0/${userType}/${keycloakId}/vetecard/other_vaccination/insertion/subscription`,
    `/0/${userType}/${keycloakId}/vetecard/treatment/insertion/subscription`,
    `/0/${userType}/${keycloakId}/vetecard/medical_file/insertion/subscription`,
    `/0/${userType}/${keycloakId}/vetecard/invoice/insertion/subscription`,
  ]
  if (remove) {
    await endpoints.forEach(endpoint =>
      api.post(`${endpoint}/change`, { old: subscribtion, new: null })
    )
  } else {
    await endpoints.forEach(endpoint => api.post(endpoint, subscribtion))
  }
}
