import React from 'react'
import {
  faFileCirclePlus,
  faListCheck,
  faNewspaper,
  faPaw,
  faWallet,
} from '@fortawesome/free-solid-svg-icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { dismissAnnouncement, getAnnouncements } from '../api/formatted/news'
import { getVetecards } from '../api/formatted/vetecard'
import Alert from '../components/Alert'
import AnnouncementCard from '../components/AnnouncementCard'
import Block from '../components/Block'
import Content from '../components/Content'
import Grid from '../components/Grid'
import Loader from '../components/Loader'
import Tile from '../components/Tile'
import Welcome from '../components/Welcome'
import { Announcement } from '../typings'
import queryClient from '../utils/queryClient'
import useAuth from '../utils/useAuth'

const Dashboard = () => {
  const { currentUser, hasPermission } = useAuth()
  const { firstName, userType } = currentUser
  const { t } = useTranslation()

  const {
    data: news = [],
    isFetching,
    isError,
  } = useQuery(['news'], () => getAnnouncements(currentUser), {
    enabled: hasPermission('view-news'),
  })

  const { mutate } = useMutation((id: Announcement['id']) => dismissAnnouncement(id, currentUser), {
    onMutate: (id: Announcement['id']) => {
      queryClient.setQueryData(
        ['news'],
        (oldData?: Announcement[]) => oldData && oldData.filter(item => item.id !== id)
      )
    },
  })

  const { data: { vetecards } = { vetecards: [], hasMore: false } } = useQuery(
    ['vetecard-list'],
    () =>
      getVetecards(
        currentUser,
        {},
        {
          withImage: !hasPermission('vetecards-advanced'),
          withRequests: !hasPermission('vetecards-advanced'),
        }
      ),
    {
      refetchOnMount: false, // Reuse data from query on main Dashboard page, if it is present.
      enabled: hasPermission('vetecards'),
    }
  )

  const tiles = [
    {
      title: t('browseVetecards'),
      url: '/vetecards',
      icon: faPaw,
      permission: 'vetecards-advanced',
    },
    {
      title: t('checkRequests'),
      url: '/vetecard-requests',
      icon: faListCheck,
      permission: 'approve-vetecard',
    },
    {
      title: t('requestVetecard'),
      url: '/request-veticard',
      icon: faFileCirclePlus,
      permission: 'request-veticard',
    },
    {
      title: t('activateVetecards'),
      url: '/activate-vetecards',
      icon: faListCheck,
      permission: 'activate-vetecards',
    },
    {
      title: t('manageNews'),
      url: '/manage-news',
      icon: faNewspaper,
      permission: 'manage-news',
    },
    { title: t('checkBilling'), url: '/billing', icon: faWallet, permission: 'billing' },
  ]

  const hasUnisngedConsents = React.useMemo(
    () => vetecards.some(item => item.requestStatus === 'consentAgreement'),
    [vetecards]
  )

  const hasUnpaidRequests = React.useMemo(
    () => vetecards.some(item => item.requestStatus === 'payment'),
    [vetecards]
  )

  const handleDismiss = (id: Announcement['id']) => {
    mutate(id)
  }

  return (
    <Content>
      <Welcome name={firstName || userType} userType={userType} />
      {hasUnisngedConsents && (
        <Alert
          type="info"
          primary={t('You have unsigned consent')}
          secondary={t('Some of your VET e-Cards has unsigned consent.')}
        />
      )}
      {hasUnpaidRequests && (
        <Alert
          type="info"
          btnLabel={t('payment.title')}
          btnUrl="/payment"
          primary={t('inactiveVetecards')}
          secondary={t('inactiveVetecardsDescr')}
        />
      )}
      {hasPermission('view-news') && (
        <Block title={t('newsMessages')}>
          <Loader loading={isFetching} error={isError}>
            {news.map(item => (
              <AnnouncementCard key={item.id} onDismiss={handleDismiss} {...item} />
            ))}
          </Loader>
        </Block>
      )}
      <Grid>
        {tiles.map(
          ({ permission, ...rest }) =>
            hasPermission(permission) && <Tile {...rest} key={permission} />
        )}
      </Grid>
    </Content>
  )
}

export default Dashboard
